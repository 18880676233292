import * as Sentry from "@sentry/react";
import axios from "axios";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useState } from "react";
import Particles from "react-particles";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { loadFull } from "tsparticles";
import UserContext from "../../context/UserContext";
import { getMe, loginStudent } from "../../resources/api";
import { waitForVoice } from "../../utils/audios";
import { queryMicAccess } from "../../utils/permissions";
import ModuleChoice from "../introduction/ModuleChoice";
import NewModuleChoice from "../new-module-choice/new-module-choice"
import Button from "./Button";
import styles from "./Landing.module.css";
import SetttingsModal from "./Settings";
const stateToCode = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY"
};

function convertStateToCode(stateName) {
  return stateToCode[stateName];
}

//loginStudent
/**
 * Intro page:
 * - Main page
 *   - Moving animation
 *   - Play button
 * - Login
 * - Settings
 *   - Change microphone
 *   - Disable sound
 * - Disappear animation
 */
export default function Landing({ initMaze, removeComponent }) {
  const [transition, setTransition] = useState("first");
  const [isChoiceShowing, setIsChoiceShowing] = useState(false)
  const [usState, setUsState] = useState("GA")
  const [microPhoneRequest, setMicroPhoneRequest] = useState(false)
  const navigate = useNavigate()
  const particlesInit = useCallback(async (engine) => {
    console.dir(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  useEffect(() => {
    axios.get("https://geolocation-db.com/json/").then(data => {
      if (data.data.state) {
        setUsState(convertStateToCode(data.data.state))
      }
    })
  }, [])

  const [schoolId, setSchoolId] = useState(null)
  const [userId, setUserId] = useState(null)
  const [password, setPassword] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [settingsDialog, setSettingsDialog] = useState(false)
  const { user, setUser } = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [licenseType, setLicenseType] = useState(null)
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getMe().then(res => {
        Sentry.setUser({
          username: res.data.id,
          id: res.data.uname
        })
        setIsLoggedIn(true)
        if (res.data.license?.license_type === "ct") {
          setLicenseType("critical")
        } else if (res.data.license?.license_type === "fl") {
          setLicenseType("fluency")
        }
      }).finally(_ => {

      })
    }
  }, [])
  const url = new URL(window.location.href);
  //fill creds from url params if they exist
  useEffect(() => {
    const state = url.searchParams.get("state");
    const school = url.searchParams.get("school");
    const user = url.searchParams.get("user");
    const pass = url.searchParams.get("pass");
    if (state && school && user && pass) {
      loginStudent(state, school, user, pass).then(res => {
        if (res.status != 401) {
          window.gtag("event", "login");
          localStorage.setItem("token", res.data.token);
          setUser(res.data.user)
          setIsLoggedIn(true)
          setIsChoiceShowing(true)
        } else {
          toast.error("User doesn't exist")
          setIsLoading(false)
        }
      })
    }
  }, [
    url.searchParams.get("state"),
    url.searchParams.get("school"),
    url.searchParams.get("user"),
    url.searchParams.get("pass")
  ])
  return <div className={clsx(styles.background, transition == "exit" && styles.hideDown)}>

    {
      transition != "form" && <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          "autoPlay": true,
          "backgroundMask": {
            "composite": "destination-out",
            "cover": {
              "color": {
                "value": "#fff"
              },
              "opacity": 1
            },
            "enable": false
          },
          "fullScreen": {
            "enable": true,
            "zIndex": -1
          },
          "detectRetina": true,
          "duration": 0,
          "fpsLimit": 120,
          "interactivity": {
            "detectsOn": "window",
            "events": {
              onDiv: [
                {
                  enable: transition != "hide",
                  selectors: ".repulsing",
                  mode: "bounce",
                  type: "rectangle",
                  "parallax": {
                    "enable": transition != "hide",
                    "force": 90,
                    "smooth": 10
                  }
                }
              ],
              "resize": true
            },
            "modes": {
              "slow": {
                "factor": 3,
                "radius": 200
              },
              "attract": {
                "distance": 200,
                "duration": 0.4,
                "easing": "ease-out-quad",
                "factor": 1,
                "maxSpeed": 50,
                "speed": 1
              },
              "bounce": {
                "distance": 200
              },
              "bubble": {
                "distance": 400,
                "duration": 2,
                "mix": false,
                "opacity": 0.8,
                "size": 40,
                "divs": {
                  "distance": 200,
                  "duration": 0.4,
                  "mix": false,
                  "selectors": []
                }
              },
              "connect": {
                "distance": 80,
                "links": {
                  "opacity": 0.5
                },
                "radius": 60
              },
              "grab": {
                "distance": 400,
                "links": {
                  "blink": false,
                  "consent": false,
                  "opacity": 1
                }
              },
              "push": {
                "default": true,
                "groups": [],
                "quantity": 4
              },
              "remove": {
                "quantity": 2
              },
              "repulse": {
                "distance": 50,
                "duration": 0.4,
                "factor": 100,
                "speed": 1,
                "maxSpeed": 50,
                "easing": "ease-out-quad",
                "divs": {
                  "distance": 50,
                  "duration": 0.4,
                  "factor": 100,
                  "speed": 1,
                  "maxSpeed": 50,
                  "easing": "ease-out-quad",
                  "selectors": [".repulsing"]
                }
              },
              "trail": {
                "delay": 1,
                "pauseOnStop": false,
                "quantity": 1
              },
              "light": {
                "area": {
                  "gradient": {
                    "start": {
                      "value": "#ffffff"
                    },
                    "stop": {
                      "value": "#000000"
                    }
                  },
                  "radius": 1000
                },
                "shadow": {
                  "color": {
                    "value": "#000000"
                  },
                  "length": 2000
                }
              }
            }
          },
          "manualParticles": [],
          "motion": {
            "disable": true,
            "reduce": {
              "factor": 4,
              "value": true
            }
          },
          "particles": {
            "bounce": {
              "horizontal": {
                "random": {
                  "enable": false,
                  "minimumValue": 0.1
                },
                "value": 1
              },
              "vertical": {
                "random": {
                  "enable": false,
                  "minimumValue": 0.1
                },
                "value": 1
              }
            },
            "collisions": {
              "bounce": {
                "horizontal": {
                  "random": {
                    "enable": true,
                    "minimumValue": 0.1
                  },
                  "value": 1
                },
                "vertical": {
                  "random": {
                    "enable": true,
                    "minimumValue": 0.1
                  },
                  "value": 1
                }
              },
              "enable": true,
              "mode": "bounce",
              "overlap": {
                "enable": false,
                "retries": 0
              }
            },
            "color": {
              "value": {
                hsl: {
                  h: { max: 359, min: 80 },
                  l: 50,
                  s: 40
                }
              }

            },
            "groups": {},
            "move": {
              "angle": {
                "offset": 0,
                "value": 90
              },
              "attract": {
                "distance": 1700,
                "enable": false,
                "rotate": {
                  "x": 50,
                  "y": 50
                }
              },
              "bounce": false,
              "center": {
                "x": 70,
                "y": 50,
                "radius": 90,

              },
              "decay": 0,
              "distance": {},
              "direction": "none",
              "drift": 0,
              "enable": true,
              "gravity": {
                "acceleration": 0.05,
                "enable": true,
                "inverse": false,
                "maxSpeed": 0.2
              },
              "path": {
                "clamp": true,
                "delay": {
                  "random": {
                    "enable": false,
                    "minimumValue": 0
                  },
                  "value": 0
                },
                "enable": false,
                "options": {}
              },
              "outModes": {
                "default": "out",
                "bottom": "out",
                "left": "out",
                "right": "out",
                "top": "out"
              },
              "random": false,
              "size": false,
              "speed": 2,
              "spin": {
                "acceleration": 1,
                "enable": false
              },
              "straight": true,
              "trail": {
                "enable": false,
                "length": 5,
                "fillColor": {
                  "value": "#fff"
                }
              },
              "vibrate": false,
              "warp": false
            },
            "number": {
              "density": {
                "enable": true,
                "area": 800,
                "factor": 1000
              },
              "limit": 0,
              "value": 26
            },
            "reduceDuplicates": false,
            "rotate": {
              "random": {
                "enable": false,
                "minimumValue": 0
              },
              "value": 0,
              "animation": {
                "enable": false,
                "speed": 1,
                "decay": 0,
                "sync": false
              },
              "direction": "clockwise",
              "path": false
            },
            "shadow": {
              "blur": 0,
              "color": {
                "value": "#000"
              },
              "enable": true,
              "offset": {
                "x": 0,
                "y": 0
              },

            },
            "shape": {
              "options": {
                "character": {
                  "value": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
                  "font": 'League Spartan',
                  "style": "",
                  "weight": "600",
                  "fill": true
                },
                "char": {
                  "value": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
                  "font": 'League Spartan',
                  "style": "",
                  "weight": "600",
                  "fill": true
                }
              },
              "type": "char"
            },
            "size": {
              "value": 26,
              "animation": {
                count: 1,
                "enable": transition == "hide",
                "speed": 150,
                "startValue": 26,
                "minimumValue": (transition == "hide") ? 0 : 26
              }
            },
            "stroke": {
              "width": 1,
              "color": {
                "value": "#000",
                "animation": {
                  "h": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                  },
                  "s": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                  },
                  "l": {
                    "count": 0,
                    "enable": false,
                    "offset": 0,
                    "speed": 1,
                    "decay": 0,
                    "sync": true
                  }
                }
              }
            },
            "zIndex": {
              "random": {
                "enable": false,
                "minimumValue": 0
              },
              "value": 0,
              "opacityRate": 1,
              "sizeRate": 1,
              "velocityRate": 1
            },
            "life": {
              "count": 0,
              "delay": {
                "random": {
                  "enable": false,
                  "minimumValue": 0
                },
                "value": 0,
                "sync": false
              },
              "duration": {
                "random": {
                  "enable": false,
                  "minimumValue": 0.0001
                },
                "value": 0,
                "sync": false
              }
            },
            "roll": {
              "darken": {
                "enable": false,
                "value": 0
              },
              "enable": false,
              "enlighten": {
                "enable": false,
                "value": 0
              },
              "mode": "vertical",
              "speed": 25
            },
            "tilt": {
              "random": {
                "enable": true,
                "minimumValue": 0
              },
              "value": 0,
              "animation": {
                "enable": false,
                "speed": 0,
                "decay": 0,
                "sync": false
              },
              "direction": "clockwise",
              "enable": false
            },
            "twinkle": {
              "lines": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
              },
              "particles": {
                "enable": false,
                "frequency": 0.05,
                "opacity": 1
              }
            },
            "wobble": {
              "distance": 5,
              "enable": false,
              "speed": {
                "angle": 50,
                "move": 10
              }
            },
            "orbit": {
              "animation": {
                "count": 0,
                "enable": true,
                "speed": 1,
                "decay": 0,
                "sync": false
              },
              "enable": false,
              "opacity": 1,
              "rotation": {
                "random": {
                  "enable": false,
                  "minimumValue": 0
                },
                "value": 45
              },
              "width": 1
            },
            "repulse": {
              "random": {
                "enable": false,
                "minimumValue": 0
              },
              "value": 0,
              "enabled": true,
              "distance": 1,
              "duration": 1,
              "factor": 1,
              "speed": 1,
              selectors: [".repulsing"]
            }
          },
          "pauseOnBlur": true,
          "pauseOnOutsideViewport": true,
          "responsive": [],
          "style": {},
          "themes": [],
          "zLayers": 100
        }}
      />
    }
    {
      microPhoneRequest && <div onClick={() => {
        setMicroPhoneRequest(false)
      }} className={styles.popupBackground}>
        <h1>Give us your <b>Microphone</b>!</h1>
        <span>Allow microphone access from the dialog above or from settings.</span>
      </div>
    }
    <header className={clsx(styles.header, "repulsingHeader")}>
      {transition == "form" ? <div /> :
        <Button
          className={transition == "hide" && styles.hidingButton}
          rounding="right" paddingV="medium"
          icon={"people"} contentSize={"medium"} click={"inside"}
          onClick={() => {
            window.location = "https://portal.read21.org/"
          }}
        />}

      <div className={styles.settingsOption}>
        <Button
          className={transition == "hide" && styles.hidingButton}
          rounding="left" paddingV="medium"
          icon={"setting"} contentSize={"medium"} click={"inside"}
          onClick={() => {
            var callback = () => { setSettingsDialog(true) }
            queryMicAccess(callback, () => {
              setMicroPhoneRequest(true)
              navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                  setMicroPhoneRequest(false)
                  callback()

                })
                .catch(function (err) {
                  setMicroPhoneRequest(false)
                });
            })
          }}
        />
      </div>


    </header>
    {
      settingsDialog && <SetttingsModal removeComponent={() => setSettingsDialog(false)} />
    }
    {
      transition != "form" && <div className={clsx(styles.content, "repulsing", transition == "hide" && styles.absorb)} >
        <img src="/read21.svg" className={styles.appLogo} />
        <Button className={styles.continueButton} title={"Continue"} onClick={async () => {
          // const blob = await waitForVoice(40, 1, "waiting", 14000, { buttonMode: true });
          // return;

          window.gtag("event", "continue");
          var callback = () => {
            setMicroPhoneRequest(false)

            if (localStorage.getItem("token")) {
              setIsChoiceShowing(true)
            } else {
              setTransition("hide")
              setTimeout(() => {
                setTransition("form")

              }, 3000)
            }

          }
          queryMicAccess(callback, () => {
            setMicroPhoneRequest(true)
            navigator.mediaDevices.getUserMedia({ audio: true })
              .then(function (stream) {
                setMicroPhoneRequest(false)
                callback()

              })
              .catch(function (err) {
                setMicroPhoneRequest(false)
              });

          })

        }} icon={"right"} border={"black"} background={"green"} click={"skewed"} />
        {/*<Button title={"Test 1"} contentSize={"medium"} onClick={async () => {*/}
        {/*  const file = await waitForVoice(40, 1, "waiting", 3000, { speechTimeOut: 1000});*/}
        {/*  //play the file*/}
        {/*    var audio = new Audio(URL.createObjectURL(file));*/}
        {/*    audio.play();*/}
        {/*    */}
        {/*}} />*/}
        {
          isLoggedIn && <Button onClick={() => {
            window.gtag("event", "logout");
            localStorage.removeItem("token")
            setIsLoggedIn(false)
            setUser(null)
          }} background={"red"} icon={"logout"} contentSize={"medium"} title={"Logout"} />
        }

      </div>

    }
    {
      transition == "form" && <form onSubmit={() => {
        setIsLoading(true)
        loginStudent(usState, schoolId, userId, password).then(res => {


          if (res.status != 401) {
            window.gtag("event", "login");
            localStorage.setItem("token", res.data.token);
            setUser(res.data.user)
            setIsLoggedIn(true)
            setIsChoiceShowing(true)
          } else {
            toast.error("User doesn't exist")
            setIsLoading(false)
          }

        }).finally(_ => {


        })
      }
      } action="javascript:void(0);" className={styles.loginContent}>
        <img src="/read21.png" className={styles.loginLogo} />
        <div className={styles.loginForm}>
          <h1 className={styles.loginTitle}>Student Login</h1>
          <select type="select" required={true} className={styles.loginInput} placeholder="State" value={usState} onChange={(c) => setUsState(c.target.value)}>
            <option value={"AL"}>Alabama</option>
            <option value={"AK"}>Alaska</option>
            <option value={"AZ"}>Arizona</option>
            <option value={"AR"}>Arkansas</option>
            <option value={"CA"}>California</option>
            <option value={"CO"}>Colorado</option>
            <option value={"CT"}>Connecticut</option>
            <option value={"DE"}>Delaware</option>
            <option value={"FL"}>Florida</option>
            <option value={"GA"}>Georgia</option>
            <option value={"HI"}>Hawaii</option>
            <option value={"ID"}>Idaho</option>
            <option value={"IL"}>Illinois</option>
            <option value={"IN"}>Indiana</option>
            <option value={"IA"}>Iowa</option>
            <option value={"KS"}>Kansas</option>
            <option value={"KY"}>Kentucky</option>
            <option value={"LA"}>Louisiana</option>
            <option value={"ME"}>Maine</option>
            <option value={"MD"}>Maryland</option>
            <option value={"MA"}>Massachusetts</option>
            <option value={"MI"}>Michigan</option>
            <option value={"MN"}>Minnesota</option>
            <option value={"MS"}>Mississippi</option>
            <option value={"MO"}>Missouri</option>
            <option value={"MT"}>Montana</option>
            <option value={"NE"}>Nebraska</option>
            <option value={"NV"}>Nevada</option>
            <option value={"NH"}>New Hampshire</option>
            <option value={"NJ"}>New Jersey</option>
            <option value={"NM"}>New Mexico</option>
            <option value={"NY"}>New York</option>
            <option value={"NC"}>North Carolina</option>
            <option value={"ND"}>North Dakota</option>
            <option value={"OH"}>Ohio</option>
            <option value={"OK"}>Oklahoma</option>
            <option value={"OR"}>Oregon</option>
            <option value={"PA"}>Pennsylvania</option>
            <option value={"RI"}>Rhode Island</option>
            <option value={"SC"}>South Carolina</option>
            <option value={"SD"}>South Dakota</option>
            <option value={"TN"}>Tennessee</option>
            <option value={"TX"}>Texas</option>
            <option value={"UT"}>Utah</option>
            <option value={"VT"}>Vermont</option>
            <option value={"VA"}>Virginia</option>
            <option value={"WA"}>Washington</option>
            <option value={"WV"}>West Virginia</option>
            <option value={"WI"}>Wisconsin</option>
            <option value={"WY"}>Wyoming</option>
          </select>

          <input required={true} className={styles.loginInput} placeholder="School id" value={schoolId} onChange={(c) => setSchoolId(c.target.value)} />
          <input required={true} id={"userid"} className={styles.loginInput} placeholder="User name" value={userId} onChange={(c) => setUserId(c.target.value)} />
          <input required={true} id={"password"} type="password" className={styles.loginInput} placeholder="Password" value={password} onChange={(c) => setPassword(c.target.value)} />

          <Button type={"submit"} paddingV="full" paddingH="full" title={"Continue"} icon={"right"} background={isLoading ? "gray" : "green"} className={styles.loginButton} contentSize={"medium"} click={"inside"} />
        </div>
      </form>
    }
    {/* {
      isChoiceShowing && <ModuleChoice onNext={async (type) => {
        //await playSound("click")
        getMe().then(res => {
          Sentry.setUser({
            username: res.data.id,
            id: res.data.uname
          })
          setTransition("hide")
          setUser(res.data);
          if (type == "fluency") {
            initMaze();
            removeComponent();

          } else if (type == "critical") {
            navigate("/critical")
          }


        }).finally(_ => {
          setIsLoading(false)
        })
      }
      } />
    } */}
    {
      isChoiceShowing && <NewModuleChoice onNext={async (type) => {
        //await playSound("click")
        getMe().then(res => {
          Sentry.setUser({
            username: res.data.id,
            id: res.data.uname
          })
          setTransition("hide")
          setUser(res.data);
          if (type == "fluency") {
            initMaze();
            removeComponent();

          } else if (type == "critical") {
            navigate("/critical")
          }


        }).finally(_ => {
          setIsLoading(false)
        })
      }
      } />
    }



  </div>
}