import ReadItOut from "../minified/ReadItOut";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { initVoice } from "../../resources/api";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import wait from "../../utils/wait";
import { waitForVoice } from "../../utils/audios";
import { useSoundManager } from "../../utils/sound";
import UserContext from '../../context/UserContext';
var speechConfig = SpeechSDK.SpeechConfig.fromSubscription("3e60cc9987ec41418be6de7cf1905fa2", "eastus");
speechConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "10000");
speechConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "5000");

speechConfig.setProperty("SPEECH-PhraseTimeout", "100000");
function matchStrings(a, b, handleEndings = true) {
    // initialize an empty object to store the results
    let result = {};
    // initialize two pointers to track the indices of a and b
    let i = 0;
    let j = 0
    // loop through the list a until the end
    var lastRead = null;
    while ((i < b.length) && (i < a.length)) {
        lastRead = `${a[i]}_${i}`.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase();
        result[`${a[i]}${i}`.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase()] = "read";
        i++;
    }
    return [result, lastRead]
}
async function getMicClip({ microPhoneConfig: _micConfig, setStatus, setHasRecorded, audioStreamBegan, closeStream }) {
    const microPhoneConfig = { waitTime: 10000, timeOut: 10000, wrLength: 3, type: "norepeat", ...(_micConfig || {}) }
    return await waitForVoice(microPhoneConfig.wrLength, 1, microPhoneConfig.type, microPhoneConfig.waitTime, {
        speechTimeOut: microPhoneConfig.timeOut,
        listening() {
            setStatus("recording")
            setHasRecorded && setHasRecorded(true)
        },
        empty() {
            setStatus("recording")
        },
        audioStreamBegan,
        closeStream
    });
}
export default function CTRIO({ status: _resultStatus, isQuestionDisabled, soundManager, questionIndex, itemDetails, questions, sentenceResults, setSentenceResults, metaBlob, audioBlob, clickHandler, s3FieldsRef, subType, breaks, isParagraphRIO }) {
    const [status, setStatus] = useState(null)
    const { user: { grade } } = useContext(UserContext)
    useEffect(() => {
        setStatus(_resultStatus)
    }, [
        _resultStatus
    ])
    const silences = useRef()

    const microphoneOperation = useCallback(async () => {


        var recognizer;
        var recognizingText = itemDetails.text;
        console.log({ recognizingText })
        if (itemDetails?.type == "rio") {
            if (typeof itemDetails.text != "string") {
                recognizingText = itemDetails.text.join(" ")
            }
            setSentenceResults((results) => ({ ...results, [questionIndex]: null }))
            recognizingText = recognizingText.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase()
            console.log({ recognizingText })

        }

        const blob = await getMicClip(
            {
                microPhoneConfig: { waitTime: isParagraphRIO ? 3 * 60 * 1000 : 45000, timeOut: isParagraphRIO ? 3 * 60 * 1000 : 4000, wrLength: recognizingText.length },
                setStatus,
                setHasRecorded() { },
                audioStreamBegan(stream, endTheStream) {
                    if (itemDetails?.type == "rio") {

                        var audioConfig = SpeechSDK.AudioConfig.fromStreamInput(stream);
                        recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
                        const phraseList = SpeechSDK.PhraseListGrammar.fromRecognizer(recognizer);
                        var mainTarget = recognizingText.split(" ");
                        for (let i = 0; i < mainTarget.length; i++) {
                            phraseList.addPhrase(mainTarget[i]);
                        }


                        var totalProcessedTextIs = []
                        setSentenceResults((results) => ({ ...results, [questionIndex]: null }))
                        var noticedWords = {};


                        for (let i = 0; i < recognizingText.length; i++) {
                            var word = mainTarget[i]
                            noticedWords[i] = {
                                word,
                                noticed: false
                            }
                        }
                        var allTexts = []
                        var questionIndexTextItem = "";
                        var allTextPrevMerged = ""
                        recognizer.recognizing = function (s, e) {

                            // handle intermediate results
                            var data = JSON.parse(e.result.json)
                            // console.log( data)
                            // console.log("RECOGNIZing: Text=",data);

                            if (typeof (e.result.text) == "string") {
                                try {
                                    var prctext = e.result.text.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase()
                                    questionIndexTextItem = prctext

                                    var recognizedText = ([allTextPrevMerged, prctext]).filter(itm => itm?.length > 0).join(" ")
                                    console.log(recognizedText)
                                    var recognizedWords = recognizedText.split(" ")


                                    var [sResultItem, lastRead] = matchStrings(mainTarget, recognizedWords)
                                    if (lastRead) {
                                        try {
                                            // document.getElementById(lastRead).scrollIntoView({
                                            //     behavior: "smooth",
                                            //     block: "center",
                                            //     inline: "start"
                                            // })
                                        } catch (e) {
                                            // alert(lastRead)
                                        }

                                    }
                                    console.log("Recognizing", sResultItem)
                                    setSentenceResults((results) => ({
                                        ...results, [questionIndex]: sResultItem
                                    }))
                                } catch (e) {
                                    console.error(e)
                                }

                            }
                            // handle intermediate results
                        };

                        recognizer.recognized = function (s, e) {
                            try {
                                var data = JSON.parse(e.result.json)
                                allTexts.push(questionIndexTextItem)
                                questionIndexTextItem = null;
                                allTextPrevMerged = allTexts.filter(itm => itm?.length > 0).join(" ")
                                if (data.RecognitionStatus == "Success") {

                                    metaBlob.current = {
                                        text: allTextPrevMerged,
                                    }
                                    if ((allTextPrevMerged.split(" ").slice(-1) == recognizingText.split(" ").slice(-1))) {
                                        endTheStream()
                                    }

                                } else if (data.RecognitionStatus == "EndOfDictation") {
                                    endTheStream()
                                }

                            } catch (e) {
                                console.error(e)
                            }


                        };
                        recognizer.canceled = function (s, e) {
                            // handle cancelation
                            console.log("CANCELED: Reason=" + e.reason);
                            endTheStream()
                        };
                        recognizer.startContinuousRecognitionAsync()


                    }

                },
                async closeStream() {
                    if (itemDetails?.type === "sentence") {
                        await (new Promise((resolve, reject) => {
                            recognizer.stopContinuousRecognitionAsync(resolve, reject)
                        }))
                    }
                }
            });

        if (window.clearCurrentCountDown) {
            window.clearCurrentCountDown()
        }

        try {
            await recognizer.stopContinuousRecognitionAsync()
        } catch (e) {

        }
        setStatus("processing")
        soundManager.playSound("processing", "processing", true, 0.06);
        s3FieldsRef.current = await initVoice(2, questionIndex)

        await wait(1)
        if (itemDetails?.type === "sentence") {
            await (new Promise((resolve, reject) => {
                recognizer.stopContinuousRecognitionAsync(resolve, reject)

            }))
            recognizer.close()
        }
        if (blob) {
            if (blob === "done") {
                setStatus("started")

            } else {
                audioBlob.current = blob;


                clickHandler(audioBlob)
            }
        }
        else {
            setStatus(null)

            if (silences.current < 1) {

                silences.current += 1
                setStatus("started")
            } else if (silences.current === 1) {

                silences.current += 1
                setStatus("started")

            } else {
                window.location.reload()
                // audioBlob.current = blob;
                //
            }

        }
    }, [questionIndex]);
    const registerMicrophone = useCallback(() => {
        return {
            micEnabled: !isQuestionDisabled,
            onClick: microphoneOperation,
            status
        }
    }, [status, microphoneOperation, isQuestionDisabled])
    return <ReadItOut stageDetails={{ subType: subType || "sequential" }} status={status} current={questionIndex} data={questions} controls={{
        sentenceResults: sentenceResults,
        initiateQuestions() {
        },
        registerMicrophone
    }}
        grade={grade}
        breaks={breaks}
    />
}