import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import { persistor, store } from './store/reducers/store'
import getAudios, { getAudioList, loadAudios, pauseAll, unloadAudios } from './utils/audios'
import { ToastContainer } from 'react-toastify';
import UserContext from './context/UserContext'
import Button from "./components/common/Button";
import * as processingAnimation from "./lottie/failerror.json";
import * as noNetAnimation from "./lottie/nonet.json";
import * as slowNetAnimation from "./lottie/nonet.json";
import * as Sentry from "@sentry/react";

import Lottie from "react-lottie";
import { MemoryRouter as Router } from "react-router-dom";
import InstructionContext from "./context/InstructionContext";
import SoundManager from "./utils/sound";
import StatusContext from "./context/StatusContext";
import { Read21LogoBeating } from "./components/common/BeatRead21";
import { DragDropContext } from "react-beautiful-dnd";
import SubmitContext from "./context/SubmitContext";
import PlusTrainWiget from "./components/plusTrain/plusTrain";
import registerUserActivityTracking from "./utils/userActivity";
import NoiseDetectedModal from "./components/common/NoiseDetected";
import { WordEditor } from "./components/stages/DisplayItems";
// import {WordEditor} from "./components/stages/DisplayItems";

window.soundManager = new SoundManager();
window.soundManager.updateAudioRefs(getAudioList())

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: props.hasError || null };
  }

  static getDerivedStateFromError(error) {

    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }




  render() {
    if (this.state.hasError || this.props.hasError) {
      Sentry.captureException((this.state.hasError || this.props.hasError));
      // You can render any custom fallback UI
      return <div className="defaultErrorPage">
        <Lottie
          loop={true}
          options={{
            loop: true,
            autoplay: true,
            animationData: processingAnimation,
          }}
          width={200}
          height={200}
        />
        <h1>Oops!</h1>
        <h2>Something unexpected happened!</h2>
        <Button noSoundCheck={true} processingLoader={false} withoutSound={true} direction="left" onClick={async () => {
          window.location.reload()
        }} icon={"repeat"} border={"white"} background={"blue"} title={`Restart`} />
      </div>
    }

    return this.props.children;
  }
}



const App = () => {
  const [user, setUser] = useState(null);
  const [_isGameLocked, setIsGameLocked] = useState(false)
  const isGameLocked = useMemo(() => user && _isGameLocked, [user]);
  const [errorDialog, setErrorDialog] = useState(null)
  const [errorPage, setErrorPage] = useState(false);
  const [isInstructing, setIsInstructing] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playingChannels, setPlayingChannels] = useState({});
  const [shownButton, setShownButton] = useState(null)
  const [read21Logo, setRead21Logo] = useState(null)
  const [submitButton, setSubmitButton] = useState(null)
  const [loudNessDetected, setLoudnessDetected] = useState(null)
  const buttonDisabled = useMemo(() => (Object.keys(playingChannels).indexOf("attn") !== -1) || (Object.keys(playingChannels).indexOf("instruction") !== -1) || (Object.keys(playingChannels).indexOf("direction") !== -1), [playingChannels])
  const registerCallbackHandler = useCallback((channel, status, item) => {
    var cPcs = { ...playingChannels }
    if (status == "playing") {
      console.log("Audio service, ", channel, " is playing ", item)
      cPcs[channel] = true
      setPlayingChannels(cPcs)
    } else if (status == "complete") {
      console.log("Audio service, ", channel, " is complete ", item)
      delete cPcs[channel]
      setPlayingChannels(cPcs)
    }
  }, [playingChannels])

  // useLayoutEffect(()=>{
  //   polyfill({
  //     // use this to make use of the scroll behaviour
  //     dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
  //   });
  //   //check if it's ios 10.x or higher
  //   if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
  //     window.addEventListener( 'touchmove', function() {}, {passive: false});
  //   }
  // }, [])
  useEffect(() => {
    window.soundManager.registerListener(registerCallbackHandler)

    window.lockGame = () => {
      setIsGameLocked(true)
    }
    window.unLockGame = () => {
      setIsGameLocked(false)
    }

    window.switchToErrorDialog = (type, callback) => {
      setErrorDialog({ type, callback })
    }

    window.switchToErrorPage = (error) => {
      console.error(error)
      setErrorPage(error)
    }

    registerUserActivityTracking()


    // Enable navigation prompt
    window.setLoudnessDetected = () => {
      return new Promise((resolve, reject) => {
        setLoudnessDetected({ resolve, reject })
      })

    }
  }, []);





  return (
    <Router>

      <Provider store={store}>
        <DragDropContext>


          <PersistGate loading={null} persistor={persistor}>
            <SubmitContextProvider value={{ submitButton, setSubmitButton }}>
              <StatusContext.Provider value={{ playingChannels, setPlayingChannels, buttonDisabled, shownButton, setShownButton, read21Logo, setRead21Logo }}>
                <InstructionContext.Provider value={{ isInstructing, setIsInstructing, isPlaying, setIsPlaying }}>


                  <UserContext.Provider value={{ user, setUser, isGameLocked }}>
                    {
                      errorDialog && <div className={"connectivityDialogBg"}>
                        <div className={"connectivityDialog"}>
                          {
                            errorDialog?.type == "none" && <>
                              <Lottie
                                loop={true}
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: noNetAnimation,
                                }}
                                width={100}
                                height={100}
                              />
                              <h1>No internet access</h1>
                            </>
                          }

                          {
                            errorDialog?.type == "slow" && <>
                              <Lottie
                                loop={true}
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: slowNetAnimation,
                                }}
                                width={100}
                                height={100}
                              />
                              <h1 style={{ textAlign: "center" }}>Your internet seems slow</h1>
                            </>
                          }
                          <Button processingLoader={false} icon={"repeat"} contentSize={"small"} title={"Retry"} onClick={async () => {
                            await errorDialog?.callback();
                            setErrorDialog(null)
                          }} />

                        </div>
                      </div>
                    }
                    {
                      /** Error Dialog **/
                    }
                    <ErrorBoundary hasError={errorPage} >
                      <PlusTrainWiget />
                      {
                        loudNessDetected && <NoiseDetectedModal removeComponent={() => {
                          loudNessDetected.resolve();
                          setLoudnessDetected(null)
                        }
                        }></NoiseDetectedModal>
                      }
                      {/*<div style={{width:"100vw", height:"100vh",*/}
                      {/*display:"flex", justifyContent:"center", alignItems:"center"*/}
                      {/*}}>*/}
                      {/*  <WordEditor*/}
                      {/*      word={"protest"}*/}
                      {/*      syllabicPos={2}*/}
                      {/*      validVowels={[*/}
                      {/*        2,4*/}
                      {/*      ]}*/}

                      {/*      registerTextInput={()=>{*/}
                      {/*        return {*/}

                      {/*        }*/}
                      {/*      }}*/}
                      {/*      steps={[*/}
                      {/*        "vowels",*/}
                      {/*        "syllable",*/}
                      {/*      ]}*/}
                      {/*  />*/}
                      {/*</div>*/}

                      <RootComponent />
                      {
                        read21Logo && <Read21LogoBeating {...read21Logo} />
                      }


                    </ErrorBoundary>

                  </UserContext.Provider>

                </InstructionContext.Provider>
              </StatusContext.Provider>
            </SubmitContextProvider>

            <ToastContainer />


          </PersistGate>

        </DragDropContext>
      </Provider>
    </Router>

  )
}

export default App


const SubmitContextProvider = ({ children }) => {

  const [submitButton, setSubmitButtonLocal] = useState(null)
  const [reference, setReference] = useState(null)
  const setSubmitButton = useCallback((button, ref) => {
    setSubmitButtonLocal(button)
    setReference(ref || null)
  }, [])
  return (
    <SubmitContext.Provider value={{ submitButton, setSubmitButton, reference }}>
      {children}
    </SubmitContext.Provider>
  )
}