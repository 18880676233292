import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";


Sentry.init({
    dsn: "https://d61a57103e494692902e21b50b51f75a@o4504576042270720.ingest.sentry.io/4504576045154304",
    integrations: [new BrowserTracing(),new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
const root = createRoot(document.getElementById('root')) // createRoot(container!) if you use TypeScript
root.render(
   <App />

)

