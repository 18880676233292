const moment = require("moment");
function* generateRange(start, stop, step = 1) {
    if (stop == null) {
        // one param defined
        stop = start;
        start = 0;
    }else{
        stop += 1
    }

    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        yield i;
    }
}
module.exports = {
    genColor(seed) {
        return `hsl(${(seed * 78) % 360}, 95%, 35%)`;
    },
    generateRange,
    randomItemFromList(list){
        var randomItem = list[Math.floor(Math.random() * list.length)];
        return randomItem
    },
    makeRandomString(length=10){
        return Math.random().toString(36).substring(2, length + 2);
    },
    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    },
    firstUpperCase(text){
        return text[0].toUpperCase()+text.slice(1)
    },
    formatSeconds(time){
        if(time == null){
            return "--:--"
        }
        return `00:${time.toString().length == 2 ? time: ('0'+time.toString())}`
    },
    formatTime(time) {
        if(time == null){
            return "--:--"
        }
        return  moment(new Date(0,0,0,0,0,time)).format("mm:ss")
    },
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return [...array]
    },
    removeDuplicates(array){
        return array.filter(function(item, pos, self) {
            return self.indexOf(item) === pos;
        })
    }
}