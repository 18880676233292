import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./Introduction.module.css";
import wait from "../../utils/wait";
import FloatingObject from "../common/FloatingObjects";
import BeatRead21 from "../common/BeatRead21";
import { skip } from "../../utils/skip";

export default function StageIntroduction({ stageDetails, isOptionalGrowth, isLastStep, triggerLastStep, children, onShown, beforeShown }) {
    const [page, setPage] = useState(stageDetails?.introduction ? "introduction" : "content")
    const [isHiding, setIsHiding] = useState(false)
    useEffect(() => {
        if (isLastStep) {
            triggerLastStep()
        }

    }, [isLastStep])
    useEffect(() => {
        (async () => {
            if (skip("stageIntroduction")) {
                setPage("content")
                await onShown();
                return
            }
            if (isOptionalGrowth) {
                setPage("content")
                await beforeShown()
                setPage("introduction")
            }

            if (stageDetails?.introduction) {
                if (stageDetails?.introduction?.audioURL) {
                    await soundManager.playURL(stageDetails?.introduction?.audioURL)
                }
                if (stageDetails.introduction.audio) {
                    await soundManager.playSound("instruction", stageDetails.introduction.audio)
                    await wait(500)
                } else {
                    await wait(1000)
                }

            }
            await wait(500)
            setIsHiding(true)
            await wait(1000)
            setPage("content")
            await onShown();
        })().catch(console.error)
    }, [])
    return page == "introduction" ? <div style={{}} className={clsx(styles.stageIntroductionContainer)}>
        <div className={clsx(styles.stageTitle, isHiding && styles.hiding)}>
            {
                stageDetails.introduction.videoPresentation && <>
                    <video loop={true} autoPlay={true} controls={false} muted={true} src={stageDetails.introduction.videoPresentation}
                        width={100} height={100}
                    ></video>
                </>
            }
            {
                stageDetails.introduction.title && <h1 className={clsx(stageDetails.introduction.title?.length <= 7 && styles.small)}>{stageDetails.introduction.title}</h1>
            }
            {
                stageDetails.introduction.subTitle && <h2>{stageDetails.introduction.subTitle}</h2>
            }
        </div>
        <BeatRead21 />
        <FloatingObject color={"rgba(78,84,200, 0.2)"} isHiding={isHiding} />
    </div>
        : children
}