import clsx from "clsx";
import Particles from "react-particles"
import { loadFull } from "tsparticles";
import { useCallback, useEffect, useState } from "react"
import { playSound } from "../../utils/playsound";
import wait from "../../utils/wait";
import styles from "./LetsBegin.module.css";
export default function LetsBegin({ onNext, soundManager }) {
    const [transition, setTransition] = useState("first");
    const particlesInit = useCallback(async (engine) => {
        console.dir(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    useEffect(() => {
        (async () => {
            try {
                await soundManager.playSound("instruction", "letsbegin")
                await wait(4000);
                setTransition("hide")
                await wait(2000);
                onNext()
            } catch (error) {
                alert(error)
                alert("Something went wrong! please reload page or restart browser.")
            }
        })();
    }, []);

    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", alignSelf: "stretch", flex: 1 }}>
        {
            transition != "form" &&
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    "autoPlay": true,
                    "backgroundMask": {
                        "composite": "destination-out",
                        "cover": {
                            "color": {
                                "value": "#fff"
                            },
                            "opacity": 1
                        },
                        "enable": false
                    },
                    "fullScreen": {
                        "enable": true,
                        "zIndex": -1
                    },
                    "detectRetina": true,
                    "duration": 0,
                    "fpsLimit": 120,
                    "interactivity": {
                        "detectsOn": "window",
                        "events": {
                            onDiv: [
                                {
                                    enable: transition != "hide",
                                    selectors: ".repulsing",
                                    mode: "repulse",
                                    type: "circle",
                                    "parallax": {
                                        "enable": transition != "hide",
                                        "force": 90,
                                        "smooth": 10
                                    }
                                }
                            ],
                            "resize": true
                        },
                        "modes": {
                            "slow": {
                                "factor": 3,
                                "radius": 200
                            },
                            "attract": {
                                "distance": 200,
                                "duration": 0.4,
                                "easing": "ease-out-quad",
                                "factor": 1,
                                "maxSpeed": 50,
                                "speed": 1
                            },
                            "bounce": {
                                "distance": 200
                            },
                            "bubble": {
                                "distance": 400,
                                "duration": 2,
                                "mix": false,
                                "opacity": 0.8,
                                "size": 40,
                                "divs": {
                                    "distance": 200,
                                    "duration": 0.4,
                                    "mix": false,
                                    "selectors": []
                                }
                            },
                            "connect": {
                                "distance": 80,
                                "links": {
                                    "opacity": 0.5
                                },
                                "radius": 60
                            },
                            "grab": {
                                "distance": 400,
                                "links": {
                                    "blink": false,
                                    "consent": false,
                                    "opacity": 1
                                }
                            },
                            "push": {
                                "default": true,
                                "groups": [],
                                "quantity": 4
                            },
                            "remove": {
                                "quantity": 2
                            },
                            "repulse": {
                                "distance": 50,
                                "duration": 0.4,
                                "factor": 100,
                                "speed": 1,
                                "maxSpeed": 50,
                                "easing": "ease-out-quad",
                                "divs": {
                                    "distance": 50,
                                    "duration": 0.4,
                                    "factor": 100,
                                    "speed": 1,
                                    "maxSpeed": 50,
                                    "easing": "ease-out-quad",
                                    "selectors": [".repulsing"]
                                }
                            },
                            "trail": {
                                "delay": 1,
                                "pauseOnStop": false,
                                "quantity": 1
                            },
                            "light": {
                                "area": {
                                    "gradient": {
                                        "start": {
                                            "value": "#ffffff"
                                        },
                                        "stop": {
                                            "value": "#000000"
                                        }
                                    },
                                    "radius": 1000
                                },
                                "shadow": {
                                    "color": {
                                        "value": "#000000"
                                    },
                                    "length": 2000
                                }
                            }
                        }
                    },
                    "manualParticles": [],
                    "motion": {
                        "disable": true,
                        "reduce": {
                            "factor": 4,
                            "value": true
                        }
                    },
                    "particles": {
                        "bounce": {
                            "horizontal": {
                                "random": {
                                    "enable": false,
                                    "minimumValue": 0.1
                                },
                                "value": 1
                            },
                            "vertical": {
                                "random": {
                                    "enable": false,
                                    "minimumValue": 0.1
                                },
                                "value": 1
                            }
                        },
                        "collisions": {
                            "bounce": {
                                "horizontal": {
                                    "random": {
                                        "enable": true,
                                        "minimumValue": 0.1
                                    },
                                    "value": 1
                                },
                                "vertical": {
                                    "random": {
                                        "enable": true,
                                        "minimumValue": 0.1
                                    },
                                    "value": 1
                                }
                            },
                            "enable": true,
                            "mode": "bounce",
                            "overlap": {
                                "enable": false,
                                "retries": 0
                            }
                        },
                        "color": {
                            "value": {
                                hsl: {
                                    h: { max: 359, min: 80 },
                                    l: 50,
                                    s: 40
                                }
                            }

                        },
                        "groups": {},
                        "move": {
                            "angle": {
                                "offset": 0,
                                "value": 90
                            },
                            "attract": {
                                "distance": 1700,
                                "enable": false,
                                "rotate": {
                                    "x": 50,
                                    "y": 50
                                }
                            },
                            "bounce": false,
                            "center": {
                                "x": 70,
                                "y": 50,
                                "radius": 90,

                            },
                            "decay": 0,
                            "distance": {},
                            "direction": "none",
                            "drift": 0,
                            "enable": true,
                            "gravity": {
                                "acceleration": 0.05,
                                "enable": true,
                                "inverse": false,
                                "maxSpeed": 0.2
                            },
                            "path": {
                                "clamp": true,
                                "delay": {
                                    "random": {
                                        "enable": false,
                                        "minimumValue": 0
                                    },
                                    "value": 0
                                },
                                "enable": false,
                                "options": {}
                            },
                            "outModes": {
                                "default": "out",
                                "bottom": "out",
                                "left": "out",
                                "right": "out",
                                "top": "out"
                            },
                            "random": false,
                            "size": false,
                            "speed": 2,
                            "spin": {
                                "acceleration": 1,
                                "enable": false
                            },
                            "straight": true,
                            "trail": {
                                "enable": false,
                                "length": 10,
                                "fillColor": {
                                    "value": "#000000"
                                }
                            },
                            "vibrate": false,
                            "warp": false
                        },
                        "number": {
                            "density": {
                                "enable": true,
                                "area": 800,
                                "factor": 1000
                            },
                            "limit": 0,
                            "value": 20
                        },
                        "reduceDuplicates": true,
                        "rotate": {
                            "random": {
                                "enable": false,
                                "minimumValue": 0
                            },
                            "value": 0,
                            "animation": {
                                "enable": false,
                                "speed": 1,
                                "decay": 0,
                                "sync": false
                            },
                            "direction": "clockwise",
                            "path": false
                        },
                        "shadow": {
                            "blur": 0,
                            "color": {
                                "value": "#000"
                            },
                            "enable": true,
                            "offset": {
                                "x": 0,
                                "y": 0
                            },

                        },
                        "shape": {
                            "options": {
                                "character": {
                                    "value": "abcdefghijklmnopqrstuvwxyz".split(""),
                                    "font": "Inter",
                                    "style": "",
                                    "weight": "600",
                                    "fill": true
                                },
                                "char": {
                                    "value": "abcdefghijklmnopqrstuvwxyz".split(""),
                                    "font": "Inter",
                                    "style": "",
                                    "weight": "600",
                                    "fill": true
                                }
                            },
                            "type": "char"
                        },
                        "size": {
                            "value": 26,
                            "animation": {
                                count: 1,
                                "enable": transition == "hide",
                                "speed": 150,
                                "startValue": 26,
                                "minimumValue": (transition == "hide") ? 0 : 26
                            }
                        },
                        "stroke": {
                            "width": 1,
                            "color": {
                                "value": "#000",
                                "animation": {
                                    "h": {
                                        "count": 0,
                                        "enable": false,
                                        "offset": 0,
                                        "speed": 1,
                                        "decay": 0,
                                        "sync": true
                                    },
                                    "s": {
                                        "count": 0,
                                        "enable": false,
                                        "offset": 0,
                                        "speed": 1,
                                        "decay": 0,
                                        "sync": true
                                    },
                                    "l": {
                                        "count": 0,
                                        "enable": false,
                                        "offset": 0,
                                        "speed": 1,
                                        "decay": 0,
                                        "sync": true
                                    }
                                }
                            }
                        },
                        "zIndex": {
                            "random": {
                                "enable": false,
                                "minimumValue": 0
                            },
                            "value": 0,
                            "opacityRate": 1,
                            "sizeRate": 1,
                            "velocityRate": 1
                        },
                        "life": {
                            "count": 0,
                            "delay": {
                                "random": {
                                    "enable": false,
                                    "minimumValue": 0
                                },
                                "value": 0,
                                "sync": false
                            },
                            "duration": {
                                "random": {
                                    "enable": false,
                                    "minimumValue": 0.0001
                                },
                                "value": 0,
                                "sync": false
                            }
                        },
                        "roll": {
                            "darken": {
                                "enable": false,
                                "value": 0
                            },
                            "enable": false,
                            "enlighten": {
                                "enable": false,
                                "value": 0
                            },
                            "mode": "vertical",
                            "speed": 25
                        },
                        "tilt": {
                            "random": {
                                "enable": true,
                                "minimumValue": 0
                            },
                            "value": 0,
                            "animation": {
                                "enable": false,
                                "speed": 0,
                                "decay": 0,
                                "sync": false
                            },
                            "direction": "clockwise",
                            "enable": false
                        },
                        "twinkle": {
                            "lines": {
                                "enable": false,
                                "frequency": 0.05,
                                "opacity": 1
                            },
                            "particles": {
                                "enable": false,
                                "frequency": 0.05,
                                "opacity": 1
                            }
                        },
                        "wobble": {
                            "distance": 5,
                            "enable": false,
                            "speed": {
                                "angle": 50,
                                "move": 10
                            }
                        },
                        "orbit": {
                            "animation": {
                                "count": 0,
                                "enable": true,
                                "speed": 1,
                                "decay": 0,
                                "sync": false
                            },
                            "enable": false,
                            "opacity": 1,
                            "rotation": {
                                "random": {
                                    "enable": false,
                                    "minimumValue": 0
                                },
                                "value": 45
                            },
                            "width": 1
                        },
                        "repulse": {
                            "random": {
                                "enable": false,
                                "minimumValue": 0
                            },
                            "value": 0,
                            "enabled": true,
                            "distance": 1,
                            "duration": 1,
                            "factor": 1,
                            "speed": 1,
                            selectors: [".repulsing"]
                        }
                    },
                    "pauseOnBlur": true,
                    "pauseOnOutsideViewport": true,
                    "responsive": [],
                    "style": {},
                    "themes": [],
                    "zLayers": 100
                }}

            />
        }
        <h1 className={clsx(styles.beginText, "repulsing")} >Let{"'"}s begin!</h1>
    </div>
}