import resampler from "audio-resampler"
import toWav  from 'audiobuffer-to-wav'


export default async function audioResampler(input, sampleRate){
    if(!input) return input
    // return input;
    return new Promise(async (resolve, reject)=>{
        const AudioContext = window.AudioContext || window.webkitAudioContext;

        var audioCtx = new AudioContext();

        var audioFile = await audioCtx.decodeAudioData(await input.arrayBuffer());
        resampler(audioFile, sampleRate, function(event){
            var audBuff = new Blob([toWav(event.getAudioBuffer())]);
            resolve(audBuff)
        });
    })
}