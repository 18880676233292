import { useContext, useEffect, useRef, useState } from "react";
import { useCTLesson } from "../../const/ct";
import UserContext from "../../context/UserContext";
import CTRIO from "./CTRIO";
import { useSoundManager } from "../../utils/sound";
import { uploadToS3 } from "../../resources/api";
import wait from "../../utils/wait";

/**
 * @type (props: {itemNumber: number, status: string, setStatus: (value: string) => void, submitResult: any }) => JSX.ELEMENT
 */
export const ParagraphRIO = ({ itemNumber, status, setStatus, submitResult, start }) => {
    const { user: { grade } } = useContext(UserContext)
    const lesson = useCTLesson(itemNumber - 1, grade)
    /**
     * @type string[]
     */
    const sentenceList = lesson["sentenceList"]
    const breaks = lesson["paragraph"]["breaks"]
    const questions = {
        text: sentenceList,
        type: "rio"
    }
    const [sentenceResults, setSentenceResults] = useState({})
    const s3FieldsRef = useRef(null)
    const audioBlob = useRef(null)
    const metaBlob = useRef(null)
    const soundManager = useSoundManager()
    const [isQuestionDisabled, setIsQuestionDisabled] = useState(true)

    useEffect(() => {
        soundManager.playURL("https://read21-assets.s3.amazonaws.com/ct/gp/RIO-intro.mp3").then(() => {
            start(60 * 3)
            setIsQuestionDisabled(false)
        })
    }, [])
    return (
        <div className=" flex items-center justify-center w-full overflow-scroll">
            <CTRIO
                status={status}
                soundManager={soundManager}
                itemDetails={questions}
                questionIndex={0}
                questions={[questions]}
                sentenceResults={sentenceResults}
                setSentenceResults={setSentenceResults}
                s3FieldsRef={s3FieldsRef}
                audioBlob={audioBlob}
                setStatus={setStatus}
                metaBlob={metaBlob}
                isQuestionDisabled={
                    isQuestionDisabled
                }
                subType="ct-paragraph"
                breaks={breaks}
                isParagraphRIO={true}
                clickHandler={async () => {
                    const { url, uploadId } = s3FieldsRef.current
                    let isUploaded = false;
                    if (audioBlob.current) {
                        await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav");
                        isUploaded = true;
                    }
                    if (!metaBlob.current?.text) {
                        metaBlob.current = {
                            text: "No comments"
                        }
                    }
                    await submitResult({
                        answer: isUploaded ? uploadId : null,
                        recMeta: metaBlob.current.text
                    }, false, async (result) => {
                        await soundManager.cleanAllChannel()
                        if (result.sentence) {
                            const sentence = Object.keys(result.sentence).reduce((prev, wrd) => ({ ...prev, [wrd.toLowerCase()]: result.sentence[wrd] }), {})
                            setSentenceResults((results) => ({ ...results, [0]: sentence }))
                        }
                        // await wait(2000)
                        // start(3 * 60)
                        setStatus(null)
                    });
                    setStatus(null)
                }}
            />
        </div>
    )
}