import { useCallback, useContext, useEffect, useMemo } from "react";
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { availableCharacters } from "../../const/chars";
import { playSound } from "../../utils/playsound";
import styles from "./ResultPage.module.css";

import clsx from "clsx";
import Lottie from "react-lottie";
import Particles from "react-particles";
import { useNavigate } from "react-router-dom";
import { loadFull } from "tsparticles";
import UserContext from "../../context/UserContext";
import * as animationData from "../../lottie/star.json";
import { HomeIcon, NextArrow } from "../../resources/svgs";
import { pauseAll } from "../../utils/audios";
import Button from "./Button";


const characters = availableCharacters;


/**
 *
 * {
 *     show score here
 *
 * }
 *
 */

export default function ResultPage({ wasSuccessful, percentage, restart, lessonId, homePage, nextAvailable, nextLessonId, presentation, checkpoint }) {
    const { width, height } = useWindowSize()
    const navigate = useNavigate()
    const result = useMemo(() => ({ hasPassed: wasSuccessful }), [wasSuccessful])
    const { user } = useContext(UserContext);
    useEffect(() => {
        if (checkpoint) {
            return
        }
        (async () => {
            try {
                await pauseAll()
                if (result.hasPassed) {
                    //Fail piano

                    await playSound("success")
                    if (lessonId == "21") {
                        await playSound(`endings/l${lessonId}`)
                    } else {
                        await playSound(`endings/l${lessonId}pass`)
                    }
                } else {
                    //Stars audio
                    await playSound("failure")

                    if (nextAvailable) {
                        await playSound(`endings/avfail`)
                    } else {
                        if (lessonId == "21") {
                            if (presentation == "goBack") {

                            } else if (presentation == "locked") {
                                window.lockGame()
                            }
                        } else {
                            await playSound(`endings/l${lessonId}fail`)
                        }
                    }


                }
            } catch (error) {
                alert(error)
            }
        })()

    }, [result, presentation])
    const particlesInit = useCallback(async (engine) => {
        console.dir(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    return <>
        {
            result.hasPassed && (lessonId == 21 ?
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    options={
                        {
                            "autoPlay": true,
                            "background": {
                                "color": {
                                    "value": "#000"
                                },
                                "image": "",
                                "position": "",
                                "repeat": "",
                                "size": "",
                                "opacity": 1
                            },
                            "backgroundMask": {
                                "composite": "destination-out",
                                "cover": {
                                    "color": {
                                        "value": "#fff"
                                    },
                                    "opacity": 1
                                },
                                "enable": false
                            },
                            "fullScreen": {
                                "enable": true,
                                "zIndex": -1
                            },
                            "detectRetina": true,
                            "duration": 0,
                            "fpsLimit": 120,
                            "interactivity": {
                                "detectsOn": "window",
                                "events": {
                                    "onClick": {
                                        "enable": false,
                                        "mode": []
                                    },
                                    "onDiv": {
                                        "selectors": [],
                                        "enable": false,
                                        "mode": [],
                                        "type": "circle"
                                    },
                                    "onHover": {
                                        "enable": false,
                                        "mode": [],
                                        "parallax": {
                                            "enable": false,
                                            "force": 2,
                                            "smooth": 10
                                        }
                                    },
                                    "resize": true
                                },
                                "modes": {
                                    "attract": {
                                        "distance": 200,
                                        "duration": 0.4,
                                        "easing": "ease-out-quad",
                                        "factor": 1,
                                        "maxSpeed": 50,
                                        "speed": 1
                                    },
                                    "bounce": {
                                        "distance": 200
                                    },
                                    "bubble": {
                                        "distance": 200,
                                        "duration": 0.4,
                                        "mix": false,
                                        "divs": {
                                            "distance": 200,
                                            "duration": 0.4,
                                            "mix": false,
                                            "selectors": []
                                        }
                                    },
                                    "connect": {
                                        "distance": 80,
                                        "links": {
                                            "opacity": 0.5
                                        },
                                        "radius": 60
                                    },
                                    "grab": {
                                        "distance": 100,
                                        "links": {
                                            "blink": false,
                                            "consent": false,
                                            "opacity": 1
                                        }
                                    },
                                    "push": {
                                        "default": true,
                                        "groups": [],
                                        "quantity": 4
                                    },
                                    "remove": {
                                        "quantity": 2
                                    },
                                    "repulse": {
                                        "distance": 200,
                                        "duration": 0.4,
                                        "factor": 100,
                                        "speed": 1,
                                        "maxSpeed": 50,
                                        "easing": "ease-out-quad",
                                        "divs": {
                                            "distance": 200,
                                            "duration": 0.4,
                                            "factor": 100,
                                            "speed": 1,
                                            "maxSpeed": 50,
                                            "easing": "ease-out-quad",
                                            "selectors": []
                                        }
                                    },
                                    "slow": {
                                        "factor": 3,
                                        "radius": 200
                                    },
                                    "trail": {
                                        "delay": 1,
                                        "pauseOnStop": false,
                                        "quantity": 1
                                    },
                                    "light": {
                                        "area": {
                                            "gradient": {
                                                "start": {
                                                    "value": "#ffffff"
                                                },
                                                "stop": {
                                                    "value": "#000000"
                                                }
                                            },
                                            "radius": 1000
                                        },
                                        "shadow": {
                                            "color": {
                                                "value": "#000000"
                                            },
                                            "length": 2000
                                        }
                                    }
                                }
                            },
                            "manualParticles": [],
                            "motion": {
                                "disable": false,
                                "reduce": {
                                    "factor": 4,
                                    "value": true
                                }
                            },
                            "particles": {
                                "bounce": {
                                    "horizontal": {
                                        "random": {
                                            "enable": false,
                                            "minimumValue": 0.1
                                        },
                                        "value": 1
                                    },
                                    "vertical": {
                                        "random": {
                                            "enable": false,
                                            "minimumValue": 0.1
                                        },
                                        "value": 1
                                    }
                                },
                                "collisions": {
                                    "bounce": {
                                        "horizontal": {
                                            "random": {
                                                "enable": false,
                                                "minimumValue": 0.1
                                            },
                                            "value": 1
                                        },
                                        "vertical": {
                                            "random": {
                                                "enable": false,
                                                "minimumValue": 0.1
                                            },
                                            "value": 1
                                        }
                                    },
                                    "enable": false,
                                    "mode": "bounce",
                                    "overlap": {
                                        "enable": true,
                                        "retries": 0
                                    }
                                },
                                "color": {
                                    "value": "#fff",
                                    "animation": {
                                        "h": {
                                            "count": 0,
                                            "enable": false,
                                            "offset": 0,
                                            "speed": 1,
                                            "decay": 0,
                                            "sync": true
                                        },
                                        "s": {
                                            "count": 0,
                                            "enable": false,
                                            "offset": 0,
                                            "speed": 1,
                                            "decay": 0,
                                            "sync": true
                                        },
                                        "l": {
                                            "count": 0,
                                            "enable": false,
                                            "offset": 0,
                                            "speed": 1,
                                            "decay": 0,
                                            "sync": true
                                        }
                                    }
                                },
                                "groups": {},
                                "move": {
                                    "angle": {
                                        "offset": 0,
                                        "value": 90
                                    },
                                    "attract": {
                                        "distance": 200,
                                        "enable": false,
                                        "rotate": {
                                            "x": 3000,
                                            "y": 3000
                                        }
                                    },
                                    "center": {
                                        "x": 50,
                                        "y": 50,
                                        "mode": "percent",
                                        "radius": 0
                                    },
                                    "decay": 0,
                                    "distance": {},
                                    "direction": "none",
                                    "drift": 0,
                                    "enable": true,
                                    "gravity": {
                                        "acceleration": 15,
                                        "enable": true,
                                        "inverse": true,
                                        "maxSpeed": 100
                                    },
                                    "path": {
                                        "clamp": true,
                                        "delay": {
                                            "random": {
                                                "enable": false,
                                                "minimumValue": 0
                                            },
                                            "value": 0
                                        },
                                        "enable": false,
                                        "options": {}
                                    },
                                    "outModes": {
                                        "default": "destroy",
                                        "bottom": "destroy",
                                        "left": "destroy",
                                        "right": "destroy",
                                        "top": "none"
                                    },
                                    "random": false,
                                    "size": false,
                                    "speed": {
                                        "min": 10,
                                        "max": 20
                                    },
                                    "spin": {
                                        "acceleration": 0,
                                        "enable": false
                                    },
                                    "straight": false,
                                    "trail": {
                                        "enable": true,
                                        "length": 10,
                                        "fillColor": {
                                            "value": "#000"
                                        }
                                    },
                                    "vibrate": false,
                                    "warp": false
                                },
                                "number": {
                                    "density": {
                                        "enable": false,
                                        "area": 800,
                                        "factor": 1000
                                    },
                                    "limit": 0,
                                    "value": 0
                                },
                                "opacity": {
                                    "random": {
                                        "enable": false,
                                        "minimumValue": 0.1
                                    },
                                    "value": 1,
                                    "animation": {
                                        "count": 0,
                                        "enable": false,
                                        "speed": 2,
                                        "decay": 0,
                                        "sync": false,
                                        "destroy": "none",
                                        "startValue": "random"
                                    }
                                },
                                "reduceDuplicates": false,
                                "shadow": {
                                    "blur": 0,
                                    "color": {
                                        "value": "#000"
                                    },
                                    "enable": false,
                                    "offset": {
                                        "x": 0,
                                        "y": 0
                                    }
                                },
                                "shape": {
                                    "options": {},
                                    "type": "line"
                                },
                                "size": {
                                    "random": {
                                        "enable": false,
                                        "minimumValue": 1
                                    },
                                    "value": {
                                        "min": 0.1,
                                        "max": 50
                                    },
                                    "animation": {
                                        "count": 0,
                                        "enable": true,
                                        "speed": 90,
                                        "decay": 0,
                                        "sync": true,
                                        "destroy": "min",
                                        "startValue": "max"
                                    }
                                },
                                "stroke": {
                                    "width": 1,
                                    "color": {
                                        "value": "#ffffff",
                                        "animation": {
                                            "h": {
                                                "count": 0,
                                                "enable": false,
                                                "offset": 0,
                                                "speed": 1,
                                                "decay": 0,
                                                "sync": true
                                            },
                                            "s": {
                                                "count": 0,
                                                "enable": false,
                                                "offset": 0,
                                                "speed": 1,
                                                "decay": 0,
                                                "sync": true
                                            },
                                            "l": {
                                                "count": 0,
                                                "enable": false,
                                                "offset": 0,
                                                "speed": 1,
                                                "decay": 0,
                                                "sync": true
                                            }
                                        }
                                    }
                                },
                                "zIndex": {
                                    "random": {
                                        "enable": false,
                                        "minimumValue": 0
                                    },
                                    "value": 0,
                                    "opacityRate": 1,
                                    "sizeRate": 1,
                                    "velocityRate": 1
                                },
                                "life": {
                                    "count": 1,
                                    "delay": {
                                        "random": {
                                            "enable": false,
                                            "minimumValue": 0
                                        },
                                        "value": 0,
                                        "sync": false
                                    },
                                    "duration": {
                                        "random": {
                                            "enable": false,
                                            "minimumValue": 0.0001
                                        },
                                        "value": 0,
                                        "sync": false
                                    }
                                },
                                "rotate": {
                                    "random": {
                                        "enable": false,
                                        "minimumValue": 0
                                    },
                                    "value": 0,
                                    "animation": {
                                        "enable": false,
                                        "speed": 0,
                                        "decay": 0,
                                        "sync": false
                                    },
                                    "direction": "clockwise",
                                    "path": true
                                },
                                "destroy": {
                                    "bounds": {},
                                    "mode": "split",
                                    "split": {
                                        "count": 1,
                                        "factor": {
                                            "random": {
                                                "enable": false,
                                                "minimumValue": 0
                                            },
                                            "value": 0.333333
                                        },
                                        "rate": {
                                            "random": {
                                                "enable": false,
                                                "minimumValue": 0
                                            },
                                            "value": 100
                                        },
                                        "sizeOffset": true,
                                        "particles": {
                                            "stroke": {
                                                "width": 0
                                            },
                                            "color": {
                                                "value": [
                                                    "#ff595e",
                                                    "#ffca3a",
                                                    "#8ac926",
                                                    "#1982c4",
                                                    "#6a4c93"
                                                ]
                                            },
                                            "number": {
                                                "value": 0
                                            },
                                            "collisions": {
                                                "enable": false
                                            },
                                            "opacity": {
                                                "value": {
                                                    "min": 0.1,
                                                    "max": 1
                                                },
                                                "animation": {
                                                    "enable": true,
                                                    "speed": 0.7,
                                                    "sync": false,
                                                    "startValue": "max",
                                                    "destroy": "min"
                                                }
                                            },
                                            "shape": {
                                                "type": "circle"
                                            },
                                            "size": {
                                                "value": {
                                                    "min": 1,
                                                    "max": 2
                                                },
                                                "animation": {
                                                    "enable": true,
                                                    "speed": 5,
                                                    "count": 1,
                                                    "sync": false,
                                                    "startValue": "min",
                                                    "destroy": "none"
                                                }
                                            },
                                            "life": {
                                                "count": 1,
                                                "duration": {
                                                    "value": {
                                                        "min": 1,
                                                        "max": 2
                                                    }
                                                }
                                            },
                                            "move": {
                                                "decay": 0.05,
                                                "enable": true,
                                                "gravity": {
                                                    "enable": true,
                                                    "inverse": false,
                                                    "acceleration": 5
                                                },
                                                "speed": {
                                                    "min": 5,
                                                    "max": 10
                                                },
                                                "direction": "none",
                                                "outModes": "destroy"
                                            }
                                        }
                                    }
                                },
                                "roll": {
                                    "darken": {
                                        "enable": false,
                                        "value": 0
                                    },
                                    "enable": false,
                                    "enlighten": {
                                        "enable": false,
                                        "value": 0
                                    },
                                    "mode": "vertical",
                                    "speed": 25
                                },
                                "tilt": {
                                    "random": {
                                        "enable": false,
                                        "minimumValue": 0
                                    },
                                    "value": 0,
                                    "animation": {
                                        "enable": false,
                                        "speed": 0,
                                        "decay": 0,
                                        "sync": false
                                    },
                                    "direction": "clockwise",
                                    "enable": false
                                },
                                "twinkle": {
                                    "lines": {
                                        "enable": false,
                                        "frequency": 0.05,
                                        "opacity": 1
                                    },
                                    "particles": {
                                        "enable": false,
                                        "frequency": 0.05,
                                        "opacity": 1
                                    }
                                },
                                "wobble": {
                                    "distance": 5,
                                    "enable": false,
                                    "speed": {
                                        "angle": 50,
                                        "move": 10
                                    }
                                },
                                "orbit": {
                                    "animation": {
                                        "count": 0,
                                        "enable": false,
                                        "speed": 1,
                                        "decay": 0,
                                        "sync": false
                                    },
                                    "enable": false,
                                    "opacity": 1,
                                    "rotation": {
                                        "random": {
                                            "enable": false,
                                            "minimumValue": 0
                                        },
                                        "value": 45
                                    },
                                    "width": 1
                                },
                                "links": {
                                    "blink": false,
                                    "color": {
                                        "value": "#fff"
                                    },
                                    "consent": false,
                                    "distance": 100,
                                    "enable": false,
                                    "frequency": 1,
                                    "opacity": 1,
                                    "shadow": {
                                        "blur": 5,
                                        "color": {
                                            "value": "#000"
                                        },
                                        "enable": false
                                    },
                                    "triangles": {
                                        "enable": false,
                                        "frequency": 1
                                    },
                                    "width": 1,
                                    "warp": false
                                },
                                "repulse": {
                                    "random": {
                                        "enable": false,
                                        "minimumValue": 0
                                    },
                                    "value": 0,
                                    "enabled": false,
                                    "distance": 1,
                                    "duration": 1,
                                    "factor": 1,
                                    "speed": 1
                                }
                            },
                            "pauseOnBlur": true,
                            "pauseOnOutsideViewport": true,
                            "responsive": [],
                            "smooth": false,
                            "style": {},
                            "themes": [],
                            "zLayers": 100,
                            "emitters": {
                                "autoPlay": true,
                                "fill": true,
                                "life": {
                                    "wait": false,
                                    "count": 0,
                                    "delay": 0.1,
                                    "duration": 0.1
                                },
                                "rate": {
                                    "quantity": 1,
                                    "delay": 0.15
                                },
                                "shape": "square",
                                "startCount": 0,
                                "size": {
                                    "mode": "percent",
                                    "height": 0,
                                    "width": 100
                                },
                                "direction": "top",
                                "particles": {},
                                "position": {
                                    "x": 50,
                                    "y": 100
                                }
                            }
                        }
                    }

                />
                : <Confetti
                    width={width}
                    height={height}
                />)
        }
        {
            result.hasPassed && <div className={clsx(styles.successPage, lessonId != 21 && styles.colored)} >
                <>
                    {
                        (lessonId == 21) ? <>
                            <Lottie
                                loop={true}
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: animationData,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                width={300}
                                height={300}
                            />


                            <h2 className={styles.magicText}>
                                You're a good reader!
                            </h2>


                            <div className={styles.btnCta} role="button" onClick={() => navigate("/critical")}>
                                Continue to Critical Thinking
                                <NextArrow />
                            </div>
                        </> : <>
                            {
                                typeof percentage == "number" && <h1 style={{ fontSize: 120, marginBottom: 10 }}>{
                                    user?.userType === "trainee" ? "Student score appears here" : `${Math.floor(percentage * 100)}%`
                                }</h1>
                            }
                            <h1>Wonderful!</h1>
                        </>
                    }

                    {
                        (lessonId != 21 && checkpoint == false) &&
                        <div className={styles.buttonsContainer}>
                            <Button direction="left" onClick={async () => {
                                await pauseAll()

                                restart()
                            }} icon={"repeat"} border={"white"} background={"blue"} title={`Repeat`} />

                            <Button direction="left" onClick={async () => {
                                await pauseAll()
                                if (user?.l21SupportDeactivated) {
                                    if (lessonId === 12) {
                                        return navigate("/lessons/17")
                                    } else if (lessonId === 17) {
                                        return navigate("/lesson/18")
                                    } else if (lessonId === 18) {
                                        return navigate("/lesson/21")
                                    }
                                }

                                /**
                                 * this means lesson 5 is locked
                                 */
                                if (lessonId < 5 && nextLessonId > 5) {
                                    return navigate("/checkpoint/0")
                                }

                                if (lessonId < 12 && nextLessonId > 12) {
                                    return navigate("/checkpoint/1")
                                }

                                if (lessonId < 16 && nextLessonId > 16) {
                                    return navigate("/checkpoint/2")
                                }

                                if (lessonId === 5) {
                                    return navigate("/checkpoint/0")
                                }
                                if (lessonId === 12) {
                                    return navigate("/checkpoint/1")
                                }
                                if (lessonId === 16) {
                                    return navigate("/checkpoint/2")
                                }
                                navigate("/lessons/" + nextLessonId)
                            }} icon={"nextarrow"} border={"white"} background={"green"} title={`Next Lesson`} />

                        </div>
                    }

                </>
                {
                    (lessonId != 21) && <div onClick={() => {
                        playSound("click").then(r => homePage())
                    }}>

                        <HomeIcon />
                    </div>
                }

            </div>
        }
        {
            !result.hasPassed && <div className={nextAvailable ? styles.failButMovePage : styles.failPage}>
                <h1>Oops!</h1>
                <img src={"https://read21-assets.s3.amazonaws.com/assets/images/graphics/sadface.png"} />
                {
                    nextAvailable ? <>
                        <div className={styles.buttonsContainer}>

                            <Button direction="left" onClick={async () => {
                                await pauseAll()
                                if (lessonId === 0 && nextAvailable === 17) {
                                    navigate("/checkpoint/3")
                                } else {
                                    navigate("/lessons/" + (nextAvailable))
                                }
                            }} icon={"nextarrow"} border={"white"} background={"green"} title={`Next Lesson`} />

                        </div>
                    </> : <>
                        {
                            (presentation == "goBack" && !checkpoint) ?
                                <Button onClick={async () => {
                                    await pauseAll()
                                    navigate("/lessons/12")
                                }} icon={"nextarrow"} border={"white"} background={"blue"} title={`L12 (Syllable Breakdown)`} />
                                : presentation === "locked" || checkpoint ? <></> : <Button onClick={async () => {
                                    await pauseAll()

                                    restart()
                                }} icon={"repeat"} border={"white"} background={"blue"} title={`Repeat`} />
                        }
                    </>
                }

                <div onClick={async () => {
                    await pauseAll()
                    playSound("click").then(r => homePage())

                }}>

                    <HomeIcon />
                </div>

            </div>
        }
    </>
}