export const Mountain = ({ className }) => {
  return (
    <svg viewBox="0 0 430 151" className={className}>
      <path
        d="M314.753 79.4169L314.738 79.4008L314.711 78.631L323.785 75.0965L314.731 78.0654L314.696 78.2173L314.484 72.0627L322.338 67.9818L314.479 70.99L314.757 58.4313L314.757 58.3893L314.754 58.4298L314.084 68.2069L307.077 62.285L314.047 69.0549L313.808 74.4452L307.385 67.4009L313.772 75.2374L313.638 78.2345L313.27 86.5384L313.243 87.159C313.842 87.3217 314.435 87.476 315.022 87.6221L314.741 79.4759L314.753 79.4169Z"
        fill="#8C8C8C"
      />
      <path
        d="M314.753 79.4169L314.738 79.4008L314.711 78.631L323.785 75.0965L314.731 78.0654L314.696 78.2173L314.484 72.0627L322.338 67.9818L314.479 70.99L314.757 58.4313L314.757 58.3893L314.754 58.4298L314.084 68.2069L307.077 62.285L314.047 69.0549L313.808 74.4452L307.385 67.4009L313.772 75.2374L313.638 78.2345L313.27 86.5384L313.243 87.159C313.842 87.3217 314.435 87.476 315.022 87.6221L314.741 79.4759L314.753 79.4169Z"
        fill="white"
        fill-opacity="0.2"
      />
      <path
        d="M300.753 67.4169L300.738 67.4008L300.711 66.631L309.785 63.0965L300.731 66.0654L300.696 66.2173L300.484 60.0627L308.338 55.9818L300.479 58.99L300.757 46.4313L300.757 46.3893L300.754 46.4298L300.084 56.2069L293.077 50.285L300.047 57.0549L299.808 62.4452L293.385 55.4009L299.772 63.2374L299.638 66.2345L299.27 74.5384L299.243 75.159C299.842 75.3217 300.435 75.476 301.022 75.6221L300.741 67.4759L300.753 67.4169Z"
        fill="#8C8C8C"
      />
      <path
        d="M124.587 54.796L124.57 54.7904L124.253 54.2523L129.351 48.2238L124.049 53.8418L124.083 53.9634L121.551 49.6602L125.568 43.7145L121.132 48.898L116.472 39.8423L116.456 39.8123L116.469 39.8424L119.774 47.0678L112.491 45.559L120.076 47.6861L121.991 51.6193L114.689 49.0848L122.271 52.1977L123.336 54.3849L126.285 60.4439L126.506 60.8966C126.996 60.7807 127.478 60.6613 127.953 60.5383L124.601 54.8428L124.587 54.796Z"
        fill="#8C8C8C"
      />
      <path
        d="M111.668 59.5884L111.647 59.5815L111.256 58.9177L117.544 51.482L111.003 58.4113L111.045 58.5614L107.922 53.2537L112.877 45.9201L107.406 52.3135L101.658 41.1439L101.638 41.1069L101.655 41.144L105.731 50.0561L96.7473 48.1951L106.104 50.8187L108.465 55.6701L99.4594 52.5439L108.811 56.3836L110.124 59.0812L113.762 66.5547L114.035 67.113C114.639 66.9701 115.234 66.8228 115.819 66.671L111.685 59.6461L111.668 59.5884Z"
        fill="#8C8C8C"
      />
      <g filter="url(#filter0_d_173_16)">
        <path
          d="M413.365 140.564L8.50854 140.564C7.19584 143.19 5.25104 134.177 7.71647 132.584L115.693 62.8129L130.493 55.2911C134.948 53.0267 138.995 50.0349 142.466 46.4389C146.94 41.8025 152.36 38.1828 158.357 35.8253L175.785 28.9742C176.961 28.1841 200.677 8.94458 203.182 7.06467C205.185 5.95668 206.97 2.75885 209.355 2.76523C211.74 2.77161 214.228 2.31913 216.286 2.78384C218.345 3.24855 220.047 3.91304 221.219 4.70943L328.136 84.3834L344.108 90.1864C352.175 93.1174 359.704 97.3598 366.389 102.742L413.365 140.564Z"
          fill="url(#paint0_linear_173_16)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_173_16"
          x="6.53223"
          y="0.77373"
          width="422.633"
          height="150.067"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="10" dy="4" />
          <feGaussianBlur stdDeviation="2.9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.726823 0 0 0 0 0.675766 0 0 0 0 0.675766 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_173_16"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_173_16"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_173_16"
          x1="207.043"
          y1="2.55493"
          x2="207.009"
          y2="104.064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#61ADD1" />
          <stop offset="1" stop-color="#B9E4FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Mountain2 = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 315 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.038 23.0607L105.023 23.0446L104.996 22.2748L114.07 18.7403L105.016 21.7092L104.981 21.8611L104.769 15.7065L112.622 11.6256L104.763 14.6338L105.041 2.07506L105.042 2.03305L105.039 2.07362L104.368 11.8507L97.3614 5.92879L104.332 12.6987L104.093 18.089L97.6698 11.0447L104.056 18.8812L103.923 21.8783L103.555 30.1822L103.528 30.8028C104.127 30.9655 104.72 31.1198 105.307 31.2659L105.026 23.1197L105.038 23.0607Z"
        fill="#8C8C8C"
      />
      <path
        d="M243.038 49.0607L243.023 49.0446L242.996 48.2748L252.07 44.7403L243.016 47.7092L242.981 47.8611L242.769 41.7065L250.622 37.6256L242.763 40.6338L243.041 28.0751L243.042 28.0331L243.039 28.0736L242.368 37.8507L235.361 31.9288L242.332 38.6987L242.093 44.089L235.67 37.0447L242.056 44.8812L241.923 47.8783L241.555 56.1822L241.528 56.8028C242.127 56.9655 242.72 57.1198 243.307 57.2659L243.026 49.1197L243.038 49.0607Z"
        fill="#8C8C8C"
      />
      <g filter="url(#filter0_i_179_9)">
        <path
          d="M313.995 89.6682L-9.43467e-05 87.9988L61.0343 52.3125L86.7459 41.9593L104.83 26.4768C110.168 21.9069 116.028 17.9853 122.289 14.7938L144.961 3.23532C146.367 2.52476 148.127 1.9444 150.128 1.53198C152.128 1.11956 154.324 0.884333 156.572 0.841591C158.82 0.79885 161.07 0.949564 163.176 1.28392C165.282 1.61827 167.196 2.12877 168.795 2.78222C169.204 2.9676 169.584 3.16328 169.933 3.36809L313.995 89.6682Z"
          fill="url(#paint0_linear_179_9)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_179_9"
          x="0"
          y="-3.16553"
          width="313.996"
          height="92.8337"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.247059 0 0 0 0 0.67451 0 0 0 0 0.894118 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_179_9"
          />
        </filter>
        <linearGradient
          id="paint0_linear_179_9"
          x1="157.466"
          y1="0.834719"
          x2="156.998"
          y2="88.8335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#39BAFD" />
          <stop offset="1" stop-color="#5BB7E7" stop-opacity="0.95" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const Mountain3 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="314"
      height="90"
      viewBox="0 0 314 90"
      fill="none"
      className="z-30"
    >
      <g filter="url(#filter0_i_128_6)">
        <path
          d="M313.995 89.6682L-9.43467e-05 87.9988L61.0343 52.3125L86.7459 41.9593L104.83 26.4768C110.168 21.9069 116.028 17.9853 122.289 14.7938L144.961 3.23532C146.367 2.52476 148.127 1.9444 150.128 1.53198C152.128 1.11956 154.324 0.884333 156.572 0.841591C158.82 0.79885 161.07 0.949564 163.176 1.28392C165.282 1.61827 167.196 2.12877 168.795 2.78222C169.204 2.9676 169.584 3.16328 169.933 3.36809L313.995 89.6682Z"
          fill="url(#paint0_linear_128_6)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_128_6"
          x="0"
          y="-3.16553"
          width="313.996"
          height="92.8337"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.247059 0 0 0 0 0.67451 0 0 0 0 0.894118 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_128_6"
          />
        </filter>
        <linearGradient
          id="paint0_linear_128_6"
          x1="157.466"
          y1="0.834719"
          x2="156.998"
          y2="88.8335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7ABDDE" />
          <stop offset="1" stop-color="#B8E4FA" stop-opacity="1.00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const DesertMountain = ({ className }) => {
  return (
    <svg
      width="100%"
      height="100%"
      className={className}
      viewBox="0 0 314 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M313.995 89.6682L-9.43467e-05 87.9988L61.0343 52.3125L86.7459 41.9593L104.83 26.4768C110.168 21.9069 116.028 17.9853 122.289 14.7938L144.961 3.23532C146.367 2.52476 148.127 1.9444 150.128 1.53198C152.128 1.11956 154.324 0.884333 156.572 0.841591C158.82 0.79885 161.07 0.949564 163.176 1.28392C165.282 1.61827 167.196 2.12877 168.795 2.78222C169.204 2.9676 169.584 3.16328 169.933 3.36809L313.995 89.6682Z"
        fill="url(#paint0_linear_189_182)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_189_182"
          x1="164.466"
          y1="33.0029"
          x2="160.5"
          y2="103.168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA500" />
          <stop offset="1" stop-color="#FFC93F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
