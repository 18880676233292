import styles from './plusTrain.module.css'
import {useEffect, useState} from "react";
import {playSound} from "../../utils/playsound";
import {waitForVoice} from "../../utils/audios";
import wait from "../../utils/wait";
import clsx from "clsx";
import {RecordIcon, SpeakerIcon} from "../../resources/svgs";
import * as processingAnimation from "../../lottie/processing.json";
import Lottie from "react-lottie";
import axios from "axios";

function playArraySound(arrayBuffer) {
    return new Promise((resolve, reject) => {
        // Convert the ArrayBuffer to a Blob
        const blob = new Blob([arrayBuffer], { type: 'audio/wav' }); // Assuming it's a wav file, change the type if it's different

        // Create an Object URL from the blob
        const audioURL = URL.createObjectURL(blob);

        // Create a new Audio object and play it
        const audio = new Audio(audioURL);
        audio.play();


        // Remember to revoke the URL when you're done to free up resources
        audio.onended = () => {
            URL.revokeObjectURL(audioURL);

            resolve()

        };
        audio.onerror = (e)=>{
            URL.revokeObjectURL(audioURL);
            alert("word")
            reject(e)
        }
    });

}
export default function PlusTrainWiget({}){
    const [plusTrain, setPlusTrain] = useState(null)
    const [isBegun, setIsBegun] = useState(false)
    const [step, setStep] = useState("individual")
    const [itemId, setItemId] = useState(0)
    const [markedWord, setMarkedWord] = useState(null)
    const [marks, setMarks] = useState({})
    const [isPlaying, setIsPlaying] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isAskingGroup, setIsAskingGroup] = useState(false)
    useEffect(()=>{
        if(plusTrain){
            (async()=>{
                setStep("individual")
                setItemId(0)
                setMarks({})
                setIsPlaying(false)
                setIsRecording(false)
                setIsProcessing(false)
                await wait(2000)
                await  playSound('plustrain')
                await wait(1000)
                setIsBegun(true)
            })().catch(()=>alert("something's wrong"))


        }
    }, [plusTrain])
    useEffect(()=>{
        window.initiatePlusTrain = async (data)=>{
            setPlusTrain(data)


        }
    }, [])
    useEffect(()=>{
        (async()=>{

            if(isBegun && step === "individual" && plusTrain){
                var dataObject = plusTrain.syllables[itemId].audio
                let arrayBuffer = new Uint8Array(dataObject.data).buffer;
                setIsPlaying(true)
                await playArraySound(arrayBuffer)
                await wait(500)
                setIsPlaying(false)
                setIsRecording(true)
                const audio = await waitForVoice(4, 1, "norepeat", 3000, {speechTimeOut: 500})
                setIsRecording(false)

                setIsProcessing(true)
                //soapbox phonetic accessment
                var result = "wrong"
                if(audio) {
                    const formData = new FormData();
                    formData.append("category", `<custom-word pronunciation="${plusTrain.syllables[itemId].arpabets.join(" ")}">${plusTrain.syllables[itemId].arpabets.join("")}</custom-word>`)
                    formData.append("user_token", "user")
                    formData.append("file", audio, "audio.wav")
                    const sboxResponse = await axios.post("https://api.soapboxlabs.com/v1/speech/verification", formData, {
                        headers: {
                            'X-App-Key': "c52f6cd6-b9df-11ed-a59a-c5d2ce926d6f"
                        },
                        validateStatus() {
                            return true
                        }
                    });
                    setIsProcessing(false)

                    if (sboxResponse.status == 200) {
                        var hypothesis = sboxResponse.data.results[0].hypothesis_score
                        if (hypothesis >= 70) {
                            result = "correct"

                        } else {


                        }
                    } else {

                    }
                }

                setMarks((preMark)=>{
                    return {
                        ...preMark,
                        [itemId]: result
                    }
                })
                if(result === "correct"){
                    await playSound('correct')
                }else{
                    await playSound('wrong')
                }



                await wait(1000)
                if(itemId < plusTrain.syllables.length-1){
                    setItemId(itemId+1)
                }else{
                    setStep("group")
                }


            }else if (step === "group"){
                // loop 3 times
                setMarkedWord(null)
                for (let l = 0; l < 2; l++) {
                    for (let i = 0; i < plusTrain.syllables.length; i++) {
                        setMarkedWord(i)
                        var dataObject = plusTrain.syllables[i].audio
                        let arrayBuffer = new Uint8Array(dataObject.data).buffer;

                        setIsPlaying(true)
                        await playArraySound(arrayBuffer)
                        setIsPlaying(false)
                        await wait(600 - (l*200))
                    }
                }
                await wait(1000)


                setIsAskingGroup(true)

                await wait(2000)

                setPlusTrain(null)
                /// reset all the states
                setIsBegun(false)
                setStep("individual")
                setItemId(0)
                setMarks({})
                setIsPlaying(false)
                setIsRecording(false)
                setIsProcessing(false)
                setIsAskingGroup(false)

                window.plusTrainResolve(true)
            }
        })().catch(console.error)


    }, [isBegun, step, itemId])
    if(plusTrain){
        return <div className={styles.plusContainer}
        >

            <div className={styles.plusTrainerDialog} key={"esfd"}>
                <div className={styles.dialogContent}>
                    <div className={clsx(styles.syllableList, isBegun && styles.begun)}>
                        {
                            plusTrain.syllables.map((item, index)=>{
                                return <><div className={clsx(
                                    styles.itemContainer,
                                    isAskingGroup && styles.noGap
                                )}>
                                    <div className={clsx(styles.item,
                                        item.syllable?.length > 1 ? styles.word : styles.letter,
                                        (step == "individual") && isRecording && (itemId === index) && styles.recording,
                                        (step == "individual") && isPlaying && (itemId === index) && styles.playing,
                                        (step == "individual") && isProcessing && (itemId === index) && styles.processing,
                                        (step == "individual") && (marks[index] === "correct") && styles.correct,
                                        (step == "individual") && (marks[index] === "wrong") && styles.wrong,
                                        (step == "group") && !isAskingGroup && (markedWord === index) && styles.marked,
                                    )}>
                                        <span className={styles.itemText}>
                                            {item.syllable}
                                                {/*{(index < plusTrain.syllables.length-1) ? " . ":""}*/}
                                        </span>
                                    </div>
                                    <div className={clsx(styles.statusContainer, (isRecording||isPlaying||isProcessing) && ( (step == "individual") && (itemId === index)) && styles.visible)}>
                                        {
                                            isRecording && (itemId === index) && <><div style={{color: "#3498db", width: 30, height: 30}}><RecordIcon /></div></>
                                        }
                                        {
                                            isPlaying && (itemId === index) && <span style={{color: "#3498db"}}><SpeakerIcon /></span>
                                        }
                                        {
                                            isProcessing && (itemId === index) && <div style={{background:"#3498db", padding: 5, borderRadius: 20}}> <Lottie
                                                loop={true}
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData:   processingAnimation,

                                                }}
                                                width={30}
                                                height={30}

                                            /></div>
                                        }
                                    </div>

                                </div>
                                {
                                    (index < plusTrain.syllables.length-1) && <div className={clsx(styles.syllableSeparator,
                                      isAskingGroup && styles.hidden
                                    )}/>
                                }
                                </>
                            })
                        }
                    </div>

                    {/*<button onClick={()=>{*/}
                    {/*setPlusTrain(null)*/}
                    {/*    window.plusTrainResolve(true)*/}
                    {/*}*/}
                    {/*}/>*/}
                </div>

            </div>
        </div>
    }

}