import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from "../components/common/Button"
import Landing from "../components/common/Landing"
import MyReports from "../components/common/MyReports"
import ProfileModal from '../components/common/Profile'
import Introduction from "../components/introduction/Introduction"
// import Maze from '../components/maze'
import { LessonTitles } from '../const/lessonTitles'
import UserContext from '../context/UserContext'
import { pingAccount } from "../resources/api"
import styles from '../styles/Home.module.css'
import { playSound } from '../utils/playsound'
import { skip } from '../utils/skip'
import { Maze } from "../components/new-maze"

var proceduralInterval;
export default function Home() {

    const navigate = useNavigate();

    const [mazifying, setMazifying] = useState(false);
    const [reportOpen, setIsReportOpen] = useState(false)
    const [isIntroducing, setIsIntroducing] = useState(true)
    const [landingOpen, setLandingOpening] = useState(true);

    const [profile, setProfile] = useState(false)
    const { user, isGameLocked } = useContext(UserContext);

    const lessonObject = useMemo(() => {
        if (!user) {
            return null
        }
        const progresses = [...user.progresses];
        var highestLesson;



        var currentLesson = Array.from(new Array(21)).map((_, i) => {
            var prgItem = progresses
                .find((a) =>
                    a.lessonId == i)
            console.log(i, "prgItem:", prgItem)
            return {
                lessonId: i,
                isLocked: (prgItem && ((prgItem?.isUnlocked === false) || (prgItem?.hasPassed)))
            }
        })
            .filter(a => !a.isLocked)
            .map(a => a.lessonId)
            //     ||
            //     ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
            // )
            // .map((a, i)=>{
            //     return ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
            //         ? (a.lessonId - 1) : a.lessonId
            // })
            .sort((a, b) => a - b)
            ?.[0];
        currentLesson = (currentLesson >= 1) ? currentLesson : 1;
        for (let i = 0; i <= 21; i++) {
            var progress = progresses.find(prg => prg.lessonId == i + 1)
            if (progress?.isUnlocked && !progress.hasPassed) {
                highestLesson = i + 1;
                if (currentLesson == i + 1) {
                    break;
                }
            } else if (!progress) {
                highestLesson = i + 1;
                break;
            }

        }
        if (highestLesson > currentLesson) {
            currentLesson = highestLesson
        }
        return { id: currentLesson, title: LessonTitles[currentLesson] }
    }, [user]);

    const isIntroductionRequired = useMemo(() => {
        if (skip("pretest")) return false
        const isInPretest = user?.progresses?.find(a => (!a.hasPassed && a.lessonId === 0))
        return (isIntroducing && user && (!user?.introductionPresented)) || (user && isInPretest)
    }, [user, isIntroducing])


    useEffect(() => {
        clearInterval(proceduralInterval)
        proceduralInterval = setInterval(() => {
            pingAccount()
        }, 5000)
        return () => {
            clearInterval(proceduralInterval)
        }
    }, [])

    return (
        <div onClick={(e) => {
            e.stopPropagation()
            return true;
        }} className={styles.container}>
            {/** After login, this will slide down and show the maze and game starting */}
            {landingOpen && <Landing initMaze={() => {
                async function callback() {
                    await playSound('ambience', 0.05, true)

                }

                callback()
                setMazifying(true)

            }} removeComponent={() => {
                setLandingOpening(false)
            }} />}
            {
                (isIntroductionRequired && !user?.introductionPresented) && <Introduction onNext={() => {
                    async function callback() {
                        await playSound('ambience', 0.05, true)

                    }
                    callback()
                    setMazifying(true)
                    setIsIntroducing(false)
                }
                } />
            }
            {
                reportOpen && <MyReports close={() => {
                    playSound("click")
                    setIsReportOpen(false)
                }} />
            }
            {
                profile && <ProfileModal playLatest={() => {
                    playSound("click")
                    navigate(`/lessons/${lessonObject.id}`)
                }} removeComponent={() => {
                    playSound("click")
                    setProfile(false)
                }} />
            }
            {/** move microphone request to Landing */}

            {
                /**
                 * Show phaser maze and figma UI
                 * Add more characters
                 */
            }
            {/* {
                isGameLocked && <div className={styles.adminRequired}>

                    <svg width="170" height="170" viewBox="0 0 1200 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10_13)">
                            <path
                                d="M600 0C268.629 0 0 268.629 0 600C0 931.371 268.629 1200 600 1200C931.371 1200 1200 931.371 1200 600C1200 268.629 931.371 0 600 0ZM595.679 274.658C676.705 275.044 750.767 326.714 782.227 398.804C792.989 423.913 798.706 449.707 798.706 477.686V549.025H896.997V925.342H303.003V549.023H393.384C392.565 498.617 391.528 440.953 409.204 398.802C443.574 322.893 514.652 274.272 595.679 274.658ZM591.284 394.482C546.403 395.426 516.804 429.555 512.474 477.684V549.023H679.614V476.953C677.553 431.312 643.01 395.739 595.677 394.482C594.213 394.447 592.748 394.447 591.284 394.482V394.482Z"
                                fill="#fff" />
                        </g>
                        <defs>
                            <clipPath id="clip0_10_13">
                                <rect width="1200" height="1200" fill="transparent" />
                            </clipPath>
                        </defs>
                    </svg>


                    <h1>Contact your administrator.</h1>
                </div>
            } */}
            {
                !isGameLocked && <> <Maze lessonClicked={(lessonId) => {
                    playSound("click")
                    navigate(`/lessons/${lessonId}`)
                }} />
                    {/* <div className={styles.footer}>
                        <div className={styles.left}>
                            <img src={"/read21.svg"} className={styles.footerLogo} />
                        </div>
                        <div className={styles.center}>
                            {
                                lessonObject ? <button onClick={() => {
                                    playSound("click")
                                    navigate(`/lessons/${lessonObject.id}`)
                                }} className={styles.playButton}>
                                    <svg width="22" height="44" viewBox="0 0 39 44" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 2V42M37 22L2 42M37 22L2 2" stroke="white" stroke-width="4"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                </button> : <span>Loading</span>
                            }

                        </div>
                        <div className={styles.right}>
                            <Button onClick={() => {
                                setIsReportOpen(true)
                            }
                            } border={"none"} background={"grey"} title={"My Reports"} direction={"right"} icon={"download"}></Button>


                            <img onClick={() => {
                                playSound("click");
                                setProfile(true)
                            }} src={user?.photoURL || "/profile.png"} className={styles.footerProfile} />
                        </div>


                    </div> */}

                </>
            }
        </div>
    )
}
