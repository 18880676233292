
import styles from "./Colorful.module.css"
import clsx from "clsx";
import { RecordIcon, WrongIcon, CorrectIcon } from "../../resources/svgs";
import { genColor, randomIntFromInterval } from "../../utils/common";
import FloatingObject from "../../components/common/FloatingObjects";
import Lottie from "react-lottie";
import * as animationData from "../../lottie/processing.json";
import BeatRead21 from "../../components/common/BeatRead21";
import BaseStage from "../interfaces/BaseStage";
import {useWindowSize} from "react-use";
import wait from "../../utils/wait";
import {useInstruction} from "../../utils/instruction";
import React from "react";



class Colorful extends BaseStage {
  async componentDidMount() {
    await wait(1000)
    this.props.controls.initiateQuestions()
  }

  render() {
    const {status, data, current, nextItem, repeatId, trialId, countDown, stageDetails: {subType}, windowSize:{width} } = this.props
    const isPageChanging = ((nextItem != null) && (nextItem !== current));

    return <div style={{ display: "flex", flexDirection: "column", alignSelf: "stretch", flex: 1 }}><div style={{
      "--hexcolor":
          status === "pass" ? "#16a085" : status === "fail" ? "#af4154" :
              genColor(Object.keys(data).indexOf(current))
    }} className={clsx(styles.container, (status && status != "started") && styles.performing, styles[subType||"normal"], isPageChanging && styles.isPageChanging)} key={current}>
      {
        subType === "normal" && <>
            <div className={styles.left} >
              <span>{data[current].example}</span>

            </div>

            <div className={styles.right} >
              <span>{data[current].text}</span>
              <FloatingObject white={true} />
            </div>
            <div className={styles.actionIndicator}>
              {
                  (status == "recording") && <RecordIcon />
              }
              {
                  (status == "processing") &&  <Lottie
                      loop={true}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                      }}
                      width={60}
                      height={60}
                  />
              }
              {
                  status == "pass" && <CorrectIcon />
              }
              {
                  status== "fail" && <WrongIcon />
              }


            </div>
            <BeatRead21 iconType={(width > 900) ? "white" : "normal"}/>
          </>
      }
      {
          subType === "half" && <>
            <div className={styles.top}>
              <span>{data[current].text}</span>
            </div>

            <FloatingObject white={true} color={"rgba(255, 255, 255, 0.2)"} />
            <BeatRead21 iconType={"white"}/>
          </>
      }


    </div></div>
  }
}

function withMyHook(Component) {
  return function WrappedComponent(props) {
    useInstruction(props.stageDetails?.instruction && [props.stageDetails?.instruction]);
    return <Component {...props} />;
  }
}


export default withMyHook(Colorful)