import Lesson1 from "./lesson-1.json"
import Lesson2 from "./lesson-2.json"
import Lesson3 from "./lesson-3.json"
import Lesson4 from "./lesson-4.json"
import Lesson5 from "./lesson-5.json"
import Lesson6 from "./lesson-6.json"
import Lesson7 from "./lesson-7.json"
import Lesson8 from "./lesson-8.json"
import Lesson9 from "./lesson-9.json"
import Lesson10 from "./lesson-10.json"
import Lesson11 from "./lesson-11.json"
import Lesson12 from "./lesson-12.json"
import Lesson13 from "./lesson-13.json"
import Lesson14 from "./lesson-14.json"
import Lesson15 from "./lesson-15.json"
import Lesson16 from "./lesson-16.json"
import Lesson17 from "./lesson-17.json"
import Lesson18 from "./lesson-18.json"
import Lesson19 from "./lesson-19.json"
import Lesson20 from "./lesson-20.json"

export const g3 = {
    "audioPrefix": "g3ct",
    days: [
        ...Lesson1,
        ...Lesson2,
        ...Lesson3,
        ...Lesson4,
        ...Lesson5,
        ...Lesson6,
        ...Lesson7,
        ...Lesson8,
        ...Lesson9,
        ...Lesson10,
        ...Lesson11,
        ...Lesson12,
        ...Lesson13,
        ...Lesson14,
        ...Lesson15,
        ...Lesson16,
        ...Lesson17,
        ...Lesson18,
        ...Lesson19,
        ...Lesson20
    ].map(lesson => {
        if (lesson.type === "day3") {
            lesson.paragraph.breaks = []
        }
        return lesson
    })
}