export default class Timer {
    timing=0
    intervalHandle=0;
    constructor() {
        this.timing = 0
    }
    start(){
        this.timing = 0
        clearInterval(this.intervalHandle)
        this.intervalHandle = setInterval(() => {
            this.timing+=10
        }, 10);
    }
    end(){
        clearInterval(this.intervalHandle)
        return this.timing
    }
}