import styles from "./Intros.module.css"
import { useMemo } from "react";
import { TableMap } from "../../const/tables";
import ItemTable from "../../components/neutral/ItemTable";

export default function GridTable({ mapKey, gridArea }) {
    const tableList = useMemo(() => TableMap[mapKey], [mapKey])
    return <div className={styles.gridItem} style={{ gridTemplateAreas: gridArea }}>
        {
            tableList.map(tblMap => <ItemTable tableMap={tblMap} />)
        }
    </div>
}