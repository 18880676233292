export const Icons = {
  logout: () => {
    return (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.375 21.9375C11.375 22.153 11.2894 22.3597 11.137 22.512C10.9847 22.6644 10.778 22.75 10.5625 22.75H4.875C4.44402 22.75 4.0307 22.5788 3.72595 22.274C3.4212 21.9693 3.25 21.556 3.25 21.125V4.875C3.25 4.44402 3.4212 4.0307 3.72595 3.72595C4.0307 3.4212 4.44402 3.25 4.875 3.25H10.5625C10.778 3.25 10.9847 3.3356 11.137 3.48798C11.2894 3.64035 11.375 3.84701 11.375 4.0625C11.375 4.27799 11.2894 4.48465 11.137 4.63702C10.9847 4.7894 10.778 4.875 10.5625 4.875H4.875V21.125H10.5625C10.778 21.125 10.9847 21.2106 11.137 21.363C11.2894 21.5153 11.375 21.722 11.375 21.9375ZM22.5123 12.4252L18.4498 8.36266C18.3362 8.2489 18.1914 8.17141 18.0337 8.14001C17.876 8.1086 17.7125 8.1247 17.564 8.18624C17.4154 8.24779 17.2885 8.35203 17.1992 8.48576C17.11 8.6195 17.0624 8.77671 17.0625 8.9375V12.1875H10.5625C10.347 12.1875 10.1403 12.2731 9.98798 12.4255C9.8356 12.5778 9.75 12.7845 9.75 13C9.75 13.2155 9.8356 13.4222 9.98798 13.5745C10.1403 13.7269 10.347 13.8125 10.5625 13.8125H17.0625V17.0625C17.0624 17.2233 17.11 17.3805 17.1992 17.5142C17.2885 17.648 17.4154 17.7522 17.564 17.8138C17.7125 17.8753 17.876 17.8914 18.0337 17.86C18.1914 17.8286 18.3362 17.7511 18.4498 17.6373L22.5123 13.5748C22.5879 13.4994 22.6478 13.4098 22.6887 13.3111C22.7296 13.2125 22.7506 13.1068 22.7506 13C22.7506 12.8932 22.7296 12.7875 22.6887 12.6889C22.6478 12.5902 22.5879 12.5006 22.5123 12.4252Z"
          fill="#A21818"
        />
      </svg>
    );
  },
};
