export const availableCharacters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
export const characterMaps = {
    "A": ["ax", "Aa"],
    "B": ["bag", "Bb"],
    "C": ["cab", "Cc"],
    "D": ["dig", "Dd"],
    "E": ["egg", "Ee"],
    "F": ["fish", "Ff"],
    "G": ["go", "Gg"],
    "H": ["hi", "Hh"],
    "I": ["it", "Ii"],
    "J": ["jot", "Jj"],
    "K": ["kite", "Kk"],
    "L": ["lip", "Ll"],
    "M": ["map", "Mm"],
    "N": ["nap", "Nn"],
    "O": ["ox", "Oo"],
    "P": ["pig", "Pp"],
    "Q": ["queen", "Qu"],
    "R": ["rat", "Rr"],
    "S": ["set", "Ss"],
    "T": ["top", "Tt"],
    "U": ["up", "Uu"],
    "V": ["vast", "Vv"],
    "W": ["we", "Ww"],
    "X": ["x-ray", "Xx"],
    "Y": ["yell", "Yy"],
    "Z": ["zip", "Zz"],
}
export const characterDisplays = Object.values(characterMaps).map(ch=>ch[1])
