import { useNavigate } from "react-router-dom"
import Button from "./Button"
import ResultPage from "./ResultPage"

export const CheckpointResult = ({ resultId, nextLessonId, ...rest }) => {
    const router = useNavigate()
    const checkPointId = parseInt(rest?.lessonId)
    const nextLesson = checkPointId === 0 ? 6 : checkPointId === 1 ? 13 : 17
    const isDone = rest?.fluencyDone
    if (isDone) {
        return <ResultPage wasSuccessful={true} percentage={100} lessonId={21} />
    }
    return (
        <div className="bg-sky-700 h-full w-full">
            <div className="flex flex-col items-center justify-center h-full w-full gap-5">
                <div>
                    <div className="text-white text-4xl font-bold text-center">Checkpoint {checkPointId + 1}</div>
                    <h2 className="text-white text-center text-3xl">
                        You have completed the checkpoint
                    </h2>
                    <p className="text-white text-lg">
                        You can read at GRADE-LEVEL {rest?.grade.replace("G", "")}{" "} with a Reading speed of {rest?.wcpm} wcpm (word correct per minute).
                    </p>
                </div>
                <Button title="Go to next lesson" onClick={() => router(`/lessons/${nextLessonId || nextLesson}`)} />
            </div>
        </div>
    )
}