import styles from "./Review.module.css"
/**
 *
 * LessonId for sending with lessonId, and lessonTitles for title and testIds with Dictionary
 * List of buttons with microphones left to it that sends audio to practice API
 */
import { useCallback, useEffect, useMemo, useState } from "react";
import { CorrectIcon, RecordIcon, WrongIcon } from "../../resources/svgs";
import clsx from "clsx";
import { playSound } from "../../utils/playsound";
import useCountDown from "../../utils/countdown";
import Button from "../common/Button";
import { formatTime } from "../../utils/common";
import { Lessons, soundLessons } from "../../const/lessons";


export function getApplicableLessonID(lessonId) {
    const isSoundLesson = soundLessons.includes(lessonId) || soundLessons.includes(parseInt(lessonId))
    const id = isSoundLesson ? typeof lessonId === "number" ? lessonId - 1 : parseInt(lessonId) - 1 : lessonId
    return id
}


export default function Type1({ tests, lessonId, status, trialId, buttonsDisabled: defButtonsDisabled, onNext, controls }) {


    const dictionary = useMemo(() => {
        const lesson = Lessons[getApplicableLessonID(lessonId)];
        const lessonData = require("../../const/lessons.json")[lesson.data]
        return lessonData
    }, [lessonId]);
    const [pageState, setPageState] = useState("practice")
    const [trials, setTrials] = useState({});
    const [statuses, setStatuses] = useState({})
    const buttonsDisabled = useMemo(() => defButtonsDisabled || ((status !== "started") && (status != null)), [defButtonsDisabled, status])
    const { state, countDown, reset } = useCountDown(5 * 60, true)
    const { onClick, micEnabled } = useMemo(() => controls.registerPractice(getApplicableLessonID(lessonId), null, "word"), [])
    useEffect(() => {
        if (status) {
            setStatuses(statuses => {
                if (status === "pass") {
                    return ({ ...statuses, [statuses?.recording]: "success" })
                } else if (status === "fail") {
                    if (trials[statuses?.recording] >= 5) {
                        return ({ ...statuses, [statuses?.recording]: "error" })
                    } else {
                        setTrials(trails => ({ ...trails, [statuses?.recording]: (trials[statuses?.recording] || 0) + 1 }))
                    }
                }
                return statuses;
            })

        }

    }, [status])
    useEffect(() => {
        if (state === "complete") {
            onNext()
        }
    }, [state]);
    useEffect(() => {
        if (defButtonsDisabled) {
            reset()
        }
    }, [defButtonsDisabled])

    // const soundManager = useSoundManager()

    // //https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/10/answers/w4.mp3

    // //https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/10/answers/W4.mp3

    // //https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/10/answers/W5.mp3

    // //https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/10/answers/w5.mp3

    //https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/10/answers/w1.mp3


    const playTheSound = useCallback(async (testId) => {
        var clipId = dictionary.data[testId]?.clipId || dictionary.data[testId]?.ref?.toUpperCase?.();
        // var audioTemplate
        if (soundLessons.includes(lessonId)) {
            console.log({ clipId, testId })

            await window.soundManager.playURL(`https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/${getApplicableLessonID(lessonId)}/answers/${(clipId || testId).toLowerCase()}.mp3`)


        } else {
            await playSound(`L${lessonId}_${(clipId || testId)}`)
        }
    }, [lessonId, dictionary])


    return <div className={styles.reviewPageFormat}>
        <div className={styles.gridList}>
            {
                tests.map(testId => {
                    const dictItem = useMemo(() => dictionary.data[testId], [testId])
                    if (!dictItem) {
                        return (
                            <div>
                                <h1>Missing Data</h1>
                            </div>
                        )
                    }
                    return <div
                        key={testId}
                        className={clsx(styles.soundItem, (statuses[testId] || (pageState == "practice")) && (status === "recording") && styles.listened, styles[statuses[testId]], (status == "recording") && styles.recording)}>

                        <div className={styles.clickableSound} disabled={buttonsDisabled} onClick={async () => {
                            setStatuses(stts => ({ ...stts, [testId]: "listened" }))
                            await playTheSound(testId)
                        }
                        }>{dictItem?.imgs ? <>
                            {
                                dictItem.imgs.map(imgSrc => {
                                    return <img key={imgSrc} src={imgSrc} />
                                })
                            }
                        </> : <span dangerouslySetInnerHTML={{ __html: dictItem.text }}></span>}</div>
                        <div style={{ flex: 1 }}></div>
                        <div onClick={async () => {
                            setStatuses(stts => ({ ...stts, [testId]: "listened" }))
                            await playTheSound(testId)
                        }
                        } className={clsx(styles.soundButton, styles.playButton)}>
                            <svg viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.75 4.25C9.75 4.25 10.75 4.75 10.75 6C10.75 7.25 9.75 7.75 9.75 7.75M10.75 1.25C12.75 2.25 13.75 3.75 13.75 6C13.75 8.25 12.75 9.75 10.75 10.75M0.75 3.75V8.25H3.25L7.25 11.25V0.75L3.25 3.75H0.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                        {
                            ((statuses[testId]?.length > 0) && (statuses[testId] != "listened")) ? <div className={clsx(styles.soundStatus, styles[statuses[testId]])}>
                                {
                                    statuses[testId] == "success" && <CorrectIcon />
                                }
                                {
                                    statuses[testId] == "error" && <WrongIcon />
                                }
                            </div> : <>
                                {
                                    (!(status == "recording") || (statuses?.recording == testId)) && <div onClick={async () => {
                                        if (!buttonsDisabled) {
                                            setStatuses(x => ({ ...(x || {}), recording: testId }))
                                            await onClick(testId)
                                        }
                                    }
                                    } className={clsx(styles.soundButton, (buttonsDisabled && !(status == "recording")) ? styles.playButton : styles.recordButton)}>
                                        <RecordIcon />
                                    </div>
                                }
                            </>
                        }
                    </div>
                })
            }

        </div>

        <div className={styles.pageFooter}>
            {
                (pageState == "practice") && <span className={styles.countDown}>
                    {formatTime(countDown)}
                </span>
            }
            <Button isDisabled={buttonsDisabled || countDown >= 180} onClick={async () => {
                onNext();
            }} paddingV="full"
                paddingH="full"
                title={`Go!`}
                icon={"right"}
                background={"green"}
                click={"inside"} />
        </div>
    </div>
}