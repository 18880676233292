import { useMemo } from "react";
import { submitLessonPractice } from "../../resources/api";

export default function Explanation({ lessonId, trialId, onNext }) {
    const Explanation = useMemo(() => require(`../../lessons/${lessonId}/Explanation.js`).default, [lessonId])
    return <Explanation onNext={onNext} submitPart={async (blob, wrd) => {
        var formData = new FormData();
        if (blob) {
            formData.append('file', blob);
        }
        var result = (await submitLessonPractice(lessonId, trialId, wrd, formData));
        return result;
    }} />
}
