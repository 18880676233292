export const ModuleTitles = {
    0: {
        title: "Read It Out!"
    },
    1: {

        title: "Read It Out!"
    },
    2: {
        title: "Read It Out!"
    }
}
export const LessonTitles = {
    0: {
        title: "Fluency Reads",
        module: 0,
        content: "WordList",
        contentProps: {
            sentence: true,
            mapKey: 21
        }
    },
    1: {
        title: "Fluency Reads",
        module: 1,
        content: "WordList",
        contentProps: {
            sentence: true,
            mapKey: 21
        }
    },
    2: {
        title: "Fluency Reads",
        module: 2,
        content: "WordList",
        contentProps: {
            sentence: true,
            mapKey: 21
        }
    }
}
