export const theme = {
    ms: {
        background: {
            "ct-blue": "#1E88E6",
            "ct-green": "#0C8016",
            "ct-blue-2": "#0053f4"
        }
    }
}

export const getMsBg = (gradeLevel) => {
    if (gradeLevel <= 2) {
        return theme.ms.background["ct-blue"]
    }
    else if (gradeLevel >= 6) {
        return theme.ms.background["ct-green"]
    } else {
        return theme.ms.background["ct-blue-2"]
    }
}