import styles from "./RecordButton.module.css"
import { useContext, useRef, useState, useEffect } from "react";
import StatusContext from "../../context/StatusContext";
import clsx from "clsx";
import { CorrectIcon, RecordIcon, StopRecordIcon, WrongIcon } from "../../resources/svgs";
import * as processingAnimation from "../../lottie/processing.json";
import Lottie from "react-lottie";
import { useWindowSize } from "react-use";
import { playSound } from "../../utils/playsound";
export default function RecordButton({ micEnabled = false, contextuallyDisabled = false, status, onClick, className }) {
    const { buttonDisabled } = useContext(StatusContext)
    const proceduringRef = useRef(false)
    const { width } = useWindowSize()
    const [canStop, setCanStop] = useState(true)

    useEffect(() => {
        if (status === "recording" || status === "started") {
            setCanStop(false)
            const timer = setTimeout(() => setCanStop(true), 1000)
            return () => clearTimeout(timer)
        }
    }, [status])

    return <div
        className={clsx(styles.recordButton, (!buttonDisabled && micEnabled && !contextuallyDisabled) && styles.enabled, status && styles[status], className)}
        onClick={async () => {

            if (proceduringRef.current && (status === "recording" && !canStop)) {
                return;
            }

            proceduringRef.current = true
            try {
                if (((micEnabled) && !buttonDisabled && !contextuallyDisabled) || (status === "recording" && canStop)) {
                    if ((status === "recording")) {
                        await window.suspendRecordingAudio?.()
                        await playSound("click")
                    } else {
                        await onClick()
                    }

                }

            } catch (e) {
                alert("fail")
                console.error(e)
            }
            proceduringRef.current = false

        }}
    >
        {
            status == "processing" && <Lottie
                loop={true}
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: processingAnimation,
                }}
                width={(width > 700) ? 80 : 40}
                height={(width > 700) ? 80 : 40}
            />
        }

        {
            (status == "pass" || status == "correct") && <CorrectIcon />
        }
        {
            (status == "fail" || status == "incorrect") && <WrongIcon />
        }
        {
            status == "recording" && <StopRecordIcon />
        }

        {
            ((status != "processing") && (status != "pass") && (status != "correct") && (status != "incorrect") && (status != "fail") && (status != "recording")) && <RecordIcon />
        }

    </div>
}