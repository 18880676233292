import { CloseIcon } from "../../resources/svgs"
import { playSound } from "../../utils/playsound"
import Button from "./Button"
import styles from "./NoiseDetected.module.css"
import {useEffect} from "react";
import {pauseAll} from "../../utils/audios";
import wait from "../../utils/wait";

export default function NoiseDetectedModal({ removeComponent }) {
    useEffect(()=>{
        (async()=>{
            await pauseAll()
            await soundManager.playSound("instruction", "tooloud")
            await wait(1000)
            removeComponent()
        })()
    }, [])
    return <div className={styles.background}>
        <div className={styles.modal}>
            {/*<span className={styles.closeIcon} onClick={() => {*/}
            {/*    playSound('click')*/}
            {/*    removeComponent();*/}
            {/*}}>*/}
            {/*    <CloseIcon width={12} height={12} />*/}
            {/*</span>*/}
            <div className={styles.settingsContent}>
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 36 36"><path fill="#FFCB4C" d="M35 17c0 9.389-7.611 17-17 17S1 26.389 1 17S8.611 0 18 0s17 7.611 17 17"></path><ellipse cx="17.812" cy="23.125" fill="#65471B" rx="4.062" ry="1.93"></ellipse><path fill="#F19020" d="m25.35 25.981l-.033-.02a1.795 1.795 0 0 0-2.462.589l-.151.245a3.97 3.97 0 0 0-2.068-1.141l-1.399-.286l1.272-6.224a1.795 1.795 0 0 0-1.395-2.112l-.038-.008a1.795 1.795 0 0 0-2.112 1.395l-1.673 8.183l-.015.074l-.313 1.531l-.001.01l-1.128 5.533c-.22 1.078.27 2.25 1.895 2.25H19c2.804 0 3.635-1.01 4.198-3.093l2.741-4.463a1.798 1.798 0 0 0-.589-2.463z"></path><path fill="#65471B" d="M22.409 30.743a.337.337 0 0 1-.333-.403a1.028 1.028 0 0 0-.187-.839c-.59-.808-2.289-1.175-2.894-1.248a.339.339 0 0 1-.296-.377a.333.333 0 0 1 .377-.296c.101.011 2.474.31 3.36 1.521c.298.407.401.868.306 1.368a.34.34 0 0 1-.333.274zm-3.259 4.73c-.253 0-.53-.031-.833-.102c-.61-.144-1.04-.499-1.18-.975a1.152 1.152 0 0 1 .289-1.135c.388-.402 1.003-.534 1.695-.358a.34.34 0 0 1-.168.658c-.445-.114-.825-.05-1.038.171a.482.482 0 0 0-.127.473c.069.236.319.421.685.506c1.18.277 1.833-.186 2.174-.625c.508-.653.568-1.538.387-1.885c-.422-.812-2.049-.992-2.64-.993a.34.34 0 0 1 0-.678c.103 0 2.542.015 3.242 1.359c.305.585.218 1.751-.454 2.614c-.308.394-.943.97-2.032.97zm-4.928-22.251c0 1.391-.846 2.519-1.889 2.519c-1.043 0-1.889-1.127-1.889-2.519c0-1.391.845-2.519 1.889-2.519c1.043.001 1.889 1.128 1.889 2.519zm15.664-2.951a1.061 1.061 0 0 0 .849-1.7c-3.466-4.622-8.092-4.675-8.287-4.675a1.062 1.062 0 0 0-.004 2.125c.166.002 3.792.091 6.592 3.825c.208.278.527.425.85.425zm-8.108 2.951c0 1.391.846 2.519 1.889 2.519c1.043 0 1.889-1.127 1.889-2.519c0-1.391-.845-2.519-1.889-2.519c-1.044.001-1.889 1.128-1.889 2.519zM6.114 10.271a1.062 1.062 0 0 1-.849-1.7c3.466-4.622 8.092-4.675 8.287-4.675a1.062 1.062 0 0 1 .004 2.125c-.166.002-3.792.091-6.592 3.825a1.06 1.06 0 0 1-.85.425z"></path></svg>
            </div>
            {/*<Button title="Try Again" contentSize="small" onClick={() => {*/}
            {/*    playSound('click')*/}
            {/*    removeComponent()*/}
            {/*}} />*/}
        </div>
    </div>
}