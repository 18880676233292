
function registerUserActivityTracking(){
    // Initialize the isUserActive property on the window object
    window.isUserActive = false;

    let timeoutId;

    // Function to set the user as active
    function setUserActive() {
        // Check if the visibility API is supported or if the document is visible
        if (!document.visibilityState || document.visibilityState === 'visible') {
            window.isUserActive = true;
            // Clear any existing timeout to reset the flag
            clearTimeout(timeoutId);

            // Reset the flag after 20 seconds of inactivity
            timeoutId = setTimeout(() => {
                window.isUserActive = false;
                console.log('User is inactive');
            }, 20000); // 20 seconds
        }
    }

    // Handle visibility change
    function handleVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            clearTimeout(timeoutId);
            window.isUserActive = false;
            console.log('User is inactive');
        }
    }

    // List of events to listen for
    const events = ['click', 'touchstart', 'mousemove', 'keydown'];

    // Add event listeners for each event
    events.forEach(event => {
        document.addEventListener(event, setUserActive, { passive: true });
    });

    // Listen for visibility change events, if supported
    if ('visibilityState' in document) {
        document.addEventListener('visibilitychange', handleVisibilityChange);
    }
}

export default registerUserActivityTracking;