import styles from "./Intros.module.css"
import {Flipped, Flipper} from "react-flip-toolkit";
import clsx from "clsx";
import shuffle from "lodash.shuffle";
import {characterDisplays} from "../../const/chars";
import React, {useEffect, useState} from "react";
import wait from "../../utils/wait";
import {removeDuplicates} from "../../utils/common";


var animRandInt = 0;
export default function RandomizedShow({visibleItems, oneRow=false}){
    const [shufflingList, setLOA] = useState(()=>removeDuplicates(visibleItems));

    useEffect(() => {
        animRandInt = setInterval(async () => {
            await wait(0)
            setLOA(shuffle(shufflingList));
        }, 1000)
        return () => {
            clearInterval(animRandInt)
        }
    }, [])

    return     <Flipper flipKey={shufflingList.join("")}>
        <ul className={clsx(oneRow? styles.sentenceGrid : styles.alphabetGrid)}>
            {
                shufflingList.map(word => {
                    return   <Flipped key={word} flipId={word}>
                        <li  className={clsx(styles.shuffledItem, styles.shuffled)}>
                            {
                                word.includes("-") ? <span className={(styles.splittedWord)}>{word.split("-").map((itm, i)=><>{(i > 0) && <b>-</b>}<span style={{'--delay': i+1}}>{itm}</span></>)}</span>
                                    : <span>{word}</span>
                            }
                        </li>
                    </Flipped>
                })
            }
        </ul>
    </Flipper>
}