import styles from "./CTGuidedPractice.module.css"
import ProgressBar from "react-bootstrap/ProgressBar";
import { useContext, useEffect, useState } from "react";
import { useSoundManager } from "../../utils/sound";
import Button from "./Button";
import clsx from "clsx";
import wait from "../../utils/wait";
import { activateGPStudent } from "../../resources/api";
import UserContext from "../../context/UserContext";


const ctFlow = [
    {
        type: "narration",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp1.mp3"
    },
    {
        type: "suggest",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp2.mp3",
        sentence: "What is your name?",
        preSelected: [2, 3],
    },
    {
        type: "select",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp3.mp3",
        sentence: "According to the text, what is the name of John's dog?",
        importantWords: [9, 10],
        preSelected: [7, 8]
    },
    {
        type: "select",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp4.mp3",
        sentence: "Can you tell me which dog belongs to you?",
        importantWords: [4, 5, 6, 7, 8]
    },
    {
        type: "suggest",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp5.mp3",
        sentence: "Rocks range in different colors; which of the following colors are supported by evidence from the text?",
        preSelected: [0, 1, 2, 3, 4]
    },
    {
        type: "select",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp6.mp3",
        sentence: "Rocks range in different colors; which of the following colors are supported by evidence from the text?",
        preSelected: [0, 1, 2, 3, 4],
        importantWords: [9, 10, 11, 12, 13, 14, 15, 16]
    },
    {
        type: "suggest",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp7.mp3",
        sentence: "Space consists of many stars; which of the following types of stars are supported by evidence from the text?",
        preSelected: [0, 1, 2, 3, 4]
    },
    {
        type: "select",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp8.mp3",
        sentence: "Space consists of many stars; which of the following types of stars are supported by evidence from the text?",
        preSelected: [0, 1, 2, 3, 4],
        importantWords: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
    },
    {
        type: "select",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp9.mp3",
        sentence: "Who is the new Science teacher this year?",
        importantWords: [3, 4, 5, 6, 7]
    },
    {
        type: "select",
        sentence: "What happened at the beginning of the movie?",
        importantWords: [1, 2, 3, 4, 5, 6, 7]
    },
    {
        type: "select",
        sentence: "According to the text, which of the following is an example of a mammal?",
        importantWords: [10, 11, 12, 13]
    },
    {
        type: "select",
        sentence: "Based on the passage, how old was Queen Elizabeth when she became the Queen of England?",
        importantWords: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    },
    {
        type: "select",
        sentence: "Cookies come in all sorts of flavors; which of the following flavors are favorites of our main character?",
        importantWords: [0, 1, 2, 3, 4, 5, 6, 11, 12, 13, 14, 15, 16, 17]
    },
    {
        type: "narration",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp10.mp3",
        isEnd: true
    }

]

const ctFlowGrade2 = [
    {
        type: "narration",
        narration: "https://read21-assets.s3.amazonaws.com/ct/gp/2-3ct-gp1.mp3"
    },
    {
        type: "suggest",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp2.mp3",
        sentence: "What is your name?",
        preSelected: [2, 3],
    },
    {
        type: "select",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp3.mp3",
        sentence: "According to the text, what is the name of John's dog?",
        importantWords: [9, 10],
        preSelected: [7, 8]
    },
    {
        type: "suggest",
        narration: "https://read21-assets.s3.amazonaws.com/ct/gp/2-3ct-gp4.mp3",
        sentence: "What color are your shoes?",
        preSelected: [1],
        importantWords: [2, 3, 4]
    },
    {
        type: "select",
        sentence: "What color are your shoes?",
        preSelected: [1],
        importantWords: [2, 3, 4]
    },
    {
        type: "select",
        narration: "https://read21-assets.s3.amazonaws.com/ct/gp/2-3ct-gp5.mp3",
        sentence: "What is your favorite toy?",
        importantWords: [2, 3, 4]
    },
    {
        type: "select",
        sentence: "What happened to your hand?",
        importantWords: [1, 2, 3, 4]
    },
    {
        type: "select",
        sentence: "Based on the text, which is the biggest star?",
        importantWords: [7, 8]
    },
    {
        type: "select",
        sentence: "According to the text, which of the following is an example of a mammal?",
        importantWords: [10, 11, 12, 13]
    },
    {
        type: "select",
        sentence: "Based on the passage, how old is Duffy?",
        importantWords: [4, 5, 6, 7]
    },
    {
        type: "select",
        sentence: "Which insect uses webs?",
        importantWords: [1, 2, 3]
    },
    {
        type: "select",
        sentence: "Who won the race?",
        importantWords: [0, 1, 2, 3]
    },
    {
        type: "select",
        sentence: "Where did the dog hide the bone?",
        importantWords: [3, 4, 5, 6]
    },
    {
        type: "narration",
        narration: "https://read21-assets.s3.us-east-1.amazonaws.com/ct/gp/ct-gp10.mp3",
        isEnd: true
    }
]

export default function CTGuidedPractice({ onEnd }) {
    const [itemStep, setItemStep] = useState(0)
    const { user: { grade } } = useContext(UserContext)
    const currentItem = grade <= 3 ? ctFlowGrade2[itemStep] : ctFlow[itemStep]
    const soundManager = useSoundManager();
    const [isClueVisible, setIsClueVisible] = useState(false)
    const [currentNarrationPlayed, setCurrentNarrationPlayed] = useState(false)
    const [selectedWords, setSelectedWords] = useState([])
    const [answerStatus, setAnswerStatus] = useState(null)
    var questioning = currentItem.sentence != null;
    var narrationPlaying = (currentItem.narration != null) && !currentNarrationPlayed;
    const totalRequiredSelectable = (currentItem.importantWords?.length ?? 0) + (currentItem.preSelected?.length ?? 0);
    const totalSelectedWords = selectedWords.length + (currentItem.preSelected?.length ?? 0);
    const shouldSelect = (currentItem.type === "select") && (!(currentItem.narration) || currentNarrationPlayed);

    useEffect(() => {
        (async () => {
            setCurrentNarrationPlayed(false)
            setSelectedWords([])


            if (currentItem.type === "narration") {

            } else {
                setIsClueVisible(false)
            }
            if (currentItem.narration) {

                await soundManager.playURL(currentItem.narration)
                setCurrentNarrationPlayed(true)

                if (currentItem.type === "suggest") {
                    await wait(2000)
                    setItemStep((i) => i + 1)
                    return
                }
                if (currentItem.type === "narration") {
                    if (currentItem.isEnd) {
                        await activateGPStudent()
                        onEnd()
                    } else {
                        setItemStep((i) => i + 1)

                    }
                }


            }

        })().catch(() => alert("something's wrong"))


    }, [currentItem])

    return <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
            <div style={{ backgroundColor: '#0075FF', height: '110px', display: 'flex', alignItems: 'center', padding: '10px' }}>
                <div style={{ fontSize: '16px', fontWeight: "bold", color: '#ececec', fontFamily: "League Spartan", marginRight: '20px', marginLeft: '20px' }}>
                    Critical Thinking<br />
                    <b style={{ fontSize: 35, lineHeight: 1 }}>Guided Practice</b>
                </div>
                <div style={{ flex: '1', display: 'flex', flexDirection: "column", alignItems: 'center', gap: 5 }}>

                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/read21-wb.png" style={{ width: '192px', height: '125px', marginTop: '10px', marginLeft: '15px' }} />
                </div>
            </div>

        </div>

        <div className={styles.contentContainer}>
            <div className={clsx(styles.bookGuy, questioning && styles.questioning)}>
                <img src="/eyeitout.gif" className={styles.bookGuyImage} />
                <div className={styles.clueContainer}>
                    {
                        (currentItem.sentence) && (!isClueVisible) && <Button title={"?"} onClick={() => {
                            setIsClueVisible(true)
                        }} background={"green"} />
                    }
                    {
                        (currentItem.sentence) && isClueVisible && <div className={styles.clueItem}>{totalSelectedWords}/{totalRequiredSelectable}</div>
                    }
                </div>

            </div>




            {
                ((currentItem.type === "select") || (currentItem.type === "suggest")) && <div className={styles.narrationContainer}>
                    <div className={clsx(styles.sentence, narrationPlaying && styles.narrating, shouldSelect && styles.shallSelect)}>{currentItem.sentence?.split(" ").map((word, i) => {
                        var autoMarked = currentItem.preSelected?.includes(i);
                        var selected = selectedWords.includes(i);
                        var isIncorrect = answerStatus && (currentItem.importantWords.includes(i))
                        var isCorrect = answerStatus && (currentItem.importantWords.includes(i))
                        return <span onClick={() => {
                            if (!autoMarked && !narrationPlaying && shouldSelect) {
                                if (selectedWords.includes(i)) {
                                    setSelectedWords(selectedWords.filter(w => w != i))
                                } else {
                                    setSelectedWords([...selectedWords, i])
                                }
                            }

                        }} className={clsx(styles.wordItem, autoMarked && styles.autoMarked, selected && styles.selected, isIncorrect && styles.incorrect, isCorrect && styles.correct)}>{word}</span>
                    })}</div>
                </div>
            }



        </div>

        {
            ((currentItem.type === "select")) && <div className={styles.footerContainer}>
                <Button className={styles.continueButton} isDisabled={narrationPlaying} title={"Continue"} icon={"nextarrow"} direction={"right"} onClick={async () => {
                    if (currentItem.type === "select") {

                        //check if importantWords are selected & no other word is selected

                        if ((selectedWords.length === (currentItem.importantWords?.length ?? 0)) && (selectedWords.every(w => currentItem.importantWords.includes(w)))) {
                            setAnswerStatus("right")
                            await soundManager.playImmediate("correct")
                            await wait(2000)
                            setAnswerStatus(null)
                            setItemStep(i => i + 1)
                        } else {
                            setAnswerStatus("wrong")
                            await soundManager.playImmediate("wrong")
                            await wait(2000)
                            setAnswerStatus(null)
                        }

                    }
                }}></Button>
            </div>
        }
    </div>
}