import {getPlusTrain} from "../resources/api";

export  async function initiatePlusTrainServo(word){
    //1, make axios call to return details, if not exists, just pass,
    // 2, if exists, show the dialog, and wait for user to complete the tasks and then return
    const result = await getPlusTrain(word);
    if((result.status === 200) && (result.data.status == "success")){
        const awaiter = new Promise((resolve, reject)=>{
            window.plusTrainResolve = resolve;
            window.initiatePlusTrain(result.data.data);
        });
        return await awaiter;
    }else {
        return false;
    }
}