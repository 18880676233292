import _axios from "axios";
import wait from "../utils/wait";
import { getApplicableLessonID } from "../components/review/Type1";

export const axios = _axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
});
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (!config.url.toLowerCase().includes("amazonaws.com/")) {
    config.headers.Authorization = `Bearer ${token}`;
  }


  return config;
});
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
},

  function (error) {

    if (!error.request.responseURL.includes("auth")) {
      if (error.request.status == 401) {
        localStorage.removeItem("token");
        window.location = "/"
      }
    }

    const originalRequestConfig = error.config;

    if ((error.code === 'ECONNABORTED') || (error.code === 'ERR_NETWORK')) {

      return new Promise((resolve, reject) => {
        var type = (error.code === 'ECONNABORTED') ? "slow" : "none"
        window.switchToErrorDialog(type, async () => {
          const result = await axios.request(originalRequestConfig)
          resolve(result)

        })
      })
    } else {
      //alert(error)
      window.switchToErrorPage(error)
      //return Promise.reject(error);
    }






  });

export async function getG2CtLesson(lessonId) {
  return (await axios.get(`/public/criticalLesson/${lessonId}`)).data
}

export async function getMe() {
  var user = (await axios.get("/student/me"))
  if (user.data.accountLocked) {
    window.lockGame()
  } else {
    window.unLockGame()
  }
  return user;
}

export async function loginStudent(state, schoolId, uname, password) {
  return (await axios.post("/auth/student", {
    state,
    uname,
    schoolId,
    password
  }, { validateStatus(status) { return (status == 401) || (status < 300 && status > 199) } }))
}


export async function startLesson(lessonId, extras) {
  return (await axios.get(`/student/lesson/${lessonId}/start` + (extras ? "/extra?groups=" + (extras.join(",")) : "")))
}

export async function startCheckpoint(checkpoint, extras) {
  return await axios.get(`/student/checkpoint/${checkpoint}/start`)
}

var tries = {}
/*
async function correctNessTicker(isCorrect, isEnd=false){
  if(!isEnd && !isCorrect){
    if(window.incorrectCounter >= 1){
      if(window.playInstruction){
        await window.playInstruction?.()
      }

      window.incorrectCounter = 0;
    } else {
      window.incorrectCounter = (window.incorrectCounter||0)+ 1;
    }

  }else{
    window.incorrectCounter = 0;
  }

}*/
async function correctNessTicker(isCorrect, isEnd = false, hasReminder = false) {
  if (isNaN(window.incorrectCounter)) {
    window.incorrectCounter = 0;
  }
  if (!isEnd && !isCorrect) {
    //console.log("incorrect counter " + window.incorrectCounter);
    // if(window.incorrectCounter >= 1){
    window.incorrectCounter = window.incorrectCounter + 1;
    console.log("incorrect counter: " + window.incorrectCounter);
    if (window.incorrectCounter == 2) {
      if (window.playInstruction) {
        !hasReminder && await window.playInstruction?.()
      }
      //window.incorrectCounter = 0;
    }
    else {
      // window.incorrectCounter = (window.incorrectCounter||0)+ 1;
    }

  }
  else {
    //window.incorrectCounter = 0;
  }

}

var lessonSubmitClb;

/**
 *
 * @param lessonId
 * @param trialId
 * @param key
 * @param data
 * @param preRecognitionCb
 * @param {SoundManager} soundManager
 * @param postTickPlay
 * @param playProcessing
 * @returns {Promise<any>}
 */
export async function submitLessonPractice(lessonId, key, data, preRecognitionCb, { soundManager, postTickPlay, playProcessing = true }) {
  if (playProcessing) {
    soundManager.playSound("processing", "processing", true, 0.06)
  }

  try {
    var result = (await axios.post(`/student/lesson/${getApplicableLessonID(lessonId)}/practice?key=${key}`, data)).data;
    await soundManager.cleanChannel("processing")
    console.log({ practiceResult: result, lessonId: getApplicableLessonID(lessonId), data })
    preRecognitionCb && await preRecognitionCb(result);

    if (result.isCorrect) {
      await wait(300)
      await soundManager.playSound("status", "correct", false, 0.8)
      await wait(200)
      postTickPlay && await postTickPlay(result)
      await wait(1400)
    } else {
      await wait(300)
      await soundManager.playSound("status", "wrong", false, 0.8)
      await wait(200)
      postTickPlay && await postTickPlay(result)
      await wait(1400)
    }

    return result;
  } catch (e) {
    window.switchToErrorPage(e)
    soundManager.cleanChannel("processing")
  }

}


export async function watchLessonSubmission(clbk) {
  lessonSubmitClb = clbk;
}


/**
 *
 * @param lessonId
 * @param trialId
 * @param data {FormData}
 * @returns {Promise<any>}
 */
export async function initVoice(lessonId, questionKey) {
  try {
    var result = (await axios.get(`/student/lesson/${lessonId}/init/voice?questionKey=${questionKey}`)).data;
    return result;
  } catch (e) {
    window.switchToErrorPage(e)
    throw e;
  }

}

export async function initVoiceAssignable(textId, grade, trialId) {
  try {
    var result = (await axios.get(`/student/assignableCheckpoint/init/voice?textId=${textId}&grade=${grade}`)).data;
    return result;
  } catch (e) {
    window.switchToErrorPage(e)
    throw e;
  }

}

export async function initVoiceCheckpoint(lessonId, questionKey) {
  try {
    var result = (await axios.get(`/student/checkpoint/${lessonId}/init/voice?questionKey=${questionKey}`)).data;
    return result;
  } catch (e) {
    window.switchToErrorPage(e)
    throw e;
  }

}




/**
 *
 * @param lessonId
 * @param trialId
 * @param data
 * @param preRecognitionCb
 * @param {SoundManager} soundManager
 * @param playProcessing
 * @returns {Promise<any>}
 */
export async function submitLesson(lessonId, trialId, data, preRecognitionCb, { soundManager, postTickPlay, playProcessing = true, reminder, itemDetails }) {
  if (playProcessing) {
    soundManager.playSound("processing", "processing", true, 0.06)
  }

  try {
    var result = (await axios.post(`/student/lesson/${lessonId}/submit?trialId=${trialId}`, data)).data;
    await soundManager.cleanChannel("processing")
    preRecognitionCb && await preRecognitionCb(result);

    if (!itemDetails.dontPlayStatus) {
      if (result.isCorrect) {
        await wait(300)
        await soundManager.playSound("status", "correct", false, 0.8)
        await wait(200)
        postTickPlay && await postTickPlay(result)
        await wait(1400)
      } else {
        if (result.score === 0 && lessonId === "4") {
        } else {
          await wait(300)
          await soundManager.playSound("status", "wrong", false, 0.8)
        }
        await wait(200)
        postTickPlay && await postTickPlay(result)
        await wait(1400)
      }
    }
    lessonSubmitClb(result.updatedBucket, result.isCoinEarned, result.coinGroupId)
    if (result.isCoinEarned && !result.tryAgain && (lessonId != 0)) {
      await soundManager.playSound("status", "coin", false, 0.8)
      await wait(1000)
    }

    await correctNessTicker(result.isCorrect, result.end, !!reminder)

    return result;
  } catch (e) {
    window.switchToErrorPage(e)
    soundManager.cleanChannel("processing")
  }

}

export async function submitCheckpointLesson(lessonId, trialId, data, preRecognitionCb, { soundManager, postTickPlay, playProcessing = true }) {
  if (playProcessing) {
    soundManager.playSound("processing", "processing", true, 0.06)
  }

  try {
    var result = (await axios.post(`/student/checkpoint/${lessonId}/submit?trialId=${trialId}`, data)).data;
    await soundManager.cleanChannel("processing")
    preRecognitionCb && await preRecognitionCb(result);

    if (result.isCorrect) {
      await wait(300)
      await soundManager.playSound("status", "correct", false, 0.8)
      await wait(200)
      postTickPlay && await postTickPlay(result)
      await wait(1400)
    } else {
      await wait(300)
      await soundManager.playSound("status", "wrong", false, 0.8)
      await wait(200)
      postTickPlay && await postTickPlay(result)
      await wait(1400)
    }
    lessonSubmitClb(result.updatedBucket, result.isCoinEarned, result.coinGroupId)
    await correctNessTicker(result.isCorrect, result.end)
    return result;
  } catch (e) {
    window.switchToErrorPage(e)
    soundManager.cleanChannel("processing")
  }

}


export async function submitActivity(activityProgressId, data, preRecognitionCb, { soundManager, postTickPlay, playProcessing = true, dontPlayStatus = false }) {
  // if(playProcessing){
  //   soundManager.playSound("processing","processing", true,0.06 )
  // }

  try {
    var result = (await axios.post(`/student/critical/submit?activityProgressId=${activityProgressId}`, data)).data;
    await soundManager.cleanChannel("processing")
    // preRecognitionCb && await preRecognitionCb(result);
    if (!dontPlayStatus) {
      if (result.isCorrect) {
        await wait(300)
        await soundManager.playSound("status", "correct", false, 0.8)
        await wait(200)
        postTickPlay && await postTickPlay(result)
      } else {
        await wait(300)
        await soundManager.playSound("status", "wrong", false, 0.8)
        await wait(200)
        postTickPlay && await postTickPlay(result)
      }
    }


    return result;
  } catch (e) {
    window.switchToErrorPage(e)
    soundManager.cleanChannel("processing")
  }
}

export async function endLessonContinuate(lessonId, trialId) {
  return (await axios.post(`/student/lesson/${lessonId}/endOptional?trialId=${trialId}`, {}))
}

export async function continueLesson(lessonId, trialId, data) {
  return (await axios.post(`/student/lesson/${lessonId}/continue?trialId=${trialId}`, data))
}

export async function pingLesson(lessonId) {
  if (!window.isUserActive) {
    return null;
  }
  return (await axios.post(`/student/lesson/${lessonId}/ping`, {}))
}
export async function pingCriticalThinking(progressId) {
  if (!window.isUserActive) {
    return null;
  }
  return (await axios.post(`/student/critical/${progressId}/ping`, {}))
}

export async function pingAccount() {
  if (!window.isUserActive) {
    return null;
  }
  const token = localStorage.getItem("token");
  if (token) {
    return (await axios.post(`/student/ping`, {}))
  } else {
    return null
  }

}


export async function lessonResult(lessonId, trialId) {
  return (await axios.get(`/student/lesson/${lessonId}/result?trialId=${trialId}`))
}

export async function checkpointResult(lessonId, trialId) {
  return (await axios.get(`/student/checkpoint/${lessonId}/result?trialId=${trialId}`))
}

export async function reviewLessonWords(lessonId, trialId, type) {
  return (await axios.get(`/student/review?lessonId=${lessonId}&trialId=${trialId}${type ? `&type=${type}` : ""}`))
}

export async function getCoins(trialId) {
  var coins = (await axios.get(`/student/me/coins?trialId=${trialId}`)).data
  return coins;
}

export async function getReports() {
  var coins = (await axios.get(`/student/me/report`)).data
  return coins;
}

export async function activateStudent() {
  return (await axios.post(`/student/me/activate`, { date: Date.now() })).data;
}

export async function activateCTStudent() {
  return (await axios.post(`/student/me/ct/activate`, { date: Date.now() })).data;
}

export async function activateGPStudent() {
  return (await axios.post(`/student/me/gp/activate`, { date: Date.now() })).data;
}

export async function initCT() {
  return (await axios.get(`/student/critical/init`)).data;
}


export async function resultCT() {
  return (await axios.get(`/student/critical/end`)).data;
}

export async function getPlusTrain(word) {
  return (await axios.get(`/student/plusTrain/start?word=${word}`));
}



export async function uploadToS3(url, auBlob, filename, contentType) {
  return axios.put(url, new File([auBlob], filename), {
    headers: {
      'Content-Type': contentType
    }
  })
}


/**
 * 
 * @returns {Promise<{choices: string[], group: string, set: number, question: string}>}
 */
export async function getDyslexiaQuestions() {
  const res = await axios.get(`/student/dyslexia`)
  return res.data
}

/**
 * 
 * @returns {Promise<{status: "in progress"}>}
 */
export async function submitDyslexiaAnswer(group, set, question, answer, keyPressed) {
  const res = await axios.post(`/student/dyslexia`, {
    group,
    set,
    question,
    answer,
    keyPressed
  })
  if (!res.data?.status) {
    return {
      status: "end"
    }
  }
  return res.data
}