import { useEffect, useMemo } from "react"
import General from "./ct.json"
import { ms } from "./ms"
import { g2 } from "./g2"
import { g3 } from "./g3"
import { g4 } from "./g4"
import { g5 } from "./g5"


const CT = {
    middle: ms,
    general: General,
    g2,
    g3,
    g4,
    g5
}

export function getGradeLevel(grade) {
    const middle = [6, 7, 8]
    return middle.includes(grade) ? "middle" : grade <= 2 ? "g2" : grade === 3 ? "g3" : grade === 4 ? "g4" : grade === 5 ? "g5" : "general"
}

export const useGrade = (grade) => {
    const level = getGradeLevel(grade)
    return level
}

export const getGradeAudioPrefix = (grade) => {
    const level = getGradeLevel(grade)
    return CT[level].audioPrefix
}

export const useCTLesson = (index, grade) => useMemo(() => {
    const level = getGradeLevel(grade)
    // 
    if (level === "middle") {
        return CT.middle.days[index]
    } else if (level === "g2") {
        return CT.g2.days[index]
    } else if (level === "g3") {
        return CT.g3.days[index]
    } else if (level === "g4") {
        return CT.g4.days[index]
    } else if (level === "g5") {
        return CT.g5.days[index]
    } else {
        const lesson = CT.general.days[index]
        if (lesson.prefix) {
            lesson.prefix = [lesson.prefix]
            lesson.suffix = [lesson.suffix]
        }
        return lesson
    }
}, [index])


export function getTimer(grade) {
    const level = getGradeLevel(grade)
    return CT[level].timer
}