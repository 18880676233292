import { useState, useEffect, useContext } from 'react';
import { useSoundManager } from "../../utils/sound";
import ctData from "../../const/ct.json"
import styles from "./Day1.module.css"
import clsx from "clsx";
import useStickyState from "../../utils/stickyState";
import Button from "../common/Button";
import useCountDown from "../../utils/countdown";
import { formatTime } from "../../utils/common";
import FlipNumbers from "react-flip-numbers";
import { submitActivity } from "../../resources/api";
import { CorrectIcon, WrongIcon } from "../../resources/svgs";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SoundOrSelectItem } from "./Components";
import ButtonHolder from "../common/ButtonHolder";
import { useInstructionCT } from "../../utils/instruction";
import wait from "../../utils/wait";
import Ct from "../../const/ms-ct.json"
import UserContext from '../../context/UserContext';
import { useCTLesson, getGradeLevel } from "../../const/ct"
import { getMsBg } from '../../utils/style';

function SoundItem({ cState, item, largeItem }) {
    return <div className={clsx(styles.largeItem, styles.baseItem, cState("hideM") && styles.hideMItem)} onClick={() => {
        clickHandler("suffix", suffix)

    }}>
        <span className={styles.word}> {item.item}</span>
        <span className={styles.meaning}> {item.meaning}</span>
    </div>
}
function Day1({ itemNumber, pauseAndResume, countDownDetails: { start, reset, stop }, repeatCount, status, questionIndex, submitResult, qProps: [isQuestioning, setIsQuestioning, isQuestioningHiding] }) {
    // const dayDetails = ctData.days[itemNumber - 1];
    const { user: { grade } } = useContext(UserContext)
    const dayDetails = useCTLesson(itemNumber - 1, grade)
    const gradeLevel = grade
    const { prefix, rootWords, suffix, questions, narrations } = dayDetails;
    const [isNextVisible, setIsNextVisible, isNextHiding] = useStickyState(false, 1000)

    const [step, setStep] = useState('start');
    const soundManager = useSoundManager()
    const setInstruction = useInstructionCT()



    // Play the introduction narrations when the component mounts
    useEffect(() => {
        (async () => {
            setInstruction(null)
            await wait(1)
            if (step === 'start') {
                setStep("part")
                setIsNextVisible(true)

            } else if (step === 'part') {

                setInstruction([narrations["discoverSounds"]])
                start(60, () => {
                    setStep("root")
                })
                soundManager.playURL(narrations["discoverSounds"]).then(() => {


                });

            } else if (step === 'root') {
                start(60 * 2, () => {
                    setStep("question")
                    setIsNextVisible(false)
                    setIsQuestioning(true)
                })
                setInstruction([narrations["rootTouch"]])
                soundManager.playURL(narrations["rootTouch"]).then(() => {

                });
            } else if (step === 'question') {
                start(30)
                setIsNextVisible(false)
                soundManager.cleanChannel("default")
                soundManager.cleanAllChannel()
                setInstruction([questions[questionIndex].narration])
                soundManager.playURL(questions[questionIndex].narration, "question").then(() => {
                    setStep("question")
                });

            }
        })().catch(alert)

        return () => {
            setInstruction(null)
        }

    }, [questionIndex, step, repeatCount]);

    const playSound = (sound) => {
        soundManager.playURL(sound, "default");
    };


    const cState = (type) => {
        if (type === 'prefix') {
            return ((step === "part") || ((step === "question") && (status == null)))
        } else if (type === 'suffix') {
            return ((step === "part") || ((step === "question") && (status == null)))
        } else if (type === 'root') {
            return ((step === "root") || ((step === "question") && (status == null)))
        }

        if (type === "hideM") {
            return (step == "question")
        }
    }
    return (
        <div className={styles.mainContainer}>
            <div className={styles.soundsContainer}>
                <div className={clsx(styles.itemContainer, cState("prefix") && styles.enabled)}>
                    <span className={clsx(styles.itemTitle, styles.prefixItem)}>
                        Prefix
                    </span>
                    {
                        prefix.map(p => (
                            <SoundOrSelectItem key={p.item} isQuestioning={isQuestioning} processAnswer={async () => {
                                return await submitResult(
                                    {
                                        answer: p.item
                                    }
                                )
                            }} soundManager={soundManager} enabled={cState("prefix")} type={(step == "question") ? "question" : "play"} item={p} hideMeaning={
                                cState("hideM")
                            }
                                size={"large"}
                            />
                        ))
                    }

                </div>
                <div className={clsx(styles.itemContainer, cState("root") && styles.enabled)}>
                    <span className={clsx(styles.itemTitle, styles.rootWord)}>
                        Root Words
                    </span>
                    <div className={styles.itemList}>
                        {rootWords.map((word, index) => (
                            <SoundOrSelectItem isQuestioning={isQuestioning} processAnswer={async () => {

                                return await submitResult(
                                    {
                                        answer: word.item
                                    }
                                )
                            }} soundManager={soundManager} enabled={cState("root")} type={(step == "question") ? "question" : "play"} item={word} hideMeaning={
                                cState("hideM")
                            }
                                size={"small"}
                            />
                        ))}
                    </div>
                </div>

                <div className={clsx(styles.itemContainer, cState("suffix") && styles.enabled)} >
                    <span className={clsx(styles.itemTitle, styles.suffixItem)}>
                        Suffix
                    </span>
                    {
                        suffix.map(s => (
                            <SoundOrSelectItem key={s.item} isQuestioning={isQuestioning} processAnswer={async () => {
                                return await submitResult(
                                    {
                                        answer: s.item
                                    }
                                )
                            }} soundManager={soundManager} enabled={cState("suffix")} type={(step == "question") ? "question" : "play"} item={s} hideMeaning={
                                cState("hideM")
                            }
                                size={"large"}
                            />
                        ))
                    }
                </div>
            </div>

            <div className={styles.footerContainer}>
                {
                    isQuestioning && <div className={clsx(styles.questionBox, isQuestioningHiding && styles.hiding)} style={{
                        backgroundColor: getMsBg(gradeLevel),
                        fontSize: "24px",
                    }}>
                        <h1>{questions[questionIndex].item}</h1>
                    </div>
                }
                {
                    isNextVisible && <ButtonHolder
                        icon={"nextarrow"} title={"Continue"}
                        background={
                            gradeLevel >= 6 ? "green" : "blue"
                        }
                        reference={
                            step + questionIndex || ""
                        }
                        onClick={() => {

                            if (step === "part") {
                                setStep("root")
                            } else if (step === "root") {
                                setStep("question")
                                setIsNextVisible(false)
                                setIsQuestioning(true)
                            }
                        }}
                    >Next</ButtonHolder>
                }

            </div>
        </div>
    );
}

export default Day1;
