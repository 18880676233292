import styles from "../styles/Lesson.module.css"
import {useLayoutEffect, useState} from "react";
import Review from "../components/review/Review";
import getAudios, {loadAudios} from "../utils/audios";

export default function CoinTest({}){
    const [coins, setCoins] = useState([])

    return <div className={styles.container}>
        <Review lessonId={3} trialId={"6af10fba-20c8-433c-819d-06c88719130f"} onNext={()=>alert("done!")}/>
    </div>
}