import moment from "moment"
import {useContext, useEffect, useMemo, useState} from "react"
import UserContext from "../../context/UserContext"
import { CloseIcon, CalendarIcon } from "../../resources/svgs"
import Button from "./Button"
import styles from "./Setttings.module.css";
import { LessonTitles } from '../../const/lessonTitles'
import { Range } from 'react-range';
import {volumeAll} from "../../utils/audios";
import {playSound} from "../../utils/playsound";

export default function SettingsModal({removeComponent}) {
    const [microPhones, setMicroPhones] = useState([])
    const [defaultMicroPhone, setDefaultMicrophone] = useState(()=>localStorage.getItem("defaultMicrophone")||"default");
    const [volume, setVolume] = useState(()=>Number.parseFloat(localStorage.getItem("appVolume") || "100"))
    useEffect(()=>{
        navigator.mediaDevices.enumerateDevices()
            .then(function(devices) {
                setMicroPhones(devices.filter(dvc=>dvc.kind==="audioinput").map(dvc=>({name:dvc.label, id: dvc.deviceId})))
            })
    }, []);

    useEffect(()=>{
        localStorage.setItem("appVolume", volume || "100");
        volumeAll(volume/100)
        localStorage.setItem("defaultMicrophone", defaultMicroPhone || "default");
    }, [defaultMicroPhone, volume])



    return <div className={styles.background}>
        <div className={styles.modal}>
            <span className={styles.closeIcon} onClick={()=>{
                playSound('click')
                    removeComponent();
                }}>
                <CloseIcon width={25} height={25} />
            </span>
            <div className={styles.settingsContent}>
                <label>Default Microphone</label>
                <select onChange={(v)=>{setDefaultMicrophone(v.target.value)}} >
                    {
                        microPhones.map(mcrp=>{
                            return <option selected={defaultMicroPhone == mcrp.id} value={mcrp.id}>{mcrp.name}</option>
                        })
                    }
                </select>
                <label>Sound volume</label>
                <Range
                    step={0.1}
                    min={0}
                    max={100}
                    values={[volume]}
                    onChange={(values) => setVolume(values[0])}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,

                            }}

                            className={styles.soundTrack}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,

                            }}
                            className={styles.volumeBall}
                        />
                    )}
                />
            </div>

            </div>
    </div>
}