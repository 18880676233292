import { useCallback, useEffect, useMemo } from "react"
import { playSound } from "../../utils/playsound"
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use';
import { availableCharacters } from "../../const/chars";
import styles from "./ResultPage.module.css";

import Lottie from "react-lottie";
import * as animationData from "../../lottie/star.json";
import { HomeIcon } from "../../resources/svgs";
import { useNavigate } from "react-router-dom";
import { pauseAll } from "../../utils/audios";
import { loadFull } from "tsparticles";
import Particles from "react-particles";
import clsx from "clsx";
import Button from "../common/Button";


const characters = availableCharacters;


/**
 *
 * {
 *     show score here
 *
 * }
 *
 */

export default function NextDay({ wasSuccessful, setIsProgramDone, percentage, restart, nextAvailable, openNext, tryAgain }) {
    const { width, height } = useWindowSize()
    const navigate = useNavigate()
    const result = useMemo(() => ({ hasPassed: wasSuccessful }), [wasSuccessful])
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             await pauseAll()
    //             if (result.hasPassed) {
    //                 //Fail piano
    //
    //                 await playSound("success")
    //                 if (lessonId == "21") {
    //                     await playSound(`endings/l${lessonId}`)
    //                 } else {
    //                     await playSound(`endings/l${lessonId}pass`)
    //                 }
    //             } else {
    //                 //Stars audio
    //                 await playSound("failure")
    //
    //                 if(nextAvailable){
    //                     await playSound(`endings/avfail`)
    //                 }else{
    //                     if (lessonId == "21") {
    //                         if(presentation == "goBack"){
    //
    //                         }else if(presentation == "locked"){
    //                             window.lockGame()
    //                         }
    //                     } else {
    //                         await playSound(`endings/l${lessonId}fail`)
    //                     }
    //                 }
    //
    //
    //             }
    //         } catch (error) {
    //             alert(error)
    //         }
    //     })()
    //
    // }, [result])
    const particlesInit = useCallback(async (engine) => {
        console.dir(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    return <div className={styles.mainContainer}>

        {
            result.hasPassed && <Confetti
                width={width}
                height={height}
            />
        }
        {
            result.hasPassed && <div className={clsx(styles.successPage, styles.colored)} >
                <>
                    <>
                        {
                            typeof percentage == "number" && <h1 style={{ fontSize: 120, marginBottom: 10 }}>{Math.floor(percentage * 100)}%</h1>
                        }
                        <h1>Wonderful!</h1>
                    </>
                    <div className={styles.buttonsContainer}>

                        {
                            nextAvailable ? <Button direction="left" onClick={async () => {
                                await pauseAll()
                                openNext()
                            }} icon={"nextarrow"} border={"white"} background={"green"} title={`Next Activity`} />
                                : <Button
                                    title={"Continue"}
                                    direction="left"
                                    background="blue"
                                    border="white"
                                    icon={"nextarrow"}
                                    onClick={async () => {
                                        await pauseAll()
                                        setIsProgramDone(true)
                                    }}
                                />
                        }

                    </div>

                </>

            </div>
        }
        {
            !result.hasPassed && <div className={nextAvailable ? styles.failButMovePage : styles.failPage}>
                <>
                    {
                        typeof percentage == "number" && <h1 style={{ fontSize: 120, marginBottom: 10 }}>{Math.floor(percentage * 100)}%</h1>
                    }
                    <h1>Oops!</h1>
                </>
                <img src={"https://read21-assets.s3.amazonaws.com/assets/images/graphics/sadface.png"} />
                {
                    nextAvailable ? <>
                        <div className={styles.buttonsContainer}>

                            <Button direction="left" onClick={async () => {
                                await pauseAll()

                                openNext()


                            }} icon={"nextarrow"} border={"white"} background={"green"} title={`Next Activity`} />

                        </div>
                    </> : <>
                        <div className={styles.buttonsContainer}>

                            <Button direction="left" onClick={async () => {
                                await pauseAll()

                                openNext()


                            }} icon={"repeat"} border={"white"} background={"blue"} title={`Try again`} />

                        </div>

                    </>
                }


            </div>
        }
    </div>
}