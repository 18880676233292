import { MediaRecorder, register } from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";
import hark from "hark";
import { playSound } from "./playsound";
import Timer from "./timer";
import wait, { holdFlow } from "./wait";

import { Lessons } from "../const/lessons";
import { Checkpoints } from "../const/checkpoints"
import lessonDatas from "../const/lessons.json";
import { generateRange } from "./common";
import audioResampler from "./resampler";
var loadedAudios = []

export function initiateVolumeMeter() {
    var endFunction = null;
    let volumeCallback = null;
    let volumeInterval = null;

    (async () => {
        const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: true,
                sampleRate: 16000
            }
        });
        const audioContext = new AudioContext({
            sampleRate: 16000
        });
        const audioSource = audioContext.createMediaStreamSource(audioStream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 512;
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.4;
        audioSource.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        volumeCallback = () => {
            analyser.getByteFrequencyData(volumes);
            let volumeSum = 0;
            for (const volume of volumes)
                volumeSum += volume;
            const averageVolume = (volumeSum / volumes.length) - 100;
            // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
            window.averageVolumeMeasured = averageVolume
            console.log(averageVolume)
        };
        volumeInterval = setInterval(volumeCallback, 3000);
        endFunction = () => {
            clearInterval(volumeInterval);
            audioStream.getTracks().forEach(trk => trk.stop());
            audioContext.suspend()
        }
    })()
    return () => {
        endFunction?.()
    }
}



export async function mockAudio() {
    return await (await fetch("/dev/words/ax.wav")).blob()
}

function bufferToWav(buffer) {
    var numOfChan = buffer.numberOfChannels;
    var length = buffer.length * numOfChan * 2 + 44;
    var wav = new Uint8Array(length);
    var view = new DataView(wav.buffer);

    // RIFF chunk descriptor
    writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, length - 8, true);
    writeUTFBytes(view, 8, 'WAVE');
    // FMT sub-chunk
    writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    // stereo (2 channels)
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, buffer.sampleRate, true);
    view.setUint32(28, numOfChan * buffer.sampleRate * 2, true); // numChannels * 2 (16bit) * sampleRate
    view.setUint16(32, numOfChan * 2, true); // numChannels * 2 (16bit)
    view.setUint16(34, 16, true); // 16bit
    // data sub-chunk
    writeUTFBytes(view, 36, 'data');
    view.setUint32(40, length - 44, true);

    // write the PCM samples
    var index = 44;
    for (var i = 0; i < buffer.length; i++) {
        for (var chan = 0; chan < numOfChan; chan++) {
            var value = buffer.getChannelData(chan)[i] * 0x7FFF;
            view.setInt16(index, value, true);
            index += 2;
        }
    }

    return new Blob([view], { type: 'audio/wav' });
}

// helper function to correctly write utf8
function writeUTFBytes(view, offset, string) {
    for (var i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
}
/**
 *
 * @param {Blob} auBlb
 * @param target
 * @returns {Promise<unknown>}
 */
export function resampleAudio(auBlb, target) {
    return new Promise(async (resolve, reject) => {
        const resampler = require('audio-resampler');
        try {

            resampler(new File([auBlb], "audio.wav"), target, (result) => {
                try {
                    const audioBuffer = result.getAudioBuffer()

                    const output = bufferToWav(audioBuffer)
                    resolve(output)

                } catch (e) {
                    reject(e)
                }
            })
        } catch (e) {
            reject(e)
        }


    })
}


export async function waitForVoice(wrLength, amount = 1, type = "norepeat", waitTime = 6000, {
    listening,
    empty,
    finished,
    cleanOnFresh = false,
    speechTimeOut = 2000,
    suspendCallback,
    audioStreamBegan,
    closeStream
} = {}) {
    await holdFlow()
    await window.soundManager.playImmediate("recording")
    listening && listening()
    var currentSampleRate = 0;
    var noiseCheckInterval;

    async function checkForNoise(analyser, rec) {
        return new Promise((resolve) => {
            noiseCheckInterval = setInterval(() => {
                const array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                let values = 0;
                const length = array.length;
                for (let i = 0; i < length; i++) {
                    values += (array[i]);
                }
                const average = values / length;

                // Set a threshold to indicate "noise" - this will need to be adjusted
                console.log("average", average)
                if (average > 40) {
                    clearInterval(noiseCheckInterval);  // Clear interval to stop checking for noise
                    resolve(true);  // Resolve the promise if noise is detected
                }
            }, 500);  // Check for noise every 500ms (adjust as necessary)
        });
    }

    async function handleNoiseDetection(analyser, rec) {
        const noiseDetected = await checkForNoise(analyser, rec);
        if (noiseDetected) {
            console.log("Noise Detected")
            if (rec.state === "recording") {
                rec.pause();  // Pause the recording
            }

            console.log("can't pause")
            clearInterval(noiseCheckInterval);  // Ensure interval is cleared

            // Display graphics to users during the pause
            await window.setLoudnessDetected();
            // ... your code to display graphics ...
            if (rec.state === "paused") {
                rec.resume();
            }



        }
    }

    function getAudioClip() {
        return new Promise(async (resolve, reject) => {
            try {
                try {
                    await register(await connect());
                } catch (error) {

                }
                const deviceId = localStorage.getItem("defaultMicrophone") || (await navigator.mediaDevices.enumerateDevices()).filter(dvc => dvc.kind === "audioinput")[0].deviceId
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: deviceId,
                        // sampleRate: 16000,
                        echoCancellation: false,
                        noiseSuppression: false,
                        autoGainControl: false,
                        channelCount: 1
                    },

                });

                const [hawkStream, recognitionStream, azureStream] = [new MediaStream(stream), new MediaStream(stream), new MediaStream(stream)]

                currentSampleRate = recognitionStream.getTracks()[0].getConstraints().sampleRate;


                var rec = new MediaRecorder(recognitionStream, { mimeType: "audio/wav", audioBitsPerSecond: 320000 });
                var minDecibel = -68
                console.log("Reference Point: ", minDecibel)
                var speechEvents = hark(hawkStream, { threshold: minDecibel, interval: (amount > 5) ? 60 : 100 });
                const timer = new Timer();
                var spEvent = 0;
                var endTout = 0;
                var spchBig = new Timer()

                speechEvents.on('speaking', function () {
                    spEvent++;
                    console.log(`Speaking ${spEvent}`)
                    timer.start();
                    if (cleanOnFresh) {
                        rec.start();
                        audioChunks = []
                    }
                    clearTimeout(endTout)
                    spchBig.start()

                });
                let speechs = 0;
                var fulFilled = false;
                speechEvents.on('stopped_speaking', function () {

                    clearTimeout(endTout)

                    endTout = setTimeout(() => {
                        var milseconds = timer.end();
                        console.log(`Spoken for ${milseconds}ms on #${spEvent}`);
                        if ((milseconds >= (wrLength * 25)) || (milseconds >= (250))) {
                            speechs += 1;
                            if (type === "check") {
                                if (speechs >= amount) {
                                    resolve(true)
                                }
                            } else if ((type === "norepeat") || (type === "repeat")) {
                                if (speechs >= amount) {
                                    fulFilled = true;
                                    rec.stop();
                                }
                            } else if ((type === "once") || (type === "waiting")) {
                                fulFilled = true;
                                rec.stop();
                            }

                        }
                    }, speechTimeOut);


                });
                speechEvents.resume();
                if (!cleanOnFresh) {
                    rec.start();

                }
                var audioChunks = [];
                var closed = false;
                rec.ondataavailable = (e) => {

                    if (closed) {
                        return
                    }
                    if ((type === "norepeat") || (type === "once") || (type === "waiting") || (type === "repeat") || (type === "within")) {
                        audioChunks.push(e.data);
                        let blob = new Blob(audioChunks);

                        clearTimeout(endTout)
                        if (rec.state === "inactive") {


                            if (fulFilled && (blob.size > 0)) {
                                //blob = resampleAudio(blob, 16000)
                                resolve(blob);
                            } else {
                                resolve(null);
                            }

                        } else if (type === "onend") {
                            if (blob && (blob.size > 0)) {
                                //blob = resampleAudio(blob, 16000)
                                resolve(blob);
                            } else {
                                resolve(null);
                            }
                        }
                    }

                };
                rec.onerror = function (error) {
                    reject(error);
                };
                rec.onstop = function () {
                    speechEvents.stop();
                    speechEvents.suspend();
                }
                if (suspendCallback) {
                    suspendCallback(() => {
                        fulFilled = true;
                        rec.stop()
                    })

                }
                if (audioStreamBegan) {
                    audioStreamBegan(azureStream, () => {
                        fulFilled = true;
                        rec.stop()
                    })
                }


                window.suspendRecordingAudio = async (fromPause = false) => {
                    if (closeStream) {
                        await closeStream()
                    }
                    if (fromPause) {
                        resolve("done")
                        rec.stop()
                        closed = true;
                    } else {
                        fulFilled = true;
                        rec.stop()
                    }


                }

                // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                /**
                 * @type {AnalyserNode}
                 */
                // var analyser = audioContext.createAnalyser();
                /**
                 * @type {MediaStreamAudioSourceNode}
                 */
                // var microphone = audioContext.createMediaStreamSource(stream);
                // microphone.connect(analyser);

                // var isNoiseHandlable = true;
                // async function loopHandleNoise() {
                //     if (isNoiseHandlable) {
                //         await handleNoiseDetection(analyser, rec);
                //         microphone.disconnect();
                //         analyser.disconnect();
                //         analyser = audioContext.createAnalyser();
                //         microphone = audioContext.createMediaStreamSource(stream);
                //         microphone.connect(analyser);
                //         await wait(10000);
                //         loopHandleNoise();
                //     }
                // }
                //
                // loopHandleNoise();

                if ((type === "norepeat") || (type === "waiting") || (type === "repeat") || (type === "onend") || (type === "within")) {
                    await wait(waitTime + speechTimeOut)
                    // isNoiseHandlable = false;
                    var milseconds = timer.end();
                    if ((milseconds >= (wrLength * 25)) || (milseconds >= (250))) {
                        fulFilled = true;
                    }
                    clearTimeout(endTout)
                    rec.stop();

                }
            } catch (error) {
                alert(error);
                reject(error);
            }
        });
    }

    var nRes = await getAudioClip();
    window.suspendRecordingAudio = null;
    if (nRes || (type === "norepeat") || (type === "once") || (type === "within")) {
        if (typeof nRes == "string") {
            return nRes;
        } else {
            if (currentSampleRate != 16000) {
                nRes = await audioResampler(nRes, 16000);
            }
            return nRes;
        }


    } else {
        empty && await empty();
        await wait(500)
        return await waitForVoice(wrLength, amount, type, waitTime, {
            listening,
            empty,
            finished,
            cleanOnFresh,
            speechTimeOut
        })
    }
}

export async function pauseAllVideos() {
    var elements = document.querySelectorAll("video");
    var pausedId = []
    for (var i = 0, len = elements.length; i < len; i++) {
        if (!elements[i].paused) {
            elements[i].pause()
            pausedId.push(elements[i].id)
        }

    }
    return pausedId;
}

export async function pauseAll() {
    return window.soundManager.cleanAllChannel()
}

export async function volumeAll(newVolume) {
    var elements = document.querySelectorAll("audio");
    var pausedId = []
    for (var i = 0, len = elements.length; i < len; i++) {
        if (!elements[i].paused) {
            elements[i].volume = newVolume
            pausedId.push(elements[i].id)
        }

    }
    return pausedId;
}


export async function playRingingBells() {
    await playSound(`chimes/bell.mp3`);
    await playSound(`chimes/bell.mp3`);
    await playSound(`chimes/bell.mp3`);
    await playSound(`chimes/bell.mp3`);
}


export function getAudioList(lessonId, checkpoint = false) {

    const lesson = lessonId ? Lessons[lessonId] : {}
    const defaultLesson = checkpoint ? Checkpoints["default"] : Lessons["default"]
    var audioSetsLists = [(lesson.audioSets || {}), defaultLesson.audioSets]
    var audioRefs = {}
    audioSetsLists.map(audioSets => {
        Object.keys(audioSets).forEach(audioKey => {
            var value = audioSets[audioKey]
            if (typeof value == "string") {
                audioRefs[audioKey] = value.replace("{{key}}", audioKey.toLowerCase()).replace("{{lessonId}}", lessonId)
            } else if (audioKey === "_") {
                if (Array.isArray(value)) {
                    value.forEach(v => {
                        const { data, audio, prefix = "" } = v;
                        data.map(dataId => {
                            audioRefs[`${prefix}${dataId}`] = audio.replace("{{key}}", dataId.toLowerCase()).replace("{{lessonId}}", lessonId)
                        })
                    })

                } else {
                    const { data, audio, prefix = "" } = value;
                    data.map(dataId => {
                        audioRefs[`${prefix}${dataId}`] = audio.replace("{{key}}", dataId.toLowerCase()).replace("{{lessonId}}", lessonId)
                    })
                }


            } else {
                const { data, audio } = value;
                data.map(dataId => {
                    dataId = dataId.toString()
                    audioRefs[`${audioKey}_${dataId}`] = audio.replace("{{key}}", dataId.toLowerCase()).replace("{{lessonId}}", lessonId)
                })
            }
        })
    })

    if (typeof lesson.data == "string") {
        const { audio, data } = lessonDatas[lesson.data]

        if (typeof audio == "string") {

            Object.keys(data).map(oky => {
                if (data[oky].sequential) {
                    for (let i of generateRange(data[oky].sequential[0], data[oky].sequential[1])) {
                        audioRefs[`L${lessonId}_${oky}/${i}`] = audio.replace("{{key}}", `${oky.toLowerCase()}/${i}`).replace("{{lessonId}}", lessonId)
                    }

                } else {
                    audioRefs[`L${lessonId}_` + oky] = audio.replace("{{key}}", oky.toLowerCase()).replace("{{lessonId}}", lessonId)
                }

            })
        } else if (audio == null) {

        } else {
            throw Error("Not implemented!")
        }
    }
    return audioRefs
}