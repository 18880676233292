import styles from "../styles/Lesson.module.css"
import styles2 from "../components/maze/Map.module.css";
import {useRef, useEffect} from "react";
import { useNavigate, useParams } from 'react-router-dom'

export default function GameZone({}){
    const navigate = useNavigate();
    const { gamezoneId } = useParams()
    const gameId = Math.floor(Math.random() * 4) + 1;
    const gameMap = {
        1: {name: "Astry",
            src: "https://wwwtyro.github.io/Astray/"},
        2: {name: "Clumsy Bird",
            src: "https://ellisonleao.github.io/clumsy-bird/"},
        3: {name: "Pac-Man",
            src: "https://pacman.platzh1rsch.ch/"},
        4: {name: "Tetris",
            src: "https://web.itu.edu.tr/~msilgu/tetris/tetris.html"}
            
    }

    const inputReference = useRef(null);

    useEffect(() => {
        inputReference.current.focus();
    }, []);

    return <div className={styles.container}>
        <div className={styles2.screenContainer}>
        <div style={{flexDirection: 'row', width: '100%', height:'100%', padding: '2%'}}>
            <div style={{textAlign: 'center', marginBottom: '2%', visibility: 'hidden'}}>
                <span style={{color: '#4cdafe', fontSize: 40, fontWeight: 'bold'}}>
                {gameMap[gameId].name}
                </span>
            </div>
            <div style={{width: '90%', height: '90%', margin: '0 auto'}}>
                <iframe ref={inputReference} src={gameMap[gameId].src} title={gameMap[gameId].name} width="100%" height="100%" style={{WebkitTransformStyle:(0.5), border: 'none'}} autoFocus >; 
                </iframe>
            </div>
            </div>
            <div className={styles.coinsAndButtons} style={{background: 'transparent', boxShadow: 'none', marginLeft: '2%'}}>
                <button className={styles.homeButton} onClick = {() => navigate('/')}>
                    <svg viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.4758 5.18845L4.87006 26.7767H10.4759V45.7956H19.4627V32.4818C19.4627 31.1913 20.508 30.146 21.7985 30.146H31.1415C32.432 30.146 33.4773 31.1913 33.4773 32.4818V45.7956H42.4641V26.7767H48.0758L26.4758 5.18845ZM51.848 29.8833C51.4977 30.2336 51.0831 30.5139 50.6334 30.6949C51.0831 30.5081 51.4977 30.2336 51.848 29.8833ZM0.0525545 27.857C0.344524 29.6263 1.88613 30.9811 3.73722 30.9811H3.78977C1.927 30.9811 0.350364 29.638 0.0525545 27.857ZM0.00583931 27.092C0.0116787 26.911 0.0350363 26.7358 0.0642333 26.5606C0.0291969 26.73 0.0116787 26.911 0.00583931 27.092Z" fill="#ECECEC" fill-opacity="0.15"/>
                        <path d="M52.105 24.8906C52.105 24.8847 52.0991 24.8847 52.0991 24.8789L51.994 24.7563C51.9882 24.7504 51.9823 24.7388 51.9707 24.7329C51.9298 24.6862 51.8831 24.6395 51.8422 24.5928L27.7663 0.534452C27.4217 0.192127 26.9557 0 26.47 0C25.9843 0 25.5183 0.192127 25.1737 0.534452L1.09781 24.5986C0.662867 25.0313 0.341872 25.5649 0.163509 26.1519L0.128473 26.2745L0.110954 26.3388L0.0934361 26.4088C0.0817573 26.4497 0.075918 26.4906 0.0700786 26.5315C0.0700786 26.5373 0.0700785 26.549 0.0642391 26.5548C0.0292027 26.73 0.0116845 26.9052 0.0058451 27.0862V27.2789C0.0058451 27.3081 0.00584524 27.3373 0.0116846 27.3665C0.0116846 27.3957 0.0116845 27.419 0.0175239 27.4482C0.0175239 27.4774 0.0233633 27.5066 0.0233633 27.5358C0.0233633 27.5709 0.0292027 27.6059 0.0350421 27.6409C0.0350421 27.6585 0.0408816 27.676 0.0408816 27.6935L0.0583998 27.8395V27.8453C0.356209 29.6263 1.93869 30.9694 3.79561 30.9694H6.27736V50H46.6685V30.9811H49.2028C49.705 30.9811 50.1896 30.8818 50.6334 30.6949C51.0772 30.5081 51.4918 30.2336 51.848 29.8833C52.1959 29.5365 52.4716 29.1241 52.659 28.67C52.8465 28.216 52.942 27.7292 52.94 27.238C52.94 26.3796 52.648 25.5563 52.105 24.8906ZM29.7401 45.7956H23.1999V33.8833H29.7401V45.7956ZM42.4641 26.7767V45.7956H33.4773V32.4818C33.4773 31.1913 32.432 30.146 31.1415 30.146H21.7985C20.508 30.146 19.4627 31.1913 19.4627 32.4818V45.7956H10.4759V26.7767H4.87006L26.4758 5.18845L48.0758 26.7767H42.4641Z" fill="white"/>
                    </svg>
                </button>
            </div>
            </div>
        </div>
            
}