import {useEffect, useRef, useState} from "react";
import {playSound} from "./playsound";
import wait from "./wait";
import {makeRandomString} from "./common";

export default function useCountDown(_amount, withOutAction=false){
    const [amount, setAmount] = useState(_amount)
    const timerRef = useRef(null)
    const [countDown, setCountDown] = useState(null);
    const [countDownId, setCountDownId] = useState(null)
    const [countDownUnit, setCountDownUnit] = useState(()=>makeRandomString())
    const [state, setState] = useState("stale")
    const [timerMeta, setTimerMeta] = useState(null)
    useEffect(()=>{
        (async ()=> {
            if((countDown!=null) && (state == "running")){
                if((countDown <= 1) ){
                    setState("complete")
                    window.suspendRecordingAudio && await window.suspendRecordingAudio?.()

                    await wait(1000)
                    setState("stale")
                    setCountDownId(null)
                }
            }

        })().catch(alert)


    }, [countDown, state, countDownId]);
    function start(){
        var currentId = makeRandomString(10);
        timerRef.current = null
        async function loopingFunction(){
            await wait(1000)



            if(timerRef.current === currentId) {
            setCountDown((cntd)=> {
                if((cntd != null) && (cntd > 0)){
                    return cntd - 1
                }
                return cntd
            });

            await loopingFunction()
            }
        }
        timerRef.current = currentId;

        loopingFunction();

        setState("running")
        setCountDownUnit(makeRandomString())
    }
    return {
        stop(){
            timerRef.current= null
            setState("stale")
            setCountDown(null)
            setCountDownId(null)
        },
        start(amt, timerMeta=null){
            if(amt){
                setAmount(amt)
                setCountDown(amt);

            }else{
                setCountDown(amount);
            }
            setTimerMeta([timerMeta]);
            start()
            setCountDown(amt||amount);
            window.clearCurrentCountDown = ()=> {
                timerRef.current = null
                setState("stale")
                setCountDown(null)
                setCountDownId(null)
            }
        },
        reset(withoutStart= false, amt, cId){

            timerRef.current= null
            if(amt){
                setAmount(amt)
                setCountDown(amt);

            }else{
                setCountDown(amount);
            }


            if(!withoutStart){
                start()
            }else{
                setState("stale")
            }
            setCountDownId(cId)

        },
        state,
        countDown,
        countDownId,
        countDownAmount: amount,
        countDownUnit,
        timerMeta: timerMeta?.[0]
    }
}