import styles from "./Modal.module.css"
import { CloseIcon } from "../../resources/svgs";
import { cn } from "../../utils/utils";

export default function Modal({ children, close, className }) {
    return <div className={cn(styles.modalContainer, className)}>
        <div className={styles.modal}>
            {children}
            <a className={styles.closeButton} onClick={() => {
                close();
            }
            }><CloseIcon /></a>

        </div>

    </div>
}