import styles from "./ItemTable.module.css";
import clsx from "clsx";
import { useEffect } from "react";

/**
 *
 * @param tableMap

 * @returns {JSX.Element}
 * @constructor
 */
export default function ItemTable({ tableMap, namingMap = {}, selected, small, smallest, colored, unboxed }) {
    useEffect(() => {
        if (selected) {
            document.getElementById(`${selected}_table`).scrollIntoView({
                behavior: 'smooth', block: 'nearest', inline: 'center'
            })
        }

    }, [selected])
    return <div id={"tableItem"} className={clsx(styles.tableBox, unboxed && styles.unboxed)}>
        {
            tableMap.map(rows => {
                return <div className={styles.row}>

                    {
                        typeof rows == "string" ?
                            <div id={`${rows}_table`} className={clsx(styles.col, colored && styles.colored, rows === selected && styles.selected, small && styles.small, smallest && styles.smallest)}>{namingMap?.[rows] ? namingMap[rows].text : rows}</div>
                            :
                            rows.map(item => {
                                return <div id={`${item}_table`} className={clsx(styles.col, colored && styles.colored, item === selected && styles.selected, small && styles.small, smallest && styles.smallest)}>{namingMap?.[item] ? namingMap[item].text : item}</div>
                            })
                    }
                </div>
            })
        }

    </div>
}