const bypassDev = {
    stageIntroduction: false,
    displayItems: false,
    pretest: false
}

/**
 * @param {keyof typeof bypassDev} section 
 * @returns {boolean}
 */
export const skip = (section) => {
    if (process.env.NODE_ENV !== "development") {
        return false
    }
    return bypassDev[section] ?? false
}