import {useEffect, useState} from "react";



export default function useStickyState(initialValue,timeOut){
    const [value, _setValue] = useState(initialValue);
    const [switching, setSwitching] = useState(false);

    const setValue = (value)=>{
        if(!value ){
            setSwitching(true)
            setTimeout(()=>{
                _setValue(value)
                setSwitching(false)
            }, timeOut)
        } else {
            _setValue(value)
        }
    }

    return [value, setValue, switching]
}