import { useContext, useEffect, useState } from "react";
import { playSound } from "./playsound";
import InstructionContext from "../context/InstructionContext";
import { pauseAll } from "./audios";
import { delayAll } from "./wait";
import styles from "../styles/Lesson.module.css";
import clsx from "clsx";

export function InstructionMarkDisabling() {
    const { isInstructing, isPlaying } = useContext(InstructionContext)


    return <>
        <button disabled={(isPlaying)} onClick={async () => {
            await window.playInstruction?.()
        }} className={clsx(styles.infoButton, ((isPlaying) || !isInstructing) && styles.isButtonDisabled)} style={{ width: 80, height: 80 }}>
            <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.7874 15.1522V14.8045C4.79422 13.6113 4.8999 12.6602 5.10445 11.9511C5.31581 11.242 5.62263 10.6693 6.0249 10.2329C6.42718 9.79654 6.91127 9.40109 7.47717 9.04654C7.8999 8.77381 8.27831 8.49086 8.6124 8.19768C8.94649 7.9045 9.2124 7.58063 9.41013 7.22609C9.60786 6.86472 9.70672 6.46245 9.70672 6.01927C9.70672 5.54881 9.59422 5.13631 9.36922 4.78177C9.14422 4.42722 8.84081 4.1545 8.45899 3.96359C8.08399 3.77268 7.66808 3.67722 7.21127 3.67722C6.76808 3.67722 6.34877 3.77609 5.95331 3.97381C5.55786 4.16472 5.23399 4.45109 4.98172 4.83291C4.72945 5.20791 4.59308 5.67495 4.57263 6.23404H0.399902C0.433993 4.87041 0.761266 3.74541 1.38172 2.85904C2.00218 1.96586 2.82377 1.30109 3.84649 0.864724C4.86922 0.421542 5.99763 0.199951 7.23172 0.199951C8.58854 0.199951 9.78854 0.424951 10.8317 0.874951C11.8749 1.31813 12.6931 1.96245 13.2863 2.80791C13.8794 3.65336 14.176 4.67268 14.176 5.86586C14.176 6.66359 14.0431 7.37268 13.7772 7.99313C13.5181 8.60677 13.1533 9.15222 12.6829 9.6295C12.2124 10.1 11.6567 10.5261 11.0158 10.9079C10.4772 11.2284 10.034 11.5625 9.68627 11.9102C9.34536 12.2579 9.08967 12.6602 8.91922 13.117C8.75558 13.5738 8.67036 14.1363 8.66354 14.8045V15.1522H4.7874ZM6.8124 21.6977C6.13058 21.6977 5.54763 21.459 5.06354 20.9818C4.58627 20.4977 4.35104 19.9181 4.35786 19.2431C4.35104 18.575 4.58627 18.0022 5.06354 17.525C5.54763 17.0477 6.13058 16.809 6.8124 16.809C7.46013 16.809 8.02945 17.0477 8.52036 17.525C9.01127 18.0022 9.26013 18.575 9.26695 19.2431C9.26013 19.6931 9.14081 20.1056 8.90899 20.4806C8.68399 20.8488 8.3874 21.1454 8.01922 21.3704C7.65104 21.5886 7.24877 21.6977 6.8124 21.6977Z" fill="white" />
            </svg>

        </button>

    </>
}

export function InstructionMark({ itemDetails }) {
    const { isInstructing, isPlaying } = useContext(InstructionContext)


    return <>
        {
            isInstructing ? <button disabled={(isPlaying)} onClick={async () => {
                await window.playInstruction?.(itemDetails)
            }} className={clsx(styles.infoButton, (isPlaying) && styles.isButtonDisabled)}>
                <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.7874 15.1522V14.8045C4.79422 13.6113 4.8999 12.6602 5.10445 11.9511C5.31581 11.242 5.62263 10.6693 6.0249 10.2329C6.42718 9.79654 6.91127 9.40109 7.47717 9.04654C7.8999 8.77381 8.27831 8.49086 8.6124 8.19768C8.94649 7.9045 9.2124 7.58063 9.41013 7.22609C9.60786 6.86472 9.70672 6.46245 9.70672 6.01927C9.70672 5.54881 9.59422 5.13631 9.36922 4.78177C9.14422 4.42722 8.84081 4.1545 8.45899 3.96359C8.08399 3.77268 7.66808 3.67722 7.21127 3.67722C6.76808 3.67722 6.34877 3.77609 5.95331 3.97381C5.55786 4.16472 5.23399 4.45109 4.98172 4.83291C4.72945 5.20791 4.59308 5.67495 4.57263 6.23404H0.399902C0.433993 4.87041 0.761266 3.74541 1.38172 2.85904C2.00218 1.96586 2.82377 1.30109 3.84649 0.864724C4.86922 0.421542 5.99763 0.199951 7.23172 0.199951C8.58854 0.199951 9.78854 0.424951 10.8317 0.874951C11.8749 1.31813 12.6931 1.96245 13.2863 2.80791C13.8794 3.65336 14.176 4.67268 14.176 5.86586C14.176 6.66359 14.0431 7.37268 13.7772 7.99313C13.5181 8.60677 13.1533 9.15222 12.6829 9.6295C12.2124 10.1 11.6567 10.5261 11.0158 10.9079C10.4772 11.2284 10.034 11.5625 9.68627 11.9102C9.34536 12.2579 9.08967 12.6602 8.91922 13.117C8.75558 13.5738 8.67036 14.1363 8.66354 14.8045V15.1522H4.7874ZM6.8124 21.6977C6.13058 21.6977 5.54763 21.459 5.06354 20.9818C4.58627 20.4977 4.35104 19.9181 4.35786 19.2431C4.35104 18.575 4.58627 18.0022 5.06354 17.525C5.54763 17.0477 6.13058 16.809 6.8124 16.809C7.46013 16.809 8.02945 17.0477 8.52036 17.525C9.01127 18.0022 9.26013 18.575 9.26695 19.2431C9.26013 19.6931 9.14081 20.1056 8.90899 20.4806C8.68399 20.8488 8.3874 21.1454 8.01922 21.3704C7.65104 21.5886 7.24877 21.6977 6.8124 21.6977Z" fill="white" />
                </svg>

            </button> : <div />
        }
    </>
}


export function useInstructionCT() {
    /**
     *
     * @type {SoundManager}
     */
    var soundManager = window.soundManager;
    const { setIsInstructing, setIsPlaying } = useContext(InstructionContext)

    return (audios) => {
        if (audios) {
            if (audios) {
                setIsInstructing(true)
                window.playInstruction = async () => {

                    var retrivePausedFunction = await delayAll(true)
                    setIsPlaying(true)
                    for (let i = 0; i < audios.length; i++) {
                        var audio = audios[i]
                        await soundManager.playURL(audio, "direction")
                    }
                    setIsPlaying(false)
                    await retrivePausedFunction()
                }
            }
        } else {
            window.playInstruction = null;
            setIsInstructing(false)
        }
    }
}


export function useInstruction(audios) {
    /**
     *
     * @type {SoundManager}
     */
    var soundManager = window.soundManager;
    const { setIsInstructing, setIsPlaying } = useContext(InstructionContext)

    useEffect(() => {
        if (audios) {
            setIsInstructing(true)
            window.playInstruction = async (itemDetails) => {
                var retrivePausedFunction = await delayAll(true)
                setIsPlaying(true)
                if (itemDetails?.direction) {
                    await soundManager.playSound("direction", itemDetails.direction)
                } else {
                    for (let i = 0; i < audios.length; i++) {
                        var audio = audios[i]
                        await soundManager.playSound("direction", audio)
                    }
                }
                setIsPlaying(false)
                await retrivePausedFunction()
            }

        }
        return () => {
            window.playInstruction = null;
            setIsInstructing(false)
        }
    }, [])
}
