import { useContext, useEffect, useMemo, useState } from "react";
import SubmitContext from "../../context/SubmitContext";
import RecordButton from "../neutral/RecordButton";

export default function ButtonHolder(props) {
    const { submitButton, setSubmitButton, reference } = useContext(SubmitContext)
    const onClickMemoed = useMemo(() => {
        return props.onClick
    }, [props.onClick])
    useEffect(() => {
        if (props?.reference !== reference) {
            setSubmitButton(props, props.reference)
        }
        // return () => {
        //     setSubmitButton(null)
        // }
    }, [props.reference])
    return <>
        {
            (props?.bType == "record") && <>
                <RecordButton {...submitButton} className={props.className || ""} />
            </>
        }
    </>
}