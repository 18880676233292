import styles from "./Intros.module.css"

export default function VowelSounds({}){
    return <div className={styles.exampleBox}>
        <h1>Examples</h1>
        <div className={styles.exampleList}>
            <img src={`https://read21-assets.s3.amazonaws.com/assets/images/lesson/3/a1.svg`}/>
            <img src={`https://read21-assets.s3.amazonaws.com/assets/images/lesson/3/a2.svg`}/>
            <img src={`https://read21-assets.s3.amazonaws.com/assets/images/lesson/3/a3.svg`}/>
        </div>
    </div>
}