import { useEffect, useState } from 'react'
import Button from '../components/common/Button'
import FloatingObject from '../components/common/FloatingObjects'
import styles from '../styles/Lesson.module.css'
import { cn } from '../utils/utils'
import { useSoundManager } from '../utils/sound'
import { getDyslexiaQuestions, submitDyslexiaAnswer } from '../resources/api'
import wait from '../utils/wait'
import useCountDown from '../utils/countdown'



export const DyslexiaScreen = () => {
    const [page, setPage] = useState("introduction")
    const soundManager = useSoundManager()
    return (
        <main className={cn(styles.container, " !overflow-hidden")}>
            <head>
                <title>
                    Dyslexia Screen
                </title>
            </head>
            <div className={styles.lessonInformation}>
                <span><b>Dyslexia Screening</b>&nbsp; &nbsp;</span>
            </div>
            {
                page === "introduction" && <Introduction setNextPage={() => {
                    setPage("lesson")
                }} />
            }
            {
                page === "lesson" && <Lesson setNextPage={async () => {
                    setPage("end")
                }} />
            }
            {
                page === "end" && <End />
            }
        </main>
    )
}

const Introduction = ({ setNextPage }) => {
    const soundManager = useSoundManager()
    useEffect(() => {
        soundManager.playURL("https://read21-assets.s3.amazonaws.com/dyslexia/Start%20Narration.mp3").then(() => {
            setNextPage()
        })
    }, [])
    return (
        <div className='w-full'>
            <FloatingObject color={"rgba(78,84,200, 0.2)"} />
            <div className='flex items-center justify-center h-full'>
                <h2 className='text-6xl font-bold bg-gradient-to-tr from-purple-500 to-purple-600 via-orange-500 bg-clip-text text-transparent'>
                    Dyslexia Screening
                </h2>
            </div>
        </div>
    )
}



const Lesson = ({ setNextPage }) => {
    const [data, setData] = useState()
    const { start, reset, stop, countDown, state } = useCountDown(5)

    useEffect(() => {
        if (state === "complete") {
            handleSubmit("")
        }
    }, [state])

    const [keyPressed, setKeyPressed] = useState(0)
    const soundManager = useSoundManager()
    const [isSubmitting, setIsSubmitting] = useState(false)

    async function getWords() {
        const res = await getDyslexiaQuestions()
        setData(res)
        await soundManager.playURL(`https://read21-assets.s3.amazonaws.com/dyslexia/${res.set}/${res.group}/${res.question}.mp3`)
        setKeyPressed(0)
        start(5)
    }

    async function handleSubmit(choice) {
        stop()
        setIsSubmitting(true)
        const res = await submitDyslexiaAnswer(data.group, data.set, data.question, choice, keyPressed)
        if (res.status === "in progress") {
            setIsSubmitting(false)
            setData(undefined)
            await wait(2000)
            await getWords()
        } else {
            setNextPage()
        }
    }

    useEffect(() => {
        getWords()
    }, [])

    return (
        <div className='w-full h-full flex flex-col justify-between'>
            <FloatingObject color={"rgba(78,84,200, 0.2)"} />
            <div className='flex h-full gap-4 justify-center flex-wrap  scale-75'>
                {
                    data?.choices?.length ? data.choices.map(word => {
                        return (
                            <div className={
                                cn("bg-blue-600 flex-grow w-5/12 text-whit rounded-md border-2 border-blue-700 shadow-md shadow-black/40 p-4 flex items-center justify-center cursor-pointer", isSubmitting && "animate-pulse")
                            } onClick={() => {
                                if (!isSubmitting) {
                                    handleSubmit(word)
                                }
                            }} key={word}>
                                <p className='text-9xl text-white'>
                                    {word}
                                </p>
                            </div>
                        )
                    }) : [0, 1, 2, 3].map(i => {
                        return (
                            <div className={
                                cn("bg-blue-600 flex-grow w-5/12 text-whit rounded-md border-2 border-blue-700 shadow-md shadow-black/40 p-4 flex items-center justify-center cursor-pointer")
                            } key={i}>
                                <p className='text-9xl text-white'>

                                </p>
                            </div>
                        )
                    })
                }
            </div>
            <div className='flex items-center justify-between'>
                <p className='text-5xl px-4'>
                    {
                        isSubmitting ? "..." : countDown
                    }
                </p>
                <div className=' text-4xl flex px-10 py-4 justify-end bg-yellow-500 rounded-md text-white font-bold w-min ml-auto mb-3 mr-4 shadow-md shadow-black/40 cursor-pointer' onClick={async () => {
                    await soundManager.playURL(`https://read21-assets.s3.amazonaws.com/dyslexia/${data.set}/${data.group}/${data.question}.mp3`)
                    setKeyPressed(keyPressed + 1)
                }}>
                    <div>
                        ?
                    </div>
                </div>
            </div>
        </div>
    )
}

const End = () => {
    const soundManager = useSoundManager()
    const [isDisabled, setIsDisabled] = useState(true)
    useEffect(() => {
        soundManager.playURL("https://read21-assets.s3.amazonaws.com/dyslexia/End%20Narration.mp3").then(() => {
            setIsDisabled(false)
        })
    }, [])
    return (
        <div className='w-full'>
            <FloatingObject color={"rgba(78,84,200, 0.2)"} />
            <div className='flex items-center flex-col gap-4 justify-center h-full'>
                <h2 className='text-6xl font-bold bg-gradient-to-tr from-purple-500 to-purple-600 via-orange-500 bg-clip-text text-transparent'>
                    Thank you for participating!
                </h2>
                <Button isDisabled={isDisabled} onClick={() => {
                    window.location.reload()
                }} contentSize={"large"} icon={'nextarrow'} title={"Continue"} background={"green"} />
            </div>
        </div>
    )
}