import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useSoundManager } from "../../utils/sound";
import ctData from "../../const/ct.json"
import styles from "./Day2.module.css"
import clsx from "clsx";
import useStickyState from "../../utils/stickyState";
import Button from "../common/Button";
import { cn } from "../../utils/utils"

import { initVoice, submitActivity, uploadToS3 } from "../../resources/api";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import ReadItOut from "../stages/ReadItOut";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import wait from "../../utils/wait";
import { waitForVoice } from "../../utils/audios";

import 'bootstrap/dist/css/bootstrap.min.css';
import { AppearingDisappearingWord, Draggables } from "./Components";
import CTRIO from "../ct/CTRIO";
import ButtonHolder from "../common/ButtonHolder";
import { useInstructionCT } from "../../utils/instruction";
import { getGradeAudioPrefix, getGradeLevel, useCTLesson } from '../../const/ct';
import UserContext from '../../context/UserContext';
import { getMsBg } from '../../utils/style';





function MatchingTableRow({ meaning, answerCorrections, itemResult, item1, item2, rowIndex, onComplete, gradeLevel }) {

    var mergedWord = (item1 || "") + (item2 || "")
    var isResulted = (typeof itemResult != "undefined")
    var isCorrect = itemResult
    var mergedWordDetails = ((item1?.length > 0) && (item2?.length > 0)) && (
        answerCorrections[mergedWord] ? answerCorrections[mergedWord] : mergedWord
    )
    const handleDragOver = (e) => {
        const droppableArea = e.currentTarget;
        const rect = droppableArea.getBoundingClientRect();
        console.log("dragged over")
        if (e.clientY - rect.top < 100) {
            // Scroll up
            droppableArea.scrollTop = Math.max(droppableArea.scrollTop - 20, 0);
        }
    }

    return <tr>
        <td>
            <Droppable droppableId={`row${rowIndex}Item1`}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={styles.tableItemContainer}
                    >
                        <AppearingDisappearingWord content={item1} />
                        <div className={clsx(styles.draggingOverlay, snapshot.isDraggingOver && styles.visible)} />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </td>
        <td>
            <Droppable droppableId={`row${rowIndex}Item2`} >
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={styles.tableItemContainer}

                    >
                        <AppearingDisappearingWord content={item2} />

                        <div className={clsx(styles.draggingOverlay, snapshot.isDraggingOver && styles.visible)} />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </td>
        <td className={clsx(styles.tableCombinedWords, isResulted && (isCorrect ? gradeLevel === "middle" ? styles.correctMs : styles.correct : styles.incorrect))} style={{
            backgroundColor: (isCorrect && gradeLevel >= 6) ? "gold" : undefined
        }}>
            <span className={clsx(styles.tableCombinedWordsInner, isResulted && (isCorrect ? styles.correct : styles.incorrect))} style={{
                color: isCorrect ? (gradeLevel >= 6 ? "green" : "white") : "black"
            }}>
                <AppearingDisappearingWord content={mergedWordDetails} />
                {
                    (typeof itemResult === "undefined") ? <></> : (itemResult ? <>
                        {" ✓"}
                    </> : <> {" ✗"}</>)
                }
            </span>

        </td>
        <td style={{
            backgroundColor: getMsBg(gradeLevel)
        }}>
            <div className={cn(styles.itemMeaningInner, "text-sm lg:text-lg")}>
                {meaning}
            </div>
        </td>
    </tr>
}
function MatchingTables({ draggableOptions: draggableItems, onChange, itemResults, answerCorrections, questions, onComplete, gradeLevel }) {

    const [itemValues, setItemValues] = useState([
        ...questions.map(question => {
            return [null, null, question.meaning]
        })
    ])

    useEffect(() => {
        onChange(itemValues)
    }, [itemValues])




    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the drop area
        //loop through all itemValues, and replace first and second value of the row where the item was dropped based on result.droppableId
        //result.droppableId is in the format row1Item1, row1Item2, row2Item1, row2Item2
        soundManager.playImmediate("drop")
        var newArray = []
        var droppableId = result.destination.droppableId;

        var draggable = draggableItems[Number.parseInt(result.draggableId)]

        for (let i = 0; i < itemValues.length; i++) {
            const row = itemValues[i];
            if (droppableId.includes(`row${i}Item1`)) {
                row[0] = draggable;
            } else if (droppableId.includes(`row${i}Item2`)) {
                row[1] = draggable;
            }

            newArray.push(row)
        }
        setItemValues(newArray)

        // setDroppedItem(result.draggableId);
    };

    return (
        <DragDropContext onDragStart={() => {
            soundManager.playImmediate("drag")
        }} onDragEnd={handleDragEnd}>
            <div className={styles.tableContainer}>
                <div className='flex  px-4 gap-2 relative'>
                    <table className={cn(styles.formerTable, "w-8/12 ")}>
                        <thead>
                            <td className={styles.colTitle}>
                                1
                            </td>
                            <td className={styles.colTitle}>
                                2
                            </td>
                            <td className={styles.colTitle}>New Word</td>
                            <td className={styles.colTitle}>Definition</td>
                        </thead>
                        <colgroup>
                            <col style={{ width: "17.5%" }} />
                            <col style={{ width: "17.5%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "40%" }} />
                        </colgroup>
                        <tbody>
                            {itemValues.map((iv, i) => {
                                return <MatchingTableRow itemResult={itemResults?.[i]} answerCorrections={answerCorrections} rowIndex={i} item1={iv[0]} item2={iv[1]} meaning={iv[2]} gradeLevel={gradeLevel} />
                            })}
                        </tbody>
                    </table>
                    <div className='w-4/12'>
                        <Draggables droppableId={"dragArea"} items={draggableItems} />
                    </div>
                    <div className={styles.nextContainer}>
                        <ButtonHolder
                            reference={
                                !itemValues.every(it => it[0] && it[1]) ? "rax143" : "rax123"
                            }
                            isDisabled={
                                !itemValues.every(it => it[0] && it[1])
                            } icon={"nextarrow"} title={"Continue"} background={"green"} onClick={async () => {
                                await onComplete(itemValues.map(it => [it[0], it[1]]))


                            }}>Complete</ButtonHolder>
                    </div>
                </div>
            </div>
        </DragDropContext>
    );
}

function SentenceItemRow({ sentence, answerCorrections, itemResult, itemsList, rowIndex, onComplete, onClear }) {

    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the drop area

    }
    var mergedWord = (itemsList || []).join("")

    return <div className={cn("")}>
        <span className='bg-black p-2 inline text-sm rounded-full text-white'>
            #{rowIndex + 1}
        </span>
        <p className='!p-0 inline mx-2'>
            {
                sentence.split("[[item]]").map((itm, i) => {
                    return <>
                        <span className='text-base inline lg:text-lg  items-center gap-2'>
                            {itm}
                        </span>
                        {
                            (i == 0) && <Droppable droppableId={`row${rowIndex}Items`} >
                                {(provided, snapshot) => (
                                    <span
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}

                                        className={clsx(styles.sentenceFillableItem, (typeof itemResult != "undefined") && (itemResult ? styles.correct : styles.incorrect),)}
                                    >
                                        <AppearingDisappearingWord content={((mergedWord?.length > 0) ? (answerCorrections[mergedWord] ? answerCorrections[mergedWord] : mergedWord) : null)} />

                                        <div className={clsx(styles.draggingOverlay, snapshot.isDraggingOver && styles.visible)} />
                                        <div onClick={() => {
                                            onClear()
                                        }} className={clsx(styles.deleteOverlay, snapshot.isDraggingOver && styles.hidden)}>Clear</div>

                                    </span>
                                )}
                            </Droppable>
                        }
                    </>

                })
            }

        </p>

    </div>
}

export function CompletingSentence({ draggableOptions: draggableItems, itemResults, onChange, answerCorrections, questions, onComplete }) {
    const [itemValues, setItemValues] = useState([
        ...questions.map(question => {
            return [[], question.sentence]
        })
    ])



    useEffect(() => {
        onChange(itemValues)
    }, [itemValues])
    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside the drop area
        //loop through all itemValues, and replace first and second value of the row where the item was dropped based on result.droppableId
        //result.droppableId is in the format row1Item1, row1Item2, row2Item1, row2Item2
        soundManager.playImmediate("drop")

        var newArray = []
        var droppableId = result.destination.droppableId;

        var draggable = draggableItems[Number.parseInt(result.draggableId)]

        for (let i = 0; i < itemValues.length; i++) {
            const row = itemValues[i];
            if (droppableId.includes(`row${i}Items`)) {
                //if the row[0] contains 3 or more items, clear everything and just add draggable, else append draggable to the end of the array
                if (row[0].length >= 3) {
                    row[0] = [draggable]
                }
                else {
                    row[0].push(draggable)
                }
            }
            newArray.push(row)
        }
        setItemValues(newArray)

        // setDroppedItem(result.draggableId);
    };

    return (
        <DragDropContext onDragStart={() => {
            soundManager.playImmediate("drag")
        }} onDragEnd={handleDragEnd} >
            <div className={styles.questionPaperContainer}>
                <div className={styles.sentenceQuestionPaperInner}>
                    <div className={cn("flex flex-col gap-2")}>
                        {itemValues.map((iv, i) => {
                            return <SentenceItemRow onClear={() => {
                                setItemValues(itmVl => {
                                    return itmVl.map((it, index) => {
                                        return index == i ? [[], it[1]] : it
                                    });
                                })
                            }} itemResult={itemResults?.[i]} sentence={iv[1]} answerCorrections={answerCorrections} rowIndex={i} itemsList={iv[0]} />
                        })}

                    </div>
                </div>
                <div className={styles.actionsContainer}>
                    <Draggables droppableId={"dragArea"} items={draggableItems} />

                    <div className={styles.nextContainer}>
                        <ButtonHolder
                            reference={
                                !itemValues.every(it => it[0]?.length > 0) ? "ref-valid" : "ref-invalid"
                            }
                            isDisabled={
                                !itemValues.every(it => it[0]?.length > 0)
                            } icon={"nextarrow"} title={"Continue"} background={"green"} onClick={async () => {
                                await onComplete(itemValues.map(it => it[0]))

                            }}>Complete</ButtonHolder>
                    </div>
                </div>
            </div>


        </DragDropContext>
    );
}

function Day2({ itemNumber, repeatCount, id, qIndex, lessonId, countDownDetails: { start, reset, stop, state }, questionIndex, submitResult, status, setStatus, qProps: [isQuestioning, setIsQuestioning, isQuestioningHiding] }) {
    const { user: { grade } } = useContext(UserContext)
    console.log({ grade })
    const dayDetails = useCTLesson(itemNumber - 1, grade)
    const gradeLevel = grade
    const { prefix, rootWords, suffix, questions, narrations } = dayDetails;
    const [itemResults, setItemResults] = useState(null)
    const [sentenceResults, setSentenceResults] = useState({})
    const s3FieldsRef = useRef(null)
    const audioBlob = useRef(null)
    const metaBlob = useRef(null)


    const setInstruction = useInstructionCT()
    useEffect(() => {
        setStatus(null)
        setItemResults(null)
    }, [questionIndex])
    const soundManager = useSoundManager()
    const itemDetails = questions[questionIndex];

    const [rioInstructionPlayed, setRioInstructionPlayed] = useState(false)

    useEffect(() => {
        (async () => {
            setInstruction(null)
            await wait(1)
            if (itemDetails.type == "creation") {
                setInstruction([narrations["tableInstruction"]])

                soundManager.playURL(narrations["tableInstruction"]).then(() => {

                });
            } else if (itemDetails.type == "insertion") {
                setInstruction([narrations["sentenceInstruction"]])
                soundManager.playURL(narrations["sentenceInstruction"]).then(() => {

                });

            } else if (itemDetails.type === "rio") {
                if (!rioInstructionPlayed) {
                    setInstruction([narrations["rioInstruction"]])
                    soundManager.playURL(narrations["rioInstruction"]).then(() => {
                        setRioInstructionPlayed(true)
                    });
                }

            }
        })().catch(alert)
        return () => {
            setInstruction(null)

        }

    }, [itemDetails]);

    useEffect(() => {
        if (itemDetails.type === "creation") {
            start(4 * 60)

        } else if (itemDetails.type === "insertion") {
            start(4 * 60)


        } else if (itemDetails.type === "rio") {
            const level = getGradeLevel(grade)
            start(
                level === "middle" ? 60 : 30
            )

        }
    }, [itemDetails, repeatCount]);
    useEffect(() => { }, [])



    const clickHandler = async (item) => {
        setStatus("processing")
        if ((itemDetails.type === "creation") || (itemDetails.type === "insertion")) {
            await submitResult({
                answer: item
            }, true, async (res) => {
                const itemObject = []

                for (let i = 0; i < res.score.length; i++) {
                    const score = res.score[i];
                    itemObject.push(score)
                    setItemResults([...itemObject])

                    if (score) {
                        await soundManager.playSound("instruct", "correct", false, 0.06);
                    } else {
                        await soundManager.playSound("instruct", "wrong", false, 0.06);
                    }
                    if ((itemDetails.type === "creation")) {
                        if (score || ((res.isCorrect) || (!res.retry))) {
                            const prefix = getGradeAudioPrefix(grade)
                            const audioFile = `https://read21-assets.s3.amazonaws.com/${prefix}/${lessonId}/c${lessonId}d2-${i + 1}.mp3`
                            console.log(audioFile)
                            try {
                                await soundManager.playURL(audioFile)
                            } catch {
                                console.log("Audio not found")
                            }

                        }
                    }


                    await wait(600)
                }




            });
        }
        else if (itemDetails.type === "rio") {
            setIsQuestioning(false)
            const { url, uploadId } = s3FieldsRef.current
            let isUploaded = false;

            if (audioBlob.current) {
                await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav");
                isUploaded = true;
            }

            await submitResult({
                answer: isUploaded ? uploadId : null,
                recMeta: metaBlob.current.text
            }, false, async (result) => {
                await soundManager.cleanAllChannel()
                if (result.sentence) {
                    const sentence = Object.keys(result.sentence).reduce((prev, wrd) => ({ ...prev, [wrd.toLowerCase()]: result.sentence[wrd] }), {})
                    setSentenceResults((results) => ({ ...results, [questionIndex]: sentence }))
                }

                await wait(2000)


            });
            setStatus(null)


        }

    }



    return (
        <>
            {
                itemDetails.type === "creation" && <MatchingTables
                    onComplete={clickHandler}
                    onChange={() => {
                        setItemResults(null)
                    }}
                    gradeLevel={gradeLevel}
                    questions={itemDetails.questionItems}
                    itemResults={itemResults}
                    answerCorrections={questions[questionIndex].answerCorrections} draggableOptions={[
                        ...questions[questionIndex].options
                    ]} />
            }
            {
                itemDetails.type === "insertion" && <CompletingSentence
                    onComplete={clickHandler}
                    onChange={() => {
                        setItemResults(null)
                    }}
                    gradeLevel={gradeLevel}
                    questions={itemDetails.questionItems}
                    itemResults={itemResults}
                    answerCorrections={questions[questionIndex].answerCorrections} draggableOptions={[
                        ...questions[questionIndex].options,
                    ]} />
            }
            {
                itemDetails.type === "rio" && <CTRIO
                    status={status}
                    questionIndex={questionIndex}
                    itemDetails={itemDetails}
                    questions={questions}
                    sentenceResults={sentenceResults}
                    setSentenceResults={setSentenceResults}
                    s3FieldsRef={s3FieldsRef}
                    audioBlob={audioBlob}
                    setStatus={setStatus}
                    metaBlob={metaBlob}
                    clickHandler={clickHandler}
                    soundManager={soundManager}
                >
                </CTRIO>
            }



        </>
    );
}

export default Day2;
