import Modal from "./Modal";
import styles from "./MyReports.module.css"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,

} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Button from "./Button";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {useContext, useEffect, useState} from "react";
import {getReports} from "../../resources/api";
import Loading from "./Loading";
import UserContext from "../../context/UserContext";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export default function MyReports({close}){
    const [isLoading, setIsLoading] = useState(true)
    const {user} = useContext(UserContext);
    const [isDownloading, setIsDownloading] = useState(false);    

    function downloadPDF() {
        setIsDownloading(true);
        setTimeout(() => {
            const modalElement = document.querySelector(`.${styles.modal}`); 
    
            html2canvas(modalElement)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
        
                    // Add image Canvas to PDF
                    const imgProps= pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        
                    pdf.save("download.pdf");
                    setIsDownloading(false);
                });
        }, 1000);
     
    }

    const [dataSet, setDataset]= useState([])
    useEffect(() => {
        getReports().then(result => {
            var nRes = Array.from({ length: 21 }, (_, i) => result[i] || { trialOne: null, trialTwo: null, trialThree: null, status: null });
            console.log(nRes)
            setDataset(nRes)
            setIsLoading(false)
        })
    }, [])

    const lessons = Array.from({length: 21}, (_, i) => `Lesson ${i + 1}`);
    const trials = ['First', 'Second', 'Third'];


    const trialColors = [
        { borderColor: 'rgba(0, 116, 217, 0.2)', backgroundColor: 'rgba(0, 116, 217, 0.8)' },  // First color
        { borderColor: 'rgba(46, 204, 64, 0.2)', backgroundColor: 'rgba(46, 204, 64, 0.8)' },  // Second color
        { borderColor: 'rgba(255, 133, 27, 0.2)', backgroundColor: 'rgba(255, 133, 27, 0.8)' }   // Third color
    ];

    if(isLoading){
        return <Modal close={close}>
            <div className={styles.modal}>
                <Loading/>
            </div>
        </Modal>
    }
    const data = {
        labels: lessons,
        datasets: trials.map((trialName, trialIndex) => ({
            label: trialName,
            data: dataSet.map((itm, lessonIndex) => {
                if (itm === 'skipped' || itm === 'pending') {
                    return null;
                }
                return itm[trialIndex] * 100; // multiply by 100
            }),
            ...trialColors[trialIndex],
            borderWidth: 2,
            borderRadius: Number.MAX_VALUE,
            borderSkipped: 'start',
        }))
    }

    const options = {
        indexAxis: 'y',
        scales: {
            y: {
                beginAtZero: false,
                max: 100,

                grid: {
                    color: '#FAFAFA',
                    drawOnChartArea: true,
                    circular: true,
                    lineWidth: 3
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                }
            },
            x: {
                beginAtZero: false,
                min:1,
                max: 100,
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                },
                barPercentage: 0.5,
                grid: {
                    z: -1, // add this l
                    color: (context) => {
                        return context.tick.value === 80 ? '#3d9970' : '#FAFAFA';
                    },
                    drawOnChartArea: true,
                    //dashed
                    borderDash: [5, 5],
                    circular: true,
                    lineWidth: 2
                },
            }
        },
        maintainAspectRatio: false,
    };
    const lList = [
        [1, 2, 3, 4],
        [5, 6, 7, 8],
        [9, 10, 11, 12],
        [13, 14, 15, 16],
        [17, 18, 19, 20],
        [21],
    ];
    const rowsOfLessons = [];
    for (let i = 0; i < lessons.length; i += 4) {
        rowsOfLessons.push(lessons.slice(i, i + 4));
    }

    const LessonCircle = ({ lessonNumber, isLocked }) => (
        <div
            style={{
                backgroundColor: isLocked ? 'black' : 'white',
                color: isLocked ? 'white' : 'black',
                border: '1px solid black',
                borderRadius: '50%',
                width: '35px',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {lessonNumber}
        </div>
    );

    return <Modal close={close}>
        <div className={styles.modalContainer}>
        <div className={styles.modal}>
            <div className={styles.reportTitleBox}>
                <h1>{user?.firstName}'s Report</h1>
            </div>
            <div className={styles.columnGaper}>
                <div style={{width: 200}}>
                    <div>
                        <p>Based on your child’s <b>Pre-Test results</b>, he/she, will SKIP the lessons marked by "⭐".</p>
                        <div className={styles.lessonCircles}>
                    {Array.from({length: 20}, (_, i) => i).map((lesson) => {
                        const isLocked = dataSet[lesson] === 'skipped';
                                return (
                                    <LessonCircle
                                        lessonNumber={lesson+1}
                                        isLocked={isLocked}
                                    />
                                );
                        })}
                </div>
                    </div>
                    <div style={{marginTop:30}}>
                        <h3>Lesson Support</h3>
                        <table className={styles.table}>
                            <tbody>
                            {lList.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => {
                                        var cellItem = cell.toString()
                                        cellItem = cellItem.length == 1 ? `0${cellItem}` : cellItem
                                        return <td key={cellIndex}>
                                            <a target={"_blank"} href={`https://read21-assets.s3.amazonaws.com/Home+Reports/Lesson+Supports/LS+-+${cellItem}.pdf`}>{cell}</a>
                                        </td>
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                
                    {/* <div style={{marginTop:30}}>
                        <h3>Print Support</h3>
                        <table className={styles.table}>
                            <tbody>
                            {lList.filter(i=>i.length > 1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => {
                                        var cellItem = cell.toString()
                                        cellItem = cellItem.length == 1 ? `0${cellItem}` : cellItem
                                        return <td key={cellIndex}>
                                            <a target={"_blank"} href={`https://read21-assets.s3.amazonaws.com/Home+Reports/Print+Supports/L${cellItem}.pdf`}>{cell}</a>
                                        </td>
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div> */}

                    <div style={{marginTop:30}}>
                        <p><b>*Lessons can be reset once after Lesson 21 is completed during subscription.</b></p>
                        </div>
                  
                </div>
                <div className={styles.barContainer}>
                    <div className={styles.barItem}>
                    <Bar id={"myReportChart"} data={data} options={options} plugins={[
                        {
                            afterDraw: (chart) => {
                                const ctx = chart.ctx;
                                ctx.save();
                                dataSet.forEach((lesson, lessonIndex) => {
                                    if (lesson === 'skipped' || lesson === 'pending') {
                                        const meta = chart.getDatasetMeta(0);
                                        const element = meta.data[lessonIndex];
                                        const { x, y, height } = element.getProps(['x', 'y', 'height'], true);
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'middle';
                                        const text = lesson === 'skipped' ? '⭐️⭐️⭐️⭐️⭐️⭐️' : '🕑🕑🕑🕑🕑🕑';
                                        var metr = ctx.measureText(text)
                                        const yPos = y + ((height / 2) + 5);  // this should place the text in the middle of the bar
                                        ctx.fillText(text, (chart.width / 2), yPos);
                                    }
                                });
                                ctx.restore();
                            }

                        },
                    ]
                    }/>
                    </div>
                    
                    <div style={{ opacity: isDownloading?0:1}}>
        <Button onClick={downloadPDF} icon={"download"} contentSize={"small"} title={"Download"}/>
    </div>
                </div>

            </div>


        </div>
        </div>
    </Modal>
}