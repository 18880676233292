import styles from "./Introduction.module.css"
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { playSound } from "../../utils/playsound";
import Button from "../common/Button";
import { activateStudent } from "../../resources/api";
import { pauseAll } from "../../utils/audios";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

export default function Introduction({ onNext, isFluency = true }) {
    const playerRef = useRef()
    const navigate = useNavigate()
    const [isEnabled, setIsEnabled] = useState(false)
    const { user } = useContext(UserContext)
    useEffect(() => {
        soundManager.cleanAllChannel()
    }, [])
    const particlesInit = useCallback(async (engine) => {
        console.dir(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);

    }, []);
    const particlesLoaded = useCallback(async (container) => {

        await console.log(container);
    }, []);
    useEffect(() => {
        (async () => {

            playerRef.current.play()
            return () => {
                playerRef.current.pause()
            }

        })().catch(alert)
    }, [])
    var relevantVideo = user?.grade ? (Number.parseInt(user.grade) > 5 ? "older" : "younger") : "older"
    return <div className={styles.introductoryPage}>

        <div className={styles.imgAndTitle}>
            <video onEnded={async () => {
                setIsEnabled(true)
                await playSound("twinkle")

            }

            } controls={true} controlsList="nodownload nofullscreen noremoteplayback" preload={"auto"} ref={playerRef} src={
                isFluency ? `https://d3vx0tne0vxeo1.cloudfront.net/videos/intro/${relevantVideo}.mp4`
                    : `https://d3vx0tne0vxeo1.cloudfront.net/videos/ctintro/${relevantVideo}.mp4`
            }></video>
        </div>

        <div className={styles.bottomContainer}>
            <Button isDisabled={!isEnabled} onClick={async () => {
                setIsEnabled(false)
                playerRef.current.currentTime = 0;
                playerRef.current.play()
            }
            } paddingV="full" paddingH="full" title={"Repeat"} icon={"repeat"} background={"blue"} contentSize={"large"} click={"inside"} />
            <Button isDisabled={!isEnabled} onClick={async () => {
                await activateStudent()
                playerRef.current.pause();
                if (isFluency) {
                    await navigate("/lessons/0")
                } else {
                    await onNext()
                }
                // await pauseAll()

                // onNext();
            }
            } paddingV="full" paddingH="full" title={"Begin"} icon={"right"} background={"green"} contentSize={"large"} click={"inside"} />
        </div>
    </div>
}