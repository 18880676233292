import styles from "./CoinsContainer.module.css"
import {useMemo} from "react";
import {BronzeCoin, GoldCoin, SilverCoin} from "../../resources/svgs";
import clsx from "clsx";

export default function CoinsContainer({coinBuckets, isCoinEarned, coinGroupId}){
    return <div className={styles.coinContainer}>
        {
            coinBuckets.map((bucket, i)=>{
                return <div key={`${i}`} className={clsx(styles.slotHolder, (coinGroupId == i) && isCoinEarned && styles.coinEarned)}>
                    {
                        bucket == 3 && <GoldCoin className={styles.coin}/>
                    }
                    {
                        bucket == 2 && <SilverCoin className={styles.coin}/>
                    }
                    {
                        bucket == 1 && <BronzeCoin className={styles.coin}/>
                    }
                </div>
            })
        }
    </div>
}