import React from "react";
import {useInstruction} from "../../utils/instruction";

class BaseStage extends React.Component {
    constructor(props) {
        super(props);
        this.props = props
    }
}

export default BaseStage