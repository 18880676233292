import clsx from "clsx";
import styles from "./Components.module.css";
import { useSoundManager } from "../../utils/sound";
import { useEffect, useState } from "react";
import wait from "../../utils/wait";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DragIcon } from "../../resources/svgs";
import useStickyState from "../../utils/stickyState";

export function SoundOrSelectItem({ size, isQuestioning, enabled, item, hideMeaning, type, soundManager: _soundManager, processAnswer }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [answerStatus, setAnswerStatus] = useState(null);
    const isDisabled = isPlaying || isProcessing || (enabled !== true) || (answerStatus != null);

    const soundManager = useSoundManager(_soundManager)
    useEffect(() => {
        if (type == "question") {
            setIsPlaying(false)
        }
    }, [type])
    return <div className={clsx(
        size == "large" ? styles.largeItem : styles.smallItem, styles.baseItem,
        hideMeaning && styles.hideMItem,
        isPlaying && styles.playing,
        isProcessing && styles.processing,
        answerStatus == "correct" && styles.correct,
        answerStatus == "wrong" && styles.wrong,
    )} onClick={async () => {
        if (!isDisabled) {
            if (type == "question") {
                if (!isQuestioning) {
                    return
                }
                setIsProcessing(true)
                setAnswerStatus(null)
                await wait(400)
                try {
                    const result = await processAnswer(item)
                    setIsProcessing(false)
                    if (result.isCorrect) {
                        setAnswerStatus("correct")
                    } else {
                        setAnswerStatus("wrong")
                    }

                    await wait(2000)
                    setAnswerStatus(null)
                } catch (e) {
                    alert("error")
                    console.error(e)
                    setIsProcessing(false)
                }

            } else if (type == "play") {
                setIsPlaying(true)
                try {
                    await soundManager.playURL(item.sound, "sounds")

                } catch (e) {
                    console.error(e)
                }
                setIsPlaying(false)
            }
        }

    }
    }>
        <span className={styles.word}> {item.item}</span>
        <span className={styles.meaning}> {item.meaning}</span>
    </div>
}

export function Draggables({ droppableId = "dragArea", items }) {
    return <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
            <div

                ref={provided.innerRef}
                {...provided.droppableProps}
                className={styles.draggableWordsContainer}

            >
                {items.map((itm, i) => ({ id: i.toString(), content: itm })).map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>

                        {(provided, /** @type {LockManagerSnapshot} */ snapshot) => (
                            <div className={styles.draggableContainerParent}>

                                {
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        style={{
                                            ...provided.draggableProps.style,
                                            transform: snapshot.isDragging ? provided.draggableProps.style?.transform : null
                                        }}
                                        className={clsx(styles.tableTableDraggable,

                                            snapshot.isDragging && styles.dragging,
                                            snapshot.isDropAnimating && styles.dropping, "text-sm lg:text-lg px-3 py-2 lg:py-3"

                                        )}
                                    >
                                        <div  >
                                            <DragIcon fill={"#000"} className=" h-2 lg:h-3" />
                                        </div>

                                        {item.content}
                                    </div>
                                }


                                {
                                    snapshot.isDragging && <div className={clsx(styles.tableTableDraggable, styles.opacityGone)}>
                                        <DragIcon fill={"#000"} height={12} />
                                        {item.content}
                                    </div>
                                }
                            </div>


                        )}

                    </Draggable>
                ))}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
}

export function AppearingDisappearingWord({ content, className, ...props }) {
    const [textItem, setTextItem, isTextHiding] = useStickyState(null, 1000)
    useEffect(() => {
        setTextItem(content)
    }, [content])
    return <span key={content} className={clsx(className, styles.appearingWord, isTextHiding && styles.hiding, "text-sm lg:text-lg")}>{textItem}</span>
}