import styles from "./Intros.module.css";
import {useMemo} from "react";
import {WordMap} from "../../const/tables";




export default function ImageList({mapKey}){
    const words = useMemo(()=>WordMap[mapKey], [mapKey])
    return <div className={styles.wordList}>
        {
            words.map((img, i)=>{
                return <img style={{'--delay': i+1}} className={styles.word} src={img}/>
            })
        }
    </div>
}