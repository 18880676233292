import clsx from "clsx";
import styles from "./BeatRead21.module.css";
import {useContext, useEffect} from "react";
import StatusContext from "../../context/StatusContext";
export function Read21LogoBeating({place="rt", iconType="normal"}){
    return //<img src={iconType == "normal" ? "/read21.svg" : "read21-white.svg"}  className={clsx(styles.floatingLogo, styles[place])}/>
}
export default function BeatRead21(params){
    const {setRead21Logo} = useContext(StatusContext)
    useEffect(()=>{
        setRead21Logo(params)
        return ()=>{
            setRead21Logo(null)
        }
    }, [])
    return <></>
}