import styles from "./LessonIntroduction.module.css"
import { lazy, useEffect, useMemo, useState } from "react";
import { LessonTitles, ModuleTitles } from "../../const/checkpointTitle";
import { playSound } from "../../utils/playsound";
import clsx from "clsx";
import wait from "../../utils/wait";
import FloatingObject from "./FloatingObjects";

/**
 *
 * @param lessonId
 * @param onNext
 * @param trialId
 * @param  soundManager {SoundManager}
 * @returns {JSX.Element}
 * @constructor
 */
export default function LessonIntroduction({ lessonId, onNext, trialId, soundManager }) {
    const [page, setPage] = useState("introduction");
    const lTitleInfo = useMemo(() => LessonTitles[lessonId], [lessonId])
    const lModuleInfo = useMemo(() => ModuleTitles[lTitleInfo.module], [lTitleInfo])

    const ContentInfo = useMemo(() => require(`../../lessons/intros/${(lTitleInfo.content || "Alphabet")}.js`).default, [lTitleInfo])
    const ExampleComponent = useMemo(() => lTitleInfo.exampleContent && require(`../../lessons/intros/${(lTitleInfo.exampleContent)}.js`).default, [lTitleInfo])
    useEffect(() => {
        (async () => {
            //TODO: make sure to uncomment
            await soundManager.playSound("instruction", "introduction")

            if (lTitleInfo.exampleIncluded && !lTitleInfo.withExampleAudio && !lTitleInfo.waitForExample) {
                setPage("example")
            }
            await wait(2000)
            onNext();

        })().catch(alert)
    }, [])

    return <section className={clsx(styles.lessonTitlePage, ContentInfo && styles.withContent, (page === "example") && styles.examplePresentation)}>

        {
            (page === "example") ? <ExampleComponent trialId={trialId} onNext={() => onNext()} {...(lTitleInfo.exampleProps || {})} /> : <>
                <div className={styles.titleInfo}>
                    <img src="/read21.png" className={styles.read21Logo} />
                    {/* <h1 className={styles.lessonTitle}>Lesson {lessonId}</h1> */}
                    <h2 className="text-xl font-bold" >Fluency Read Checkpoint {parseInt(lessonId) + 1}</h2>
                </div>

                <div className={styles.headingInfo}>
                    <span>
                        Checkpoint Fluency Read
                    </span>
                </div>

                <div className={styles.contentInfo}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" className=" w-56 h-56" height="1em" viewBox="0 0 24 24"><path fill="#0b8617" d="M13.885 9.592v-.93q.805-.408 1.726-.612t1.889-.204q.554 0 1.063.071q.51.071 1.052.202v.908q-.523-.167-1.02-.232T17.5 8.731q-.97 0-1.892.218t-1.723.643m0 5.462v-.97q.767-.407 1.716-.61t1.899-.205q.554 0 1.063.071q.51.072 1.052.202v.908q-.523-.167-1.02-.232t-1.095-.064q-.97 0-1.892.234t-1.723.666m0-2.712v-.969q.805-.408 1.726-.611t1.889-.204q.554 0 1.063.07q.51.072 1.052.203v.907q-.523-.167-1.02-.231t-1.095-.065q-.97 0-1.892.238t-1.723.662M12.5 17.32q1.215-.679 2.453-.98t2.547-.3q.9 0 1.618.111t1.267.296q.23.096.423-.029t.192-.394V7.008q0-.173-.096-.308t-.327-.23q-.825-.293-1.501-.4T17.5 5.961q-1.31 0-2.613.386q-1.304.387-2.387 1.16zm-.5 1.45q-1.22-.834-2.62-1.282t-2.88-.449q-.78 0-1.534.131q-.753.131-1.466.42q-.544.217-1.022-.131T2 16.496V6.831q0-.371.195-.689t.547-.442q.887-.383 1.836-.56T6.5 4.961q1.47 0 2.866.423T12 6.615q1.237-.807 2.634-1.23t2.866-.423q.973 0 1.922.177q.95.178 1.836.561q.352.125.547.442q.195.318.195.689v9.665q0 .614-.516.942q-.517.33-1.1.112q-.694-.27-1.418-.39t-1.466-.122q-1.48 0-2.88.449T12 18.769"></path></svg>
                </div>
            </>
        }

        <FloatingObject color={"rgba(78,84,200, 0.2)"} />
    </section>
}