export const Tree1 = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 30"
      fill="none"
    >
      <path
        d="M8.03844 21.0607L8.02319 21.0446L7.99637 20.2748L17.0705 16.7403L8.016 19.7092L7.98128 19.8611L7.76919 13.7065L15.6228 9.62558L7.76395 12.6338L8.04186 0.075057L8.04205 0.0330531L8.03908 0.073617L7.36885 9.85069L0.361872 3.92879L7.33263 10.6987L7.09323 16.089L0.670317 9.04471L7.05696 16.8812L6.92345 19.8783L6.55505 28.1822L6.52804 28.8028C7.12713 28.9655 7.72021 29.1198 8.30736 29.2659L8.02621 21.1197L8.03844 21.0607Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export const Tree2 = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 27 26"
      fill="none"
      className={className}
    >
      <g clip-path="url(#clip0_88_194)">
        <path
          d="M16.5 23.8333C16.5 25.4287 15.1568 25.9999 13.5 25.9999C11.8432 25.9999 10.5 25.4287 10.5 23.8333L11.25 17.3333C11.25 15.7379 11.8432 15.8888 13.5 15.8888C15.1568 15.8888 15.75 15.7379 15.75 17.3333L16.5 23.8333Z"
          fill="#ABABA5"
        />
        <path
          d="M25.5 12.2778C25.5 18.6601 20.1278 20.9444 13.5 20.9444C6.873 20.9444 1.5 18.6601 1.5 12.2778C1.5 5.89622 8.25 0 13.5 0C18.75 0 25.5 5.89622 25.5 12.2778Z"
          fill="#2E4F63"
        />
        <path
          d="M4.5 15.8888C5.32843 15.8888 6 15.5654 6 15.1666C6 14.7677 5.32843 14.4443 4.5 14.4443C3.67157 14.4443 3 14.7677 3 15.1666C3 15.5654 3.67157 15.8888 4.5 15.8888Z"
          fill="#41ADE5"
        />
        <path
          d="M22.5 15.8888C23.3284 15.8888 24 15.5654 24 15.1666C24 14.7677 23.3284 14.4443 22.5 14.4443C21.6716 14.4443 21 14.7677 21 15.1666C21 15.5654 21.6716 15.8888 22.5 15.8888Z"
          fill="#41ADE5"
        />
        <path
          d="M7.5 18.7777C8.32843 18.7777 9 18.4543 9 18.0555C9 17.6566 8.32843 17.3333 7.5 17.3333C6.67157 17.3333 6 17.6566 6 18.0555C6 18.4543 6.67157 18.7777 7.5 18.7777Z"
          fill="#41ADE5"
        />
        <path
          d="M10.5 16.6112C11.3284 16.6112 12 16.2878 12 15.889C12 15.4901 11.3284 15.1667 10.5 15.1667C9.67157 15.1667 9 15.4901 9 15.889C9 16.2878 9.67157 16.6112 10.5 16.6112Z"
          fill="#41ADE5"
        />
        <path
          d="M7.5 12.2777C8.32843 12.2777 9 11.9543 9 11.5555C9 11.1566 8.32843 10.8333 7.5 10.8333C6.67157 10.8333 6 11.1566 6 11.5555C6 11.9543 6.67157 12.2777 7.5 12.2777Z"
          fill="#41ADE5"
        />
        <path
          d="M5.25 9.38878C6.07843 9.38878 6.75 9.06543 6.75 8.66656C6.75 8.26769 6.07843 7.94434 5.25 7.94434C4.42157 7.94434 3.75 8.26769 3.75 8.66656C3.75 9.06543 4.42157 9.38878 5.25 9.38878Z"
          fill="#41ADE5"
        />
        <path
          d="M21.75 9.38878C22.5784 9.38878 23.25 9.06543 23.25 8.66656C23.25 8.26769 22.5784 7.94434 21.75 7.94434C20.9216 7.94434 20.25 8.26769 20.25 8.66656C20.25 9.06543 20.9216 9.38878 21.75 9.38878Z"
          fill="#41ADE5"
        />
        <path
          d="M10.5 7.94444C11.3284 7.94444 12 7.62109 12 7.22222C12 6.82335 11.3284 6.5 10.5 6.5C9.67157 6.5 9 6.82335 9 7.22222C9 7.62109 9.67157 7.94444 10.5 7.94444Z"
          fill="#41ADE5"
        />
        <path
          d="M16.5 7.94444C17.3284 7.94444 18 7.62109 18 7.22222C18 6.82335 17.3284 6.5 16.5 6.5C15.6716 6.5 15 6.82335 15 7.22222C15 7.62109 15.6716 7.94444 16.5 7.94444Z"
          fill="#41ADE5"
        />
        <path
          d="M19.5 12.2777C20.3284 12.2777 21 11.9543 21 11.5555C21 11.1566 20.3284 10.8333 19.5 10.8333C18.6716 10.8333 18 11.1566 18 11.5555C18 11.9543 18.6716 12.2777 19.5 12.2777Z"
          fill="#41ADE5"
        />
        <path
          d="M13.5 13.0001C14.3284 13.0001 15 12.6768 15 12.2779C15 11.879 14.3284 11.5557 13.5 11.5557C12.6716 11.5557 12 11.879 12 12.2779C12 12.6768 12.6716 13.0001 13.5 13.0001Z"
          fill="#41ADE5"
        />
        <path
          d="M16.5 16.6112C17.3284 16.6112 18 16.2878 18 15.889C18 15.4901 17.3284 15.1667 16.5 15.1667C15.6716 15.1667 15 15.4901 15 15.889C15 16.2878 15.6716 16.6112 16.5 16.6112Z"
          fill="#41ADE5"
        />
        <path
          d="M13.5 19.5001C14.3284 19.5001 15 19.1768 15 18.7779C15 18.379 14.3284 18.0557 13.5 18.0557C12.6716 18.0557 12 18.379 12 18.7779C12 19.1768 12.6716 19.5001 13.5 19.5001Z"
          fill="#41ADE5"
        />
        <path
          d="M19.5 18.7777C20.3284 18.7777 21 18.4543 21 18.0555C21 17.6566 20.3284 17.3333 19.5 17.3333C18.6716 17.3333 18 17.6566 18 18.0555C18 18.4543 18.6716 18.7777 19.5 18.7777Z"
          fill="#41ADE5"
        />
      </g>
      <defs>
        <clipPath id="clip0_88_194">
          <rect width="27" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Ship = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="71"
      className="absolute bottom-2 z-50 right-0"
      viewBox="0 0 100 71"
      fill="none"
    >
      <path
        d="M6.82048 58.4443C6.58383 58.4726 6.35056 58.5348 6.12363 58.6299C5.01557 59.1136 2.5289 60.413 4.53701 61.62C4.53701 61.62 9.11732 64.2741 18.3265 64.2852L37.4412 65.3905C37.4412 65.3905 42.6419 64.8033 51.4259 65.2576C51.4259 65.2576 62.7968 66.673 71.2498 61.5738C71.2498 61.5738 74.2038 59.7267 76.0016 59.4807C76.0016 59.4807 82.5349 57.0033 79.4904 52.8673C78.5519 52.0261 77.5091 51.5271 76.4468 51.4107C76.1108 51.3826 75.7772 51.2696 75.4595 51.0762C73.2924 49.7443 64.8281 44.8674 59.5216 46.3889C59.5216 46.3889 55.6871 47.1736 52.7403 46.2338L48.4552 45.5127C48.4552 45.5127 43.8576 44.116 39.1155 46.3454C39.1155 46.3454 31.339 49.1752 27.9917 49.0951C27.9917 49.0951 17.6533 48.3151 14.6678 52.8113C14.6678 52.8113 12.2091 55.2361 10.4116 56.1662C9.90431 56.4266 9.41398 56.752 8.94561 57.1389C8.27769 57.6761 7.35434 58.3653 6.82048 58.4443Z"
        fill="url(#paint0_linear_88_425)"
      />
      <path
        d="M49.3238 57.9567C49.0704 57.9856 48.8203 58.0473 48.5769 58.1409C47.3879 58.6166 44.7186 59.8917 46.8607 61.0503C46.8607 61.0503 51.7474 63.597 61.6044 63.5526L82.0572 64.5102C82.0572 64.5102 87.6273 63.9097 97.0266 64.2977C97.0266 64.2977 109.189 65.6023 118.267 60.6064C118.267 60.6064 121.44 58.7973 123.366 58.548C123.366 58.548 130.374 56.1062 127.14 52.1131C126.14 51.3029 125.027 50.8252 123.891 50.7187C123.531 50.6934 123.175 50.5858 122.836 50.4002C120.524 49.1214 111.494 44.4422 105.805 45.9495C105.805 45.9495 101.696 46.7336 98.5472 45.8397L93.965 45.166C93.965 45.166 89.0523 43.8389 83.9633 46.0294C83.9633 46.0294 75.6227 48.8204 72.0404 48.7628C72.0404 48.7628 60.9794 48.0682 57.7569 52.4466C57.7569 52.4466 55.1108 54.813 53.1813 55.7259C52.6367 55.9814 52.1099 56.2999 51.6063 56.678C50.8882 57.203 49.8957 57.8769 49.3238 57.9567Z"
        fill="url(#paint1_linear_88_425)"
      />
      <path
        d="M81.0419 35.4409C83.8539 36.9025 85.6345 38.9476 85.7739 41.2982C86.0743 46.3621 78.6528 50.9218 69.1976 51.4826C65.0868 51.7265 61.2694 51.1736 58.2414 50.0553C61.4871 51.7424 66.1073 52.6518 71.1734 52.3513C80.6285 51.7904 88.05 47.2307 87.7497 42.1669C87.5799 39.3047 84.9784 36.8947 81.0419 35.4409Z"
        fill="#F1F1F1"
        fill-opacity="0.41"
      />
      <path
        d="M87.2894 38.5611C90.4625 40.2103 92.4716 42.518 92.629 45.1704C92.9679 50.8844 84.5936 56.0294 73.9246 56.6623C69.286 56.9374 64.9785 56.3136 61.5618 55.0517C65.2242 56.9554 70.4375 57.9815 76.154 57.6425C86.823 57.0096 95.1973 51.8645 94.8584 46.1506C94.6668 42.9209 91.7313 40.2016 87.2894 38.5611Z"
        fill="#F1F1F1"
        fill-opacity="0.41"
      />
      <path
        d="M78.8209 37.1101C80.802 38.1399 82.0565 39.5807 82.1547 41.2368C82.3663 44.8044 77.1377 48.0168 70.4763 48.4119C67.8835 48.606 65.2725 48.2659 62.7574 47.4063C65.044 48.5949 68.2991 49.2356 71.8683 49.0239C78.5296 48.6288 83.7583 45.4163 83.5467 41.8487C83.4271 39.8322 81.5942 38.1344 78.8209 37.1101Z"
        fill="#F1F1F1"
        fill-opacity="0.41"
      />
      <path
        d="M44.626 36.0427C44.626 36.0427 46.1997 50.77 66.2007 49.5836C86.2017 48.3972 91.2971 33.2743 91.2971 33.2743L44.626 36.0427Z"
        fill="#C0C3A7"
      />
      <path
        d="M73.1383 8.08293C70.3747 5.85694 67.0194 4.97587 63.7752 5.62424C66.7485 6.29338 69.4516 8.18671 71.4102 10.9719C73.3687 13.7572 74.4575 17.2564 74.4854 20.8554C74.5134 24.4544 73.4787 27.9233 71.563 30.6533C69.6473 33.3833 66.973 35.1999 64.0093 35.7844C66.9162 36.4396 69.9021 35.8844 72.4651 34.2123C75.0282 32.5402 77.0121 29.853 78.0832 26.6026C79.1544 23.3522 79.2476 19.7367 78.347 16.364C77.4465 12.9913 75.6072 10.0671 73.1383 8.08293Z"
        fill="#C0C3A7"
      />
      <path
        d="M66.8894 3.4341L65.6509 3.50757L67.7414 38.7502L68.9799 38.6767L66.8894 3.4341Z"
        fill="#C0C3A7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_88_425"
          x1="15.3239"
          y1="55.0714"
          x2="47.2259"
          y2="56.9714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6E1F2" />
          <stop offset="1" stop-color="#2E9DDC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_88_425"
          x1="67.395"
          y1="66.5148"
          x2="198.914"
          y2="58.7134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2D9CDB" />
          <stop offset="1" stop-color="#82A4E4" stop-opacity="0.79" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Water = ({ className }) => {
  return (
    <svg
      width="54"
      className={className}
      height="21"
      viewBox="0 0 54 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.32384 13.9567C3.07038 13.9856 2.82032 14.0473 2.57686 14.1409C1.38795 14.6166 -1.28144 15.8917 0.860726 17.0503C0.860726 17.0503 5.74739 19.597 15.6044 19.5526L36.0572 20.5102C36.0572 20.5102 41.6273 19.9097 51.0266 20.2977C51.0266 20.2977 63.189 21.6023 72.2671 16.6064C72.2671 16.6064 75.44 14.7973 77.3658 14.548C77.3658 14.548 84.3735 12.1062 81.1396 8.11309C80.1401 7.3029 79.027 6.82517 77.8906 6.71868C77.5312 6.69343 77.1748 6.58582 76.8358 6.40016C74.5243 5.1214 65.4937 0.442229 59.8048 1.94953C59.8048 1.94953 55.6957 2.73356 52.5472 1.83973L47.965 1.16602C47.965 1.16602 43.0523 -0.161056 37.9633 2.02945C37.9633 2.02945 29.6227 4.82045 26.0404 4.76279C26.0404 4.76279 14.9794 4.06816 11.7569 8.44663C11.7569 8.44663 9.11081 10.813 7.18129 11.7259C6.6367 11.9814 6.10993 12.2999 5.60629 12.678C4.88817 13.203 3.89574 13.8769 3.32384 13.9567Z"
        fill="url(#paint0_linear_88_427)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_88_427"
          x1="21.395"
          y1="22.5148"
          x2="152.914"
          y2="14.7134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2D9CDB" />
          <stop offset="1" stop-color="#82A4E4" stop-opacity="0.79" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Bird = ({ className }) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.30859 5.82531L15.538 0.842968C10.6986 0.309058 8.7102 2.94833 7.89642 5.03735C4.11571 3.46746 0 5.5249 0 5.5249L12.4639 10.0497C11.8351 8.37025 10.7406 6.90484 9.30859 5.82531Z"
        fill="#3F3D56"
      />
    </svg>
  );
};

export const IceStone = ({ className }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_89_520)">
        <path
          d="M24.3408 12.1776L20.1405 21.1817L20.3758 27.9083L22.4545 30.0796L24.0734 30.2564L25.0251 25.6134L25.7115 25.0966L30.5781 25.5441L31.653 24.1589L30.913 20.9846L32.9777 24.4866L32.59 25.729L32.8872 25.7564L33.4011 26.091L34.6903 28.3445L36.9215 22.349L37.8833 22.0394L40.0992 23.5251L38.2024 20.0749L33.681 13.5364L24.341 12.1775L24.3408 12.1776ZM11.349 17.5431L10.5758 21.8917L10.9219 23.9394L12.497 22.741L13.4742 22.9865L13.4951 23.0291L16.8632 26.5757L17.6967 25.9356L18.5567 26.0086L19.0215 26.494L18.851 21.619L17.9798 19.302L15.2208 20.2009L12.6179 19.223L15.2083 19.1943L17.93 17.6614L20.0909 18.1705L21.6918 14.7386C21.4683 14.7197 21.2446 14.7039 21.0207 14.6913L15.8192 17.2569L15.5719 17.3215L11.3488 17.5431L11.349 17.5431ZM29.2763 14.2924L34.0752 17.8342L35.7152 22.1768L32.5571 18.5718L26.6432 15.8501L29.2763 14.2924ZM12.664 24.2883L8.76952 27.2516L9.50621 33.1596L13.1144 32.3683L12.9843 31.8528L14.6822 28.3964L12.664 24.2885L12.664 24.2883ZM18.0362 27.3549L15.831 29.0481L14.4044 31.9521L16.6882 33.5944L20.6513 32.3809L21.3933 30.8611L18.0362 27.3549ZM26.1853 26.4758L24.9144 32.6751L26.2795 36.6903L33.2253 35.2822L35.7844 32.8794L32.4508 27.052L26.1853 26.4758ZM37.8576 23.6041L35.9904 28.6212L36.0525 28.7391L40.8413 28.1968L42.2476 26.5475L37.8577 23.604L37.8576 23.6041ZM28.7717 27.9448L32.0019 28.6355L34.1315 31.882L32.8672 34.1817L29.7268 35.0512L31.6889 32.1685L30.92 29.3546L28.7717 27.9449L28.7717 27.9448ZM13.754 37.2813L13.8364 39.4886L15.8966 42.5894L21.8391 39.6315L21.2972 37.7447L16.2506 36.9551L13.7541 37.2814L13.754 37.2813Z"
          fill="url(#paint0_linear_89_520)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_89_520"
          x1="20.3224"
          y1="13.5463"
          x2="28.7264"
          y2="38.2194"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EDF8FF" />
          <stop offset="1" stop-color="#61B2E3" />
        </linearGradient>
        <clipPath id="clip0_89_520">
          <rect
            width="37"
            height="38"
            fill="white"
            transform="translate(0 11.9297) rotate(-18.8097)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ShipSail = ({ className }) => {
  return (
    <svg
      width="100%"
      height="100%"
      className={className}
      viewBox="0 0 192 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98.8205 58.4443C98.5838 58.4726 98.3506 58.5348 98.1236 58.6299C97.0156 59.1136 94.5289 60.413 96.537 61.62C96.537 61.62 101.117 64.2741 110.327 64.2852L129.441 65.3905C129.441 65.3905 134.642 64.8033 143.426 65.2576C143.426 65.2576 154.797 66.673 163.25 61.5738C163.25 61.5738 166.204 59.7267 168.002 59.4807C168.002 59.4807 174.535 57.0033 171.49 52.8673C170.552 52.0261 169.509 51.5271 168.447 51.4107C168.111 51.3826 167.777 51.2696 167.459 51.0762C165.292 49.7443 156.828 44.8674 151.522 46.3889C151.522 46.3889 147.687 47.1736 144.74 46.2338L140.455 45.5127C140.455 45.5127 135.858 44.116 131.115 46.3454C131.115 46.3454 123.339 49.1752 119.992 49.0951C119.992 49.0951 109.653 48.3151 106.668 52.8113C106.668 52.8113 104.209 55.2361 102.412 56.1662C101.904 56.4266 101.414 56.752 100.946 57.1389C100.278 57.6761 99.3543 58.3653 98.8205 58.4443Z"
        fill="url(#paint0_linear_174_18)"
      />
      <path
        d="M141.324 57.9567C141.07 57.9856 140.82 58.0473 140.577 58.1409C139.388 58.6166 136.719 59.8917 138.861 61.0503C138.861 61.0503 143.747 63.597 153.604 63.5526L174.057 64.5102C174.057 64.5102 179.627 63.9097 189.027 64.2977C189.027 64.2977 201.189 65.6023 210.267 60.6064C210.267 60.6064 213.44 58.7973 215.366 58.548C215.366 58.548 222.374 56.1062 219.14 52.1131C218.14 51.3029 217.027 50.8252 215.891 50.7187C215.531 50.6934 215.175 50.5858 214.836 50.4002C212.524 49.1214 203.494 44.4422 197.805 45.9495C197.805 45.9495 193.696 46.7336 190.547 45.8397L185.965 45.166C185.965 45.166 181.052 43.8389 175.963 46.0294C175.963 46.0294 167.623 48.8204 164.04 48.7628C164.04 48.7628 152.979 48.0682 149.757 52.4466C149.757 52.4466 147.111 54.813 145.181 55.7259C144.637 55.9814 144.11 56.2999 143.606 56.678C142.888 57.203 141.896 57.8769 141.324 57.9567Z"
        fill="url(#paint1_linear_174_18)"
      />
      <path
        d="M173.042 35.4409C175.854 36.9025 177.634 38.9476 177.774 41.2982C178.074 46.3621 170.653 50.9218 161.198 51.4826C157.087 51.7265 153.269 51.1736 150.241 50.0553C153.487 51.7424 158.107 52.6518 163.173 52.3513C172.629 51.7904 180.05 47.2307 179.75 42.1669C179.58 39.3047 176.978 36.8947 173.042 35.4409Z"
        fill="#F1F1F1"
        fill-opacity="0.41"
      />
      <path
        d="M179.289 38.5611C182.462 40.2103 184.472 42.518 184.629 45.1704C184.968 50.8844 176.594 56.0294 165.925 56.6623C161.286 56.9374 156.978 56.3136 153.562 55.0517C157.224 56.9554 162.437 57.9815 168.154 57.6425C178.823 57.0096 187.197 51.8645 186.858 46.1506C186.667 42.9209 183.731 40.2016 179.289 38.5611Z"
        fill="#F1F1F1"
        fill-opacity="0.41"
      />
      <path
        d="M170.821 37.1101C172.802 38.1399 174.056 39.5807 174.155 41.2368C174.366 44.8044 169.138 48.0168 162.476 48.4119C159.883 48.606 157.272 48.2659 154.757 47.4063C157.044 48.5949 160.299 49.2356 163.868 49.0239C170.53 48.6288 175.758 45.4163 175.547 41.8487C175.427 39.8322 173.594 38.1344 170.821 37.1101Z"
        fill="#F1F1F1"
        fill-opacity="0.41"
      />
      <path
        d="M136.626 36.0427C136.626 36.0427 138.2 50.77 158.201 49.5836C178.202 48.3972 183.297 33.2743 183.297 33.2743L136.626 36.0427Z"
        fill="#C0C3A7"
      />
      <path
        d="M165.138 8.08293C162.375 5.85694 159.019 4.97587 155.775 5.62424C158.748 6.29338 161.452 8.18671 163.41 10.9719C165.369 13.7572 166.457 17.2564 166.485 20.8554C166.513 24.4544 165.479 27.9233 163.563 30.6533C161.647 33.3833 158.973 35.1999 156.009 35.7844C158.916 36.4396 161.902 35.8844 164.465 34.2123C167.028 32.5402 169.012 29.853 170.083 26.6026C171.154 23.3522 171.248 19.7367 170.347 16.364C169.446 12.9913 167.607 10.0671 165.138 8.08293Z"
        fill="#C0C3A7"
      />
      <path
        d="M158.889 3.4341L157.651 3.50757L159.741 38.7502L160.98 38.6767L158.889 3.4341Z"
        fill="#C0C3A7"
      />
      <path
        d="M0 72.2058L192.794 52.8199L193.308 57.9274L193.278 81.8162L0.989934 82.0508L0 72.2058Z"
        fill="url(#paint2_linear_174_18)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_174_18"
          x1="107.324"
          y1="55.0714"
          x2="139.226"
          y2="56.9714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C6E1F2" />
          <stop offset="1" stop-color="#2E9DDC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_174_18"
          x1="159.395"
          y1="66.5148"
          x2="290.914"
          y2="58.7134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2D9CDB" />
          <stop offset="1" stop-color="#82A4E4" stop-opacity="0.79" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_174_18"
          x1="1.98164e-07"
          y1="77"
          x2="192"
          y2="68.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#61B2E3" />
          <stop offset="0.9375" stop-color="#2F9EDD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
