const ColMap = {
    5: [
        ["w1", "w2", "w3", "w4", "w5", "w6"],
        [ "w7", "w8", "w9", "w10", "w11", "w12"],
        ["w13", "w14", "w15", "w16", "w17", "w18"],
        ["w19", "w20", "w21", "w22", "w23", "w24"],
    ],
    6: [
        ["br", "fr", "pr"],
        ["gl", "pl", "sl"],
        ["bl", "cl", "fl"]
    ],
    8: [
        ["ai", "ay", "ae"],
        ["ey", "aw", "au"],
        ["oo", "oy", "oi"]
    ],
    10: [
        ["ail", "el", "ell"],
        ["ish", "ash", "esh"],
        ["eal", "ook", "ept"]
    ]
}
const SeqMap = {
    5: {
        "w1": "the",
        "w2": "was",
        "w3": "they",
        "w4": "when",
        "w5": "for",
        "w6": "some",
        "w7": "could",
        "w8": "picture",
        "w9": "country",
        "w10": "don't",
        "w11": "enough",
        "w12": "sometime",
        "w13": "very",
        "w14": "there",
        "w15": "do",
        "w16": "you",
        "w17": "first",
        "w18": "come",
        "w19": "our",
        "w20": "world",
        "w21": "head",
        "w22": "group",
        "w23": "movie",
        "w24": "young",

},
    6: {

    }
}

const TableMap = {
    6: [
        [["br", "fr", "pr"], ["cr", "gr", "tr"], ["dr", null, "wr"]],
        [["sc", "sm", "sp", "sk"], ["sn", "st", "sw", "str"]],
        [["bl", "cl", "fl"], ["gl", "pl", "sl"]]
    ]
}

const WordMap = {
    7:  ["tr-a-p", "fl-i-p", "sp-o-t", "pl-u-g"],
    9: ["p-ai-d", "sp-in", "sp-ou-t", "br-oi-l"],
    11: ["k-ept", "sh-ook", "sw-ell", "l-ight"],
    12: ["perform", "human", "complement"].map(dName=>`https://read21-assets.s3.amazonaws.com/assets/images/lesson/12/${dName}.png`),
    13: ["anti-", "il-", "mid-", "pre-"],
    14: ["anti-crime", "inter-lock", "semi-sweet"],
    15: ["-ing", "-ion", "-tion", "-less"],
    16: ["jump-ing", "act-ion", "bound-less"],
    17: ["busy", "four", "learn", "father"],
    18: ["k-ept", "sh-ook", "sw-ell", "l-ight"],
    20: [
        "The captain turned the ship.",
            "Of all the things in the world, she chose to be a scientist.",
            "This is really an important decision.",
            "To be successful, you must do all you can to make your talent grow.",
            "The creed of the school is written on the board."
    ],
    21: [
        "  The human body is a peculiar thing. It is made up of tiny structures called cells. Cells are the most basic structure of our bodies. All living things contain cells.\n They provide oxygen to our bodies, Vitamin D through sunlight, and nutrients from food."
    ]
}

export {
    ColMap, SeqMap, TableMap,WordMap
}