import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styles from '../styles/Critical.module.css'
import Loading from "../components/common/Loading";
import { activateCTStudent, initCT, pingCriticalThinking, resultCT, submitActivity } from "../resources/api";
import Day1 from "../components/days/Day1";
import Day2 from "../components/days/Day2";
import NextDay from "../components/days/NextDay";
import Day3 from "../components/days/Day3";
import Day4 from "../components/days/Day4";
import clsx from "clsx";
import { CorrectIcon, CTLessonIntro, WrongIcon } from "../resources/svgs";
import { formatTime } from "../utils/common";
import ProgressBar from "react-bootstrap/ProgressBar";
import useCountDown from "../utils/countdown";
import useStickyState from "../utils/stickyState";
import Button from "../components/common/Button";
import { useSoundManager } from "../utils/sound";
import FlipNumbers from "react-flip-numbers";
import wait, { delayAll } from "../utils/wait";
import UserContext from "../context/UserContext";
import Introduction from "../components/introduction/Introduction";
import CTGuidedPractice from "../components/common/CTGuidedPractice";
import SubmitContext from "../context/SubmitContext";
import { playSound } from "../utils/playsound";
import { InstructionMark, InstructionMarkDisabling } from "../utils/instruction";
import * as processingAnimation from "../lottie/processing.json";
import Lottie from "react-lottie";
import { useWindowSize } from "react-use";
import Modal from "../components/common/Modal";
import medal from "../lottie/medal.json"
import homebutton from "../lottie/homebutton.json"
import { NONE } from 'phaser';
import think from "../lottie/think.json"
import { getGradeLevel } from '../const/ct';
import { cn } from '../utils/utils';
import { getMsBg } from '../utils/style';
var proceduralInterval;


var retrivePausedFunction, pauseTimeOut;
function HeaderComponent({ loadedData, gradeLevel, noLessonId = false }) {
    return <div style={{
        position: "sticky",
        top: 0,
        zIndex: 1400
    }}
    >
        <div style={{
            background: getMsBg(gradeLevel),
            height: '110px',
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
        }}
        >
            {!noLessonId && <div style={{
                fontSize: '48px',
                fontWeight: "bold",
                color: '#ececec',
                flex: '0 0 200px',
                fontFamily: "League Spartan",
                marginRight: '20px',
                marginLeft: '20px',
                whiteSpace: "nowrap"
            }}>
                Lesson {
                    Math.ceil(loadedData?.itemNumber / 4)
                }
            </div>}
            <div style={{
                flex: '1',
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                gap: 5
            }}>

                <h1 className={styles.headerContainer}>
                    {
                        loadedData.dayType == "day1" && "Vocabulary Builder"
                    }
                    {
                        loadedData.dayType == "day2" && "Vocabulary Review"
                    }
                    {
                        loadedData.dayType == "day3" && "Critical Thinking & Comprehension"
                    }
                    {
                        loadedData.dayType == "day4" && "Closing Practice"
                    }
                </h1>
                <div style={{ alignSelf: "stretch", display: "flex" }}>
                    <ProgressBar className={styles.progressBar}
                        now={Number.parseInt(loadedData?.dayType?.replace("day", "")) * 25}
                        label={
                            <span style={{
                                fontSize: '14px',
                                fontWeight: "bold",
                                color: "#000"
                            }}>Activity {
                                    loadedData?.dayType?.replace("day", "")
                                }</span>} animated variant="warning" striped={true} style={{
                                    flex: '1',
                                    height: '25px',
                                    borderRadius: '20px',
                                    width: '50%'
                                }} />

                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src="/read21-wb.png"
                    style={{ width: '192px', height: '125px', marginTop: '10px', marginLeft: '15px' }} />
            </div>
        </div>

    </div>
}
function TimeOutPopup({ onClose }) {
    useEffect(() => {
        pauseTimeOut = setTimeout(() => {
            window.location.reload()
        }, 60 * 1000 * 3)
        return () => {
            clearTimeout(pauseTimeOut)
        }
    }, [])
    return <div className={styles.pausedPage}>
        <h1>Timed Out</h1>
        <Button noSoundCheck={true} processingLoader={false} onClick={async () => {
            clearTimeout(pauseTimeOut)
            onClose()
        }} icon={"nextarrow"} border={"white"} background={"green"} title={`Continue`} />
    </div>
}
export default function Critical() {
    //loading from server
    const [loadedData, setLoadedData] = useState(null)
    const { user, setUser } = useContext(UserContext)
    const [isCloseModeOpen, setIsCloseModalOpen] = useState(false)
    const [isTimeOutOpen, setIsTimeOutOpen] = useState(false)
    const { countDown, ...countDownDetails } = useCountDown(null, true)
    const [programIsDone, setIsProgramDone] = useState(false)

    const [currentStatus, setCurrentStatus, isStatusHiding] = useStickyState(null, 1000)
    const [titleShowing, setTitleShowing, isTitleHiding] = useStickyState(true, 2000)
    const [repeatCount, setRepeatCount] = useState(0)
    const qProps = useState(false)
    const [isQuestioning, setIsQuestioning] = qProps
    const [questionIndex, setQuestionIndex] = useState(0)
    const soundManage = useSoundManager()

    var [presentIntroduction, setPresentIntroduction] = useState(false);
    var [presentGuidedPractice, setPresentGuidedPractice] = useState(false);
    const gradeLevel = user?.grade

    useEffect(() => {
        if (presentIntroduction) {
            setUser({
                ...user,
                ctVideoPresented: true
            })
        }

        if (presentGuidedPractice) {
            setUser({
                ...user,
                guidedPracticePresented: true
            })
        }
    }, [presentIntroduction, presentGuidedPractice])


    const submitResult = useCallback((data, dontPlayStatus = false, callback) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { id } = loadedData;
                countDownDetails?.stop?.()
                setCurrentStatus("processing")
                const res = await submitActivity(id, {
                    ...data
                }, () => { }, {
                    soundManager,
                    dontPlayStatus
                });

                if (callback) {
                    await callback(res)
                }

                setCurrentStatus(res.isCorrect ? "correct" : "incorrect")
                resolve(res)
                await wait(1000)

                setCurrentStatus(null)



                setIsQuestioning(false)

                if (res.isEnd) {
                    onEnd()
                } else {
                    if (res.retry) {
                        setRepeatCount(res.triedAmount)
                        setIsQuestioning(true)
                    } else {
                        setRepeatCount(0)
                        setTimeout(() => {
                            setQuestionIndex(res.nextIndex)
                            setIsQuestioning(true)
                        }, 1000)
                    }

                }



            } catch (e) {
                reject(e)
            }

        });



    }, [loadedData]);

    const startActivity = useCallback(() => {
        initCT().then((data) => {
            if (data.programIsDone) {
                setIsProgramDone(true)
                return;
            }

            if (!user.ctVideoPresented) {
                setPresentIntroduction(true);
            } else if (!user.guidedPracticePresented) {
                setPresentGuidedPractice(true);
            }

            setTitleShowing(true)


            //x   setLoadedData(data)

            const { id, itemNumber, qIndex } = data;

            const dayIndex = (itemNumber - 1) % 4;
            const lessonId = Math.ceil((itemNumber) / 4);
            var dayType = null;
            if (dayIndex == 0) {
                dayType = "day1";
            } else if (dayIndex == 1) {
                dayType = "day2";
            } else if (dayIndex == 2) {
                dayType = "day3";
            } else {
                dayType = "day4";
            }
            setQuestionIndex(qIndex)
            setLoadedData({
                dayType,
                id,
                itemNumber,
                qIndex,
                lessonId
            })
        });
    }, [user]);

    useEffect(() => {
        var pingInterval = null;
        if (loadedData?.id) {
            pingInterval = setInterval(() => {
                pingCriticalThinking(loadedData.id)
            }, 5000)
        }
        return () => {
            clearInterval(pingInterval)
        }
    }, [loadedData?.id])
    useEffect(() => {
        startActivity()
    }, []);

    const onEnd = useCallback(() => {
        setLoadedData(null)
        resultCT().then((data) => {
            console.log
            //console.log(data);
            setLoadedData({
                dayType: "end",
                ...data
            })
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (countDownDetails?.state === "complete") {
                await wait(500)
                if (countDownDetails?.timerMeta) {
                    await countDownDetails?.timerMeta()
                } else {
                    // if (submitButton?.onClick) {
                    //     await submitButton?.onClick()
                    // }
                    setIsTimeOutOpen(true)
                    await playSound("click")
                    try {
                        const retrivePausage = await delayAll();
                        retrivePausedFunction = async () => {
                            await retrivePausage()
                            await submitButton?.onClick()

                        };
                    } catch (e) {
                        console.error(e)
                    }
                }


            }
        })().catch(alert)

    }, [countDownDetails?.state, submitResult])



    const pauseAndResume = useCallback(async () => {

        window.location.reload()
    }, []);
    const { submitButton, reference } = useContext(SubmitContext)


    const DayItem = useMemo(() => {
        if (!loadedData?.dayType) return null;
        if (loadedData.dayType == "day1") {
            return Day1
        } else if (loadedData.dayType == "day2") {
            return Day2
        } else if (loadedData.dayType == "day3") {
            return Day3
        } else if (loadedData.dayType == "day4") {
            return Day4
        }
    }, [loadedData?.dayType])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: medal,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const home = {
        loop: true,
        autoplay: true,
        animationData: homebutton,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const thinking = {
        loop: true,
        autoplay: true,
        animationData: think,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }


    if (programIsDone) return <div className={styles.container}>
        <div className={styles.mainContainer}>
            {
                /** Medal **/

                <Lottie
                    options={defaultOptions}
                    height={500}
                    width={500}
                />

            }
            {
                /** You've mastered **/
                <h2 className={styles.latoFont} style={{ marginTop: 30 }}>A Medal For You</h2>
            }
            {
                /** Line separator **/
                <h1 className={styles.alfaSlabFont}>
                    YOU'VE MASTERED <br /> YOUR CRITICAL THINKING SKILLS!
                </h1>
            }
            {
                <hr className={styles.customHr} />
            }
            {
                <hr className={styles.secondHr} />
            }
            {
                <button style={{ border: NONE, background: NONE, marginLeft: 600, marginTop: 30, position: "relative" }} className={styles.latoFont}
                    onClick={() => {
                        window.location.reload()
                    }
                    }>
                    <Lottie
                        options={home}
                        height={60}
                        width={60}
                    />
                    Back To Home
                </button>
            }
        </div>
    </div>
    if (!loadedData) return <div className={styles.container}>
        <Loading />
    </div>

    return <div className={styles.mainContainer}>
        {
            isTimeOutOpen && <TimeOutPopup onClose={() => {
                retrivePausedFunction?.()
                setIsTimeOutOpen(false)
            }} />
        }
        {
            isCloseModeOpen && <Modal close={() => {
                retrivePausedFunction?.()
                setIsCloseModalOpen(false)
            }}>
                <div style={{ display: "flex", padding: "20px 20px", borderRadius: 20, background: "#fff", flexDirection: "column", gap: 15 }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
                        <Button direction={"left"} icon={"home"} background={"blue"} title={"Home"} onClick={() => {
                            window.location.reload()
                        }
                        } />
                        <Button direction={"left"} icon={"logout"} background={"red"} title={"Logout"} onClick={() => {
                            localStorage.removeItem("token");
                            window.location.reload()
                        }
                        } />
                    </div>
                    <div style={{ alignSelf: "stretch", justifyContent: "stretch", alignItems: "stretch", display: "flex" }}>
                        <Button className={styles.stretchedButton} direction={"left"} icon={"nextarrow"} background={"green"} title={"Resume"} onClick={() => {
                            retrivePausedFunction?.()
                            setIsCloseModalOpen(false)
                        }
                        } />
                    </div>

                </div>

            </Modal>
        }
        {
            (presentIntroduction || presentGuidedPractice) ? <>
                {
                    presentIntroduction && <>
                        <Introduction isFluency={false} onNext={async () => {
                            await activateCTStudent();
                            setPresentIntroduction(false)
                            setPresentGuidedPractice(true)
                        }
                        } />
                    </>
                }
                {
                    presentGuidedPractice && <>
                        <CTGuidedPractice onEnd={() => {
                            setPresentGuidedPractice(false)
                        }
                        } />
                    </>
                }
            </> :
                <>
                    {
                        (loadedData.dayType != "end") && ((titleShowing && isTitleHiding) || (!titleShowing)) && <>


                            <HeaderComponent loadedData={loadedData} gradeLevel={gradeLevel} />
                        </>

                    }

                    {
                        titleShowing && <div className={clsx(styles.titlePage, isTitleHiding && styles.hiding)}>
                            <HeaderComponent loadedData={loadedData} noLessonId={true} gradeLevel={gradeLevel} />

                            <div className={styles.titleContentContainer}>
                                <div className={cn(styles.leftSide)} style={{
                                    background: getMsBg(gradeLevel)
                                }}>
                                    <CTLessonIntro />
                                    <h1 className={styles.title} style={{ color: "#fff", marginTop: 20, fontWeight: "bold" }}>
                                        Lesson {
                                            Math.ceil(loadedData?.itemNumber / 4)}
                                    </h1>
                                </div>
                                <div className={styles.rightSide}>
                                    <h1 style={{ fontSize: 48, textAlign: "center" }}>
                                        {
                                            loadedData.dayType == "day1" && "Vocabulary Builder"
                                        }
                                        {
                                            loadedData.dayType == "day2" && "Vocabulary Review"
                                        }
                                        {
                                            loadedData.dayType == "day3" && "Critical Thinking & Comprehension"
                                        }
                                        {
                                            loadedData.dayType == "day4" && "Closing Practice"
                                        }
                                    </h1>
                                    <Button title={"Next"} icon={"nextarrow"} onClick={() => {
                                        setTitleShowing(false)
                                    }} background={
                                        gradeLevel === "middle" ? "green" : "blue"

                                    } />
                                </div>
                            </div>


                        </div>
                    }
                    <div className={styles.indentContainer}>
                        <div className={styles.leftSider}>
                            {
                                ((titleShowing && isTitleHiding) || (!titleShowing)) && <>
                                    {
                                        loadedData.dayType != "end" ?
                                            <DayItem
                                                countDownDetails={{
                                                    ...countDownDetails,
                                                    countDown
                                                }}
                                                repeatCount={repeatCount}
                                                questionIndex={questionIndex}
                                                submitResult={submitResult}
                                                pauseAndResume={pauseAndResume}
                                                status={currentStatus}
                                                setStatus={setCurrentStatus}
                                                qProps={qProps}
                                                onEnd={onEnd} {...loadedData} /> : null
                                    }

                                    {
                                        loadedData.dayType == "end" ?
                                            <NextDay
                                                setIsProgramDone={setIsProgramDone}
                                                openNext={() => {
                                                    startActivity()
                                                }}
                                                restart={() => {
                                                    startActivity()
                                                }} {
                                                ...{
                                                    ...loadedData,
                                                }
                                                }
                                            /> : null
                                    }
                                </>
                            }
                        </div>
                        {
                            ((titleShowing && isTitleHiding) || (!titleShowing)) && <div className={styles.rightSider}>
                                <div className={styles.optionList}>
                                    <button onClick={async () => {
                                        setIsCloseModalOpen(true)
                                        await playSound("click")
                                        try {
                                            retrivePausedFunction = await delayAll();
                                        } catch (e) {
                                            console.error(e)
                                        }

                                    }} className={clsx(styles.homeButton)}>
                                        <svg height="27" viewBox="0 0 35 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.2991 8.21328C16.2991 7.44074 15.9922 6.69985 15.446 6.15358C14.8997 5.60732 14.1588 5.30043 13.3863 5.30043C12.6138 5.30043 11.8729 5.60732 11.3266 6.15358C10.7803 6.69985 10.4734 7.44074 10.4734 8.21328V22.7775L7.61884 19.9229C7.04703 19.3518 6.30077 18.9881 5.49859 18.8895C4.69642 18.791 3.88433 18.9634 3.19131 19.3792C2.54067 19.7693 2.06788 20.3978 1.8735 21.1311C1.67912 21.8644 1.77848 22.6446 2.15045 23.3057C5.79928 29.7917 7.92566 33.4988 8.53153 34.4289C8.65791 34.6235 8.78478 34.8176 8.91215 35.0115C9.97343 36.623 11.4183 37.9458 13.1171 38.8609C14.8159 39.7761 16.7154 40.255 18.645 40.2546H18.241H22.1248C25.215 40.2546 28.1786 39.0271 30.3636 36.842C32.5487 34.657 33.7762 31.6934 33.7762 28.6032V12.0971C33.7762 11.3245 33.4694 10.5836 32.9231 10.0374C32.3768 9.49112 31.6359 9.18423 30.8634 9.18423C30.0909 9.18423 29.35 9.49112 28.8037 10.0374C28.2574 10.5836 27.9505 11.3245 27.9505 12.0971M16.2991 8.21328V20.8356M16.2991 8.21328V4.32948C16.2991 3.94696 16.3745 3.56818 16.5209 3.21478C16.6672 2.86137 16.8818 2.54026 17.1523 2.26978C17.4228 1.9993 17.7439 1.78474 18.0973 1.63835C18.4507 1.49197 18.8295 1.41663 19.212 1.41663C19.5945 1.41663 19.9733 1.49197 20.3267 1.63835C20.6801 1.78474 21.0012 1.9993 21.2717 2.26978C21.5422 2.54026 21.7567 2.86137 21.9031 3.21478C22.0495 3.56818 22.1248 3.94696 22.1248 4.32948V20.8356M22.1248 8.21328C22.1248 7.44074 22.4317 6.69985 22.978 6.15358C23.5243 5.60732 24.2652 5.30043 25.0377 5.30043C25.8102 5.30043 26.5511 5.60732 27.0974 6.15358C27.6437 6.69985 27.9505 7.44074 27.9505 8.21328V20.8356" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>



                                    </button>
                                    <InstructionMarkDisabling />
                                </div>
                                <div className={styles.timerAndStatus}>
                                    {
                                        (submitButton?.bType === "record") ? <>
                                            {/*<RecordButton {...submitButton}  />*/}
                                        </>
                                            : <>
                                                {
                                                    (currentStatus) && <div className={clsx(
                                                        styles.statusContainer,
                                                        styles[currentStatus ? currentStatus : "countDown"],
                                                        isStatusHiding && styles.hiding
                                                    )}>
                                                        <div style={{ width: 80, height: 80 }}>
                                                            {
                                                                currentStatus === "correct" && <CorrectIcon />
                                                            }
                                                            {currentStatus == "incorrect" && <WrongIcon />}
                                                            {currentStatus == "processing" && <Lottie
                                                                loop={true}
                                                                options={{
                                                                    loop: true,
                                                                    autoplay: true,
                                                                    animationData: processingAnimation,
                                                                }}
                                                                width={80}
                                                                height={80}
                                                            />}
                                                        </div>


                                                    </div>
                                                }

                                            </>
                                    }
                                    {
                                        (countDown) ? <div className={clsx(styles.timerContainer, countDown < 2 && styles.hiding
                                        )} style={{
                                            background: getMsBg(gradeLevel)
                                        }}>
                                            <h1 style={{ margin: 0, padding: 0, lineHeight: 1 }}>
                                                <FlipNumbers height={35} width={24} numberStyles={styles.numberStyles} color="white"
                                                    play
                                                    numbers={(countDown > 60) ? formatTime(countDown) : countDown.toString()} />
                                            </h1>
                                        </div> : <></>
                                    }
                                </div>

                                <div className={styles.submitContainer}>
                                    {
                                        (submitButton) && ((submitButton?.bType != "record"))
                                        && <Button {...submitButton} isDisabled={submitButton?.isDisabled || (currentStatus != null)} background={
                                            gradeLevel === "middle" ? "green" : "blue"
                                        } />
                                    }

                                </div>
                            </div>
                        }

                    </div>

                </>
        }
    </div>
}
