import clsx from "clsx";
import svgs, { CorrectIcon, WrongIcon } from "../../resources/svgs";
import styles from "./Button.module.css";
import { playSound } from "../../utils/playsound";
import { useContext, useEffect, useState } from "react";
import StatusContext from "../../context/StatusContext";
import * as animationData from "../../lottie/processing.json";
import Lottie from "react-lottie";

export function SlottedButton(params) {
    const { setShownButton } = useContext(StatusContext)
    useEffect(() => {
        if (!params.isDisabled) {
            soundManager.playImmediate("twinkle")
            setShownButton(params)
        }
    }, [params.isDisabled])
    useEffect(() => {
        setShownButton(params)
        return () => {
            setShownButton(null)
        }
    }, [])
    return <></>
}
export default function Button({ title, fillWidth, withoutSound, noSoundCheck = false, processingLoader = true, isDisabled: _isDisabled, onClick, type, icon: iconKey, contentSize = "large", click = "skewed", direction = "right", size, paddingV = "full", paddingH = "full", className, background = "blue", border = "black", rounding = "full", animation = "none" }) {
    const Icon = svgs[iconKey];
    const [isSubmitting, setIsSubmitting] = useState()
    const isLoading = (isSubmitting) && processingLoader
    const { buttonDisabled } = useContext(StatusContext)
    const isDisabled = _isDisabled || isLoading || (!noSoundCheck && buttonDisabled);

    const RightAdaptedIcon = ({ }) => (iconKey && direction == "right") ? (<Icon stroke={"currentcolor"} height={contentSize == "large" ? 30 : contentSize == "medium" ? 20 : 15} />) : <></>;
    const LeftAdaptedIcon = ({ }) => (iconKey && direction == "left") ? (<Icon stroke={"currentcolor"} height={contentSize == "large" ? 30 : contentSize == "medium" ? 20 : 15} />) : <></>;
    return <button type={type} onClick={
        (e) => {
            (async () => {
                if (!isDisabled) {
                    setIsSubmitting(true)
                    !withoutSound && playSound('click', 0.9);
                    setTimeout(async () => {
                        try {

                            if (onClick) {
                                await onClick();
                            }

                            setIsSubmitting(false)
                        } catch (e) {
                            console.error(e)
                            window.switchToErrorPage(e)
                        }
                    }, 500)
                }
            })().catch(alert)
        }} className={clsx(styles.button, fillWidth && styles.fillWidth, className, isLoading && styles.loading, isDisabled ? styles.gray : styles[background], styles[`border_${border}`], styles[`rounding_${rounding}`], styles[`animation_${animation}`], styles[`click_${click}`], styles[`ph_${paddingH}`], styles[`pv_${paddingV}`], styles[`content_${contentSize}`])}><LeftAdaptedIcon />{title?.length > 0 && <span>{title}</span>}<RightAdaptedIcon />
        {
            isLoading && <div className={styles.inButtonLoadingContainer}>
                {
                    (isSubmitting) && <Lottie
                        loop={true}
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid",
                            },
                        }}
                        width={100}
                        height={20}
                        z
                    />
                }
            </div>
        }
    </button>
}