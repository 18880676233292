import ReadItOut from "../minified/ReadItOut";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { initVoice } from "../../resources/api";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import wait from "../../utils/wait";
import { waitForVoice } from "../../utils/audios";
import { useSoundManager } from "../../utils/sound";
import DisplayItems from "../minified/DisplayItems";

function matchStrings(a, b, handleEndings = true) {
    // initialize an empty object to store the results
    let result = {};
    // initialize two pointers to track the indices of a and b
    let i = 0;
    let j = 0
    // loop through the list a until the end
    var lastRead = null;
    while ((i < b.length) && (i < a.length)) {
        lastRead = `${a[i]}_${i}`.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase();
        result[`${a[i]}${i}`.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase()] = "read";
        i++;
    }
    return [result, lastRead]
}
async function getMicClip({ microPhoneConfig: _micConfig, setStatus, setHasRecorded, audioStreamBegan, closeStream }) {
    const microPhoneConfig = { waitTime: 6000, timeOut: 2000, wrLength: 3, type: "norepeat", ...(_micConfig || {}) }
    return await waitForVoice(microPhoneConfig.wrLength, 1, microPhoneConfig.type, microPhoneConfig.waitTime, {
        speechTimeOut: microPhoneConfig.timeOut,
        listening() {
            setStatus("recording")
            setHasRecorded && setHasRecorded(true)
        },
        empty() {
            setStatus("recording")
        },
        audioStreamBegan,
        closeStream
    });
}
export default function CTDisplay({ status: _resultStatus, isQuestionDisabled, soundManager, questionIndex, itemDetails, questions, audioBlob, clickHandler, s3FieldsRef }) {
    const [status, setStatus] = useState(null)

    useEffect(() => {
        setStatus(_resultStatus)
    }, [
        _resultStatus
    ])
    const silences = useRef()
    const microphoneOperation = useCallback(async () => {




        const blob = await getMicClip(
            {
                microPhoneConfig: { waitTime: 6000, timeOut: 4000 },
                setStatus,
                setHasRecorded() { },
                audioStreamBegan(stream, endTheStream) {

                },
                async closeStream() {

                }
            });

        if (window.clearCurrentCountDown) {
            window.clearCurrentCountDown()
        }
        setStatus("processing")
        soundManager.playSound("processing", "processing", true, 0.06);
        s3FieldsRef.current = await initVoice(2, questionIndex)

        await wait(1)

        if (blob) {
            if (blob == "done") {
                setStatus("started")

            } else {
                audioBlob.current = blob;


                clickHandler(audioBlob)
            }
        }
        else {
            setStatus(null)

            if (silences.current < 1) {

                silences.current += 1
                setStatus("started")
            } else if (silences.current == 1) {

                silences.current += 1
                setStatus("started")

            } else {
                window.location.reload()
                // audioBlob.current = blob;
                //
            }

        }
    }, [questionIndex]);
    const registerMicrophone = useCallback(() => {
        return {
            micEnabled: !isQuestionDisabled,
            onClick: microphoneOperation,
            status
        }
    }, [status, microphoneOperation, isQuestionDisabled])
    return <DisplayItems stageDetails={{ micEnabled: true }} status={status} current={questionIndex} data={questions} controls={{
        initiateQuestions() {
        },
        registerMicrophone
    }} />
}