import {  } from '../actions/data'

const initialState = {
}

const dataState = (state = initialState, action) => {
    switch (action.type) {

        default:
            return state
    }
}

export default dataState
