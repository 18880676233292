
export const trainingLessons = {
    lesson3: {
        "data": {
            "A1": {
                "group": "p1",
                "type": "word",
                "text": "at"
            },
            "A2": {
                "group": "p1",
                "type": "word",
                "text": "about"
            },
            "A3": {
                "group": "p1",
                "type": "word",
                "text": "lake"
            },
            "L1": {
                "group": "gp1",
                "type": "word",
                "imgs": [
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/a1.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/a2.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/a3.svg"
                ]
            },
            "L2": {
                "group": "gp1",
                "type": "word",
                "imgs": [
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/e1.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/e2.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/e3.svg"
                ]
            },
            "L3": {
                "group": "gp1",
                "type": "word",
                "imgs": [
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/i1.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/i2.svg"
                ]
            },
            "L4": {
                "group": "gp1",
                "type": "word",
                "imgs": [
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/o1.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/o2.svg"
                ]
            },
            "L5": {
                "group": "gp1",
                "type": "word",
                "imgs": [
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/u1.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/u2.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/u3.svg"
                ]
            },
            "L6": {
                "group": "gp1",
                "type": "word",
                "imgs": [
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/y1.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/y2.svg",
                    "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/y3.svg"
                ]
            },
            "P1": {
                "group": "gp2",
                "type": "word",
                "sequential": [
                    1,
                    5
                ],
                "playBefore": [
                    {
                        "afterDelay": 1000,
                        "audio": "prompt/n3"
                    },
                    {
                        "afterDelay": 1000,
                        "audio": "prompt/n3_1"
                    }
                ],
                "text": "aeiou",
                "markers": {
                    "a": "0_0_ae",
                    "e": "0_1_eh",
                    "i": "0_2_ih",
                    "o": "0_3_aa",
                    "u": "0_4_ah"
                }
            },
            "T1": {
                "group": "p2",
                "type": "word",
                "text": "ae",
                "markers": {
                    "a": "0_0_ae",
                    "e": "0_1_eh"
                },
                "reminder": "RA-a-e"
            },
            "T3": {
                "group": "p2",
                "type": "word",
                "text": "ou",
                "markers": {
                    "i": "0_0_aa",
                    "o": "0_1_ah"
                }
            },
            "T5": {
                "group": "p2",
                "type": "word",
                "text": "ia",
                "markers": {
                    "a": "0_0_ih",
                    "i": "0_1_ae"
                }
            }
        },
        "audio": "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/answers/{{key}}.mp3"
    },
    lesson4: {
        "data": {
            "T1": {
                "text": "tap",
                "group": "p2",
                "type": "word"
            },
            "T2": {
                "text": "led",
                "group": "p2",
                "type": "word"
            },
            "T3": {
                "text": "bit",
                "group": "p2",
                "type": "word"
            },
            "WR1": {
                "text": "h",
                "group": "p3",
                "type": "text",
                "playBefore": [
                    {
                        "audio": "prompt/p3_1"
                    }
                ]
            },
            "WR2": {
                "text": "m",
                "group": "p3",
                "type": "text"
            }
        },
        "audio": "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/answers/{{key}}.mp3"
    }
}