import {randomItemFromList} from "./common";
import {useEffect, useState} from "react";
import {getAudioList} from "./audios";

export default class SoundManager {
    constructor(){
        /**
         *
         * @type {{[key: string]: {context: AudioContext, playing: {[key: string]: {id: string,progress: number, source: AudioBufferSourceNode, http:XMLHttpRequest, reject, resolve, callbacks}}}}}
         */
        this.channels = {}
        this.audioRefs = {}
    }
    listener = null
    sequential_cache = {}
    _createOrUpdateChannel(channelId){
        var exists = Object.keys(this.channels).filter(v=>v===channelId)?.length > 0
        if(!exists){
            this.channels[channelId] = {
                context: new (window.AudioContext || window.webkitAudioContext)(),
                playing: {}
            }
        }
    }

    registerListener(cb) {
        this.listener = cb
    }


    updateAudioRefs(update){
        this.audioRefs = update
    }

    playSoundGeneric(channelId, key, looped=false, volume=null){
        this._createOrUpdateChannel(channelId)

        return new Promise((_resolve, _reject)=>{
            const resolve = ()=>{
                try {
                    this.channels[channelId].playing[key].callbacks.forEach((results)=>{
                        results[0]();
                    })
                    this.listener?.(channelId, "complete", key)
                }catch (e) {

                }

                try {
                    delete this.channels[channelId].playing[key]

                }catch (e) {

                }



                _resolve()
            }
            const reject = ()=>{
                try {
                    this.channels[channelId].playing[key].callbacks.forEach((results)=>{
                        results[1]();
                    })
                }catch (e) {

                }
                this.listener?.(channelId, "complete", key)
                delete this.channels[channelId].playing[key]
                _reject()
            }
            (async()=>{

                try {
                    const audioURL = this.audioRefs[key]
                    const {context: audioContext, paused, playing} = this.channels[channelId]

                    if(Object.keys(playing).filter(v=>v===key)?.length > 0){
                        this.channels[channelId].playing[key].callbacks.push([_resolve, _reject]);
                        return
                    }
                    if(paused){
                        this.listener?.(channelId, "complete", key)
                        _reject()
                        return
                    }

                    /**
                     * @type {AudioBufferSourceNode}
                     */

                    var source = audioContext.createBufferSource();
                    var connection = audioContext.destination
                    if(typeof volume == "number"){
                        const gainNode = audioContext.createGain();
                        gainNode.gain.value = volume; // setting it to 10%
                        gainNode.connect(audioContext.destination);
                        connection = gainNode;
                    }

                    source.addEventListener('ended', () => {
                        source.disconnect()
                        source.stop();
                        resolve()
                    });

                    const request = new XMLHttpRequest();

                    request.open('GET', audioURL, true);
                    request.responseType = 'arraybuffer';
                    request.onload = () => {
                        audioContext.decodeAudioData(
                            request.response,
                            (buffer) => {
                                source.buffer = buffer;
                                source.connect(connection);
                                source.start(0);
                                if(looped){
                                    source.loop = true
                                }
                            },
                            (e) => {
                                reject(e)
                            });
                    }

                    request.send();

                    this.channels[channelId].playing[key] = {
                        reject, resolve, callbacks: [], http: request, source: source
                    }
                    this.listener?.(channelId, "playing", key)
                } catch (e) {
                    console.error(e)
                    reject(e)
                }
            })().catch(e=>{
                console.error(e)
                reject(e)
            })

        })
    }

    playImmediate(key,looped, volume=null){
        return this.playSoundGeneric("default", key, looped, volume)
    }
    async playURL(url, channel="default",looped, volume=null){
        console.log("Trying, ", url, " on channel:", channel)
        var nKey = url.split("/").pop().split(".")[0]
        this.audioRefs[nKey] = url
        //stop all sounds playing on same channel
        if(channel=="default"){
            await this.cleanAllChannel(true)
        }else{
            await this.cleanChannel(channel, true)

        }
        // alert([nKey, url])
        if(channel != "default"){
           await this.cleanChannel("default")
        }

        var johnTalking = await this.playSoundGeneric(channel, nKey, looped, volume)

        return johnTalking
    }
    playFromAny(key, channelId="default", looped=false, volume=null) {
        var madeURL = "https://read21-assets.s3.us-east-1.amazonaws.com/ct/any/"+key;
        return this.playURL(madeURL, channelId, looped, volume)
    }
    playFrom(channelId, configuration){
        if(configuration == "string"){
            return this.playSound(channelId, configuration)
        }else{
            var selected = null;
            if(configuration.flow == "random"){
                selected = `${configuration.base}_${randomItemFromList(configuration.from)}`
            }
            return this.playSound(channelId, selected)
        }
    }
    playSequential(channelId, key, [start, stop]) {
        console.log("Trying, ", key, " on channel:", channelId)
        var seqCId = this.sequential_cache[key] || start
        if((this.sequential_cache[key] + 1) < stop){
            this.sequential_cache[key] += 1
        }else{
            delete this.sequential_cache[key];
        }

        return this.playSoundGeneric(channelId, `${key}/${seqCId}`)
    }
    playSound(channelId, key, looped=false, volume=null) {
        console.log("Trying, ", key, " on channel:", channelId)
        return this.playSoundGeneric(channelId, key, looped, volume)
    }

    async cleanChannel(channelId, resolveOnComplete=false) {
        if(this.channels[channelId]) {
            if(resolveOnComplete){
                var playingKeys = Object.keys(this.channels[channelId].playing)

                for (let i = 0; i < playingKeys.length; i++) {
                    var playElement = this.channels[channelId].playing[playingKeys[i]];
                    playElement.source.disconnect()
                    try {
                        playElement.http.abort()

                    }catch (e) {

                    }
                    try {
                        playElement.reject()
                    }catch (e) {

                    }
                }

            }

            try {
                await this.channels[channelId].context.close()
            }catch (e) {

            }
            this.listener?.(channelId, "complete", "*")
            delete this.channels[channelId];
        }
    }
    async cleanAllChannel(resolveOnComplete=false) {
        return await Promise.all(Object.keys(this.channels).map(cK=>this.cleanChannel(cK, resolveOnComplete)))

    }


    async continueChannel(channelId) {
        if(this.channels[channelId]){
            this.channels[channelId].paused= false;
            return await this.channels[channelId].context.resume()
        }

    }

    async pauseChannel(channelId) {
        if(this.channels[channelId]) {
            this.channels[channelId].paused = true;
            return await this.channels[channelId].context.suspend()
        }
    }

    async pauseAllChannels(){
        return await Promise.all(Object.values(this.channels).map(chnl=>chnl.context.suspend()))
    }
    async resumeAllChannels(){
        return await Promise.all(Object.values(this.channels).map(chnl=>chnl.context.resume()))
    }


}


// useSoundManager, returns a soundManager instance that's cleaned when unmounted
export function useSoundManager(_soundManager=window.soundManager) {
    const [soundManager] = _soundManager ? [_soundManager] : useState(()=>{
        var sm = new SoundManager();
        sm.updateAudioRefs(getAudioList())
        return sm
    })
    useEffect(() => {
        return () => {
            soundManager.cleanAllChannel()
        }
    }, [])
    return soundManager
}

