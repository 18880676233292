import { useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "../../lottie/loading.json";
export default function Loading() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch",
        flex: 1
      }}
    >
      <Lottie
        loop={true}
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width={80}
        height={80}
      />
    </div>
  );
}
