import { holdFlow } from "./wait";

export function playSound(id, volume, looped = false, dontPause = false) {
    console.log("Trying, ", id)
    return new Promise((resolve, reject) => {

        holdFlow(dontPause).then(_ => {
            window.soundManager.playImmediate(id, false, volume || 1).then(resolve).catch(reject)
        });

    })
}



export async function resumeSound(id) {
    return new Promise((resolve, reject) => {
        holdFlow().then(_ => {
            window.resumeSoundFn(id, resolve, reject)
        })

    })
}