import moment from "moment"
import {useContext, useEffect, useMemo} from "react"
import UserContext from "../../context/UserContext"
import { CloseIcon, CalendarIcon } from "../../resources/svgs"
import Button from "./Button"
import styles from "./Profile.module.css";
import { LessonTitles } from '../../const/lessonTitles'
import {playSound} from "../../utils/playsound";

export default function ProfileModal({removeComponent, playLatest}) {
    const {user} = useContext(UserContext);
    useEffect(()=>{
        window.gtag("event", "page_view", {
            page_title: "Profile Open"
        });
    }, [])
    const lessonObject = useMemo(()=>{
        if(!user){
            return null
        }
        const progresses = [...user.progresses];

        var currentLesson = Array.from(new Array(21)).map((_,i)=>{
            var prgItem = progresses
                .find((a)=>
                    a.lessonId == i)
            console.log(i, "prgItem:", prgItem)
            return {
                lessonId: i,
                isLocked: (prgItem && ((prgItem?.isUnlocked === false) || (prgItem?.hasPassed) ))
            }
        })
            .filter(a=>!a.isLocked)
            .map(a=>a.lessonId)
            //     ||
            //     ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
            // )
            // .map((a, i)=>{
            //     return ((a.lessonId>0) && (a.lessonId<=21) && (progresses[i-1]?.lessonId != a.lessonId-1))
            //         ? (a.lessonId - 1) : a.lessonId
            // })
            .sort((a, b) => a - b)
            ?.[0];
        currentLesson = (currentLesson >= 1) ? currentLesson : 1;
        return {id: currentLesson, title: LessonTitles [currentLesson].title}
    }, [user])
    return <div className={styles.background}>
        <div className={styles.modal}>
            <img className={styles.profileImage} src={user?.photoURL||"/profile.png"}/>
            <span className={styles.closeIcon} onClick={()=>{
                   playSound('click')
                    removeComponent();
                }}>
                <CloseIcon width={25} height={25} />
            </span>
            <h1 >{user.firstName} {user.lastName}</h1>
            <div className={styles.joinDateContainer}>
                <CalendarIcon width={30} height={30}/>
                <div  className={styles.labelSeparator}>
                    <h1>{moment(user.created_at).format("MMM DD, YYYY")}</h1>
                    <h2>Joined on</h2>
                </div>
            </div>
            {
                lessonObject &&  <Button className={styles.lessonButton} onClick={()=>{
                    playLatest();
                }}  icon={"right"} border={"black"} background={"green"}  title={`L${lessonObject.id} (${lessonObject.title})`} />
            }
           
            <Button textAlign={"center"} className={styles.logoutButton} onClick={()=>{

                localStorage.removeItem("token");
                window.location.reload()
            }} direction={"left"} background={"red"} title={"Logout"} icon={"logout"}/>
            </div>
    </div>
}