import styles from "./Intros.module.css";
import {useMemo} from "react";
import {WordMap} from "../../const/tables";




export default function WordList({mapKey, sentence}){
    const words = useMemo(()=>WordMap[mapKey], [mapKey])
    return <div className={styles.wordList}>
        {
            words.map((word, i)=>{
                return <div style={{'--delay': i+1}} className={sentence ? styles.sentence : styles.word}>{word}</div>
            })
        }
    </div>
}