export const Checkpoints = {
    default: {
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/chimes/{{key}}.mp3",
                data: ["bell", "drag", "drop", "click", "coin", "correct", "failure", "notifications", "processing", "recording", "success", "wrong", "twinkle"],
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/repeatables/{{key}}.mp3",
                prefix: "repeatables/",
                data: ["mic-p", "L1", "L2", "L3-GP1", "L3-GP2", "L3-P1", "L3-P2", "L4-P1", "L4-P2", "L4-P3", "L5-P1", "L6-CB", "L6-GP", "L7-P1", "L7-P2", "L7-P3", "L8-P1", "L8-VC", "L9-P1", "L9-P2", "L9-P3", "L10-P1", "L10-VC", "L11-P1", "L11-P2", "L11-P3", "L12-P1", "L12-P2", "L13-P1", "L13-P2", "L14-P1", "L14-P2", "L14-P3", "L15-P1", "L15-P2", "L16-P1", "L16-P2", "L16-P3", "L17-P1", "L17-P2", "L18-GP", "L18-P1", "L19", "L20", "L21"]
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/endings/{{key}}.mp3",
                prefix: "endings/",
                data: ["avfail", "l1fail", "l1pass", "l2fail", "l2pass", "l3fail", "l3pass", "l4fail", "l4pass", "l5fail", "l5pass", "l6fail", "l6pass", "l7fail", "l7pass", "l8fail", "l8pass", "l9fail", "l9pass", "l10fail", "l10pass", "l11fail", "l11pass", "l12fail", "l12pass", "l13fail", "l13pass", "l14fail", "l14pass", "l15fail", "l15pass", "l16fail", "l16pass", "l17fail", "l17pass", "l18fail", "l18pass", "l19fail", "l19pass", "l20fail", "l20pass", "l21"]
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/review/{{key}}.mp3",
                prefix: "review/",
                data: ["review", "review2", "reviewlesson"]
            },
            {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/misc/{{key}}.mp3",
                data: ["ambience", "enticing", "plustrain"],
            },

            ],
            wrong: {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/cpsecond.mp3",
                data: [1, 2, 3, 4],
                flow: "random"
            },
            empty: {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/empty/{{key}}.mp3",
                data: [1, 2, 3, 4],
                flow: "random"
            },
            correct: {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/correct/{{key}}.mp3",
                data: [1, 2, 3, 4, 5, 6, 7],
                flow: "random"
            },
            introduction: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/introduction.mp3",
            letsbegin: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/letsbegin.wav",
            tooloud: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/tooloud.mp3",

        }
    },
    0: {
        stages: {
            g1: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                wrongPrompt: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/cpsecond.mp3",
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },

                title: "Grade 1",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 1",
                }
            },
            g2: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 2",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 2",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/2fr.mp3"
                }
            },
            g3: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 3",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 3",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/3fr.mp3"
                }
            },
            g4: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 4",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 4",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/4fr.mp3"
                }
            },
            g5: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 5",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 5",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/5fr.mp3"
                }
            },
            g6: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 6",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 6",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/6fr.mp3"
                }
            },
            g7: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 7",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 7",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/7fr.mp3"
                }
            },
            g8: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 8",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint",
                    subTitle: "Grade 8",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/8fr.mp3"
                }
            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["l3-1", "l3-2", "l3f-1", "l3f-2", "l4-1", "l4-2", "l4f-1", "l4f-2", "l5-1", "l5-2", "l5f-1", "l5f-2", "l6-1", "l6-2", "l6f-1", "l6f-2", "l7f-1", "l8f-1"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["prep"]
        },
        data: "C0"
    },
    1: {
        stages: {
            g1: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 1",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 1",
                    // audio: "prompt/13-1"
                }
            },
            g2: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 2",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 2",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/2fr.mp3"
                }
            },
            g3: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 3",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 3",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/3fr.mp3"
                }
            },
            g4: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 4",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 4",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/4fr.mp3"
                }
            },
            g5: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 5",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 5",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/5fr.mp3"
                }
            },
            g6: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 6",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 6",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/6fr.mp3"
                }
            },
            g7: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 7",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 7",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/7fr.mp3"
                }
            },
            g8: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 8",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 2",
                    subTitle: "Grade 8",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/8fr.mp3"
                }
            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["l3-1", "l3-2", "l3f-1", "l3f-2", "l4-1", "l4-2", "l4f-1", "l4f-2", "l5-1", "l5-2", "l5f-1", "l5f-2", "l6-1", "l6-2", "l6f-1", "l6f-2", "l7f-1", "l8f-1"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["prep"]
        },
        data: "C1"
    },
    2: {
        stages: {
            g1: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 1",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 1",
                    // audio: "prompt/13-1"
                }
            },
            g2: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 2",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 2",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/2fr.mp3"
                }
            },
            g3: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 3",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 3",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/3fr.mp3"
                }
            },
            g4: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 4",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 4",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/4fr.mp3"
                }
            },
            g5: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 5",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 5",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/5fr.mp3"
                }
            },
            g6: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 6",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 6",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/6fr.mp3"
                }
            },
            g7: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 7",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 7",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/7fr.mp3"
                }
            },
            g8: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 60,
                microPhoneConfig: {
                    waitTime: ((60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Grade 8",
                instruction: "repeatables/L21",
                introduction: {
                    title: "Checkpoint 3",
                    subTitle: "Grade 8",
                    audioURL: "https://d3vx0tne0vxeo1.cloudfront.net/audios/checkpoint/8fr.mp3"
                }
            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["l3-1", "l3-2", "l3f-1", "l3f-2", "l4-1", "l4-2", "l4f-1", "l4f-2", "l5-1", "l5-2", "l5f-1", "l5f-2", "l6-1", "l6-2", "l6f-1", "l6f-2", "l7f-1", "l8f-1"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["prep"]
        },
        data: "C2"
    }
}