import styles from "./LessonIntroduction.module.css"
import {lazy, useEffect, useMemo, useState} from "react";
import {LessonTitles, ModuleTitles} from "../../const/lessonTitles";
import {playSound} from "../../utils/playsound";
import clsx from "clsx";
import wait from "../../utils/wait";
import FloatingObject from "./FloatingObjects";

/**
 *
 * @param lessonId
 * @param onNext
 * @param trialId
 * @param  soundManager {SoundManager}
 * @returns {JSX.Element}
 * @constructor
 */
export default function LessonIntroduction({lessonId, onNext, trialId, soundManager}){
    const [page, setPage] = useState("introduction");
    const lTitleInfo = useMemo(()=>LessonTitles[lessonId],[lessonId])
    const lModuleInfo = useMemo(()=>ModuleTitles[lTitleInfo.module],[lTitleInfo])

    const ContentInfo = useMemo(()=>require(`../../lessons/intros/${(lTitleInfo.content || "Alphabet")}.js`).default,[lTitleInfo])
    const ExampleComponent = useMemo(()=>lTitleInfo.exampleContent && require(`../../lessons/intros/${(lTitleInfo.exampleContent)}.js`).default,[lTitleInfo])
    useEffect(()=>{
        (async ()=>{
            await soundManager.playSound("instruction", "introduction")

            if(lTitleInfo.exampleIncluded && !lTitleInfo.withExampleAudio && !lTitleInfo.waitForExample){
                setPage("example")
            }
            await soundManager.playSound("instruction", "explanation")

            if(lTitleInfo.exampleIncluded && (lTitleInfo.withExampleAudio || lTitleInfo.waitForExample)){
                await wait(1000)
                setPage("example")
            }
            if(lTitleInfo.withExampleAudio){
                await soundManager.playSound("instruction", "example")
            }
            if(!lTitleInfo.waitForExample) {
                await wait(2000)
                onNext();
            }

        })().catch(alert)
    }, [])

    return <section className={clsx(styles.lessonTitlePage,  ContentInfo && styles.withContent, (page==="example") && styles.examplePresentation)}>

        {
            (page==="example") ? <ExampleComponent trialId={trialId} onNext={()=>onNext()} {...(lTitleInfo.exampleProps || {})}/> : <>
                <div className={styles.titleInfo}>
                    <img src="/read21.png" className={styles.read21Logo}/>
                    <h1 className={styles.lessonTitle}>Lesson {lessonId}</h1>
                    <h2 className={styles.lessonSubTitle} dangerouslySetInnerHTML={{__html: lTitleInfo.title}}></h2>
                </div>

                <div className={styles.headingInfo}>
                    <span><b>Module {lTitleInfo.module}</b> <span dangerouslySetInnerHTML={{
                        __html:lModuleInfo.title
                    }}/></span>
                </div>

                {ContentInfo ? <div className={styles.contentInfo}>
                    <ContentInfo {...(lTitleInfo.contentProps || {})}/>
                </div> : <></> }
            </>
        }

        <FloatingObject color={"rgba(78,84,200, 0.2)"} />
    </section>
}