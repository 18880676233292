import styles from "./Review.module.css"
import { useCallback, useEffect, useState } from "react";
import Loading from "../common/Loading";
import { reviewLessonWords, } from "../../resources/api";
import { playSound } from "../../utils/playsound";
import Button from "../common/Button";
import { LessonTitles } from "../../const/lessonTitles";
import Type1 from "./Type1";
import FloatingObject from "../common/FloatingObjects";
import { soundLessons } from "../../const/lessons";




export default function Review({ lessonId, percentage, lessonDetails, trialId, openExtras, onNext, computerResults }) {
    const [page, setPage] = useState("loading")
    const [buttonsDisabled, setButtonsDisabled] = useState(true)
    const [reviewType, setReviewType] = useState(null)

    const [reviewData, setReviewData] = useState(null)
    const loadingCb = useCallback(async (reviewType) => {
        if (reviewType === "correction") {
            if (lessonId == 3) {
                setReviewData(["L1", "L2", "L3", "L4", "L5"])
            } else {
                const reviewInfo = (await reviewLessonWords(lessonId, trialId, soundLessons.includes(lessonId) ? "sound" : ""))
                setReviewData(reviewInfo.data.failed)
            }
        }
        setPage("intro")
        await playSound(`review/reviewlesson`)
        setButtonsDisabled(false)

    }, [lessonId])

    useEffect(() => {
        (async () => {
            const resultCallback = async (lessonId, type) => {

                // loadedAudios = (lessonId == 2) ? [...getAudios(`lesson${1}`), ...getAudios(`lesson${2}`)] : getAudios(`lesson${lessonId}`)
                setReviewType(type)
                setPage("loading")
                loadingCb(type).catch(alert)


            }
            if ([1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17].indexOf(lessonId) !== -1) {

                await resultCallback(lessonId, "correction")
            } else {
                await resultCallback(lessonId, "revision")
            }

            // else if([4, 7, 9, 11].indexOf(lessonId) !== -1){
            //
            //     await resultCallback(lessonId, "type2")
            // } else if([12, 14, 16].indexOf(lessonId) !== -1){
            //     await resultCallback(12, "type3")
            // } else if([19, 20].indexOf(lessonId) !== -1){
            //     await resultCallback(18, "type4")
            // }
        })().catch(alert)


    }, [lessonId]);


    return <>
        {
            (page === "loading") ? <Loading /> : <div className={styles.reviewContainer}>
                {
                    page == "intro" && <>
                        {
                            typeof percentage == "number" && <h1 style={{ fontSize: 67, marginBottom: 20 }}>{Math.floor(percentage * 100)}%</h1>
                        }
                        <h1 className={styles.reviewTitle}>Review Lesson</h1>
                        <h1 className={styles.lessonTitle}>{LessonTitles[lessonId].title}</h1>
                        <Button isDisabled={buttonsDisabled} onClick={async () => {
                            setButtonsDisabled(true)

                            if (reviewType === "correction") {
                                setPage(reviewType)


                                await playSound("review/review")
                            } else {

                                var oLid = lessonDetails.review.fromLesson
                                if (oLid === "self") {
                                    oLid = lessonId;
                                }
                                //await playSound("review/review2")
                                openExtras(oLid, lessonDetails.review.stages)

                            }
                            setButtonsDisabled(false)
                        }
                        } paddingV="full" paddingH="full" title={"Review Lesson"} icon={"right"} background={"green"} click={"inside"} />
                    </>
                }

                {
                    page == "correction" && <Type1 status={computerResults.status} controls={computerResults?.controls} buttonsDisabled={buttonsDisabled} tests={reviewData} trialId={trialId} lessonId={lessonId} onNext={() => onNext()} />
                }

                {/*{*/}
                {/*    page == "type3" && <Type3 buttonsDisabled={buttonsDisabled} lessonId={lessonId} trialId={trialId} onNext={()=>onNext()}/>*/}
                {/*}*/}
                {/*{*/}
                {/*    page == "type4" && <Type4 buttonsDisabled={buttonsDisabled} lessonId={lessonId} trialId={trialId} onNext={()=>onNext()}/>*/}
                {/*}*/}
            </div>
        }
        <FloatingObject color={"rgba(78,84,200, 0.2)"} />
    </>
}