import styles from "./Intros.module.css"
import {Flipped, Flipper} from "react-flip-toolkit";
import clsx from "clsx";
import shuffle from "lodash.shuffle";
import {characterDisplays} from "../../const/chars";
import {useEffect, useState} from "react";
import wait from "../../utils/wait";


var animRandInt = 0;
export default function Alphabet({shuffled=true}){
    const [listOfAlphabets, setLOA] = useState(()=>shuffled? shuffle(characterDisplays) :characterDisplays);

    useEffect(() => {
        if(shuffled) {
            animRandInt = setInterval(async () => {
                await wait(0)
                setLOA(shuffle(listOfAlphabets));
            }, 1000)
        }
        return () => {
            clearInterval(animRandInt)
        }
    }, [shuffled])

    return     <Flipper flipKey={listOfAlphabets.join("")}>
        <ul className={clsx(styles.alphabetGrid)}>
            {
                listOfAlphabets.map(alphabet => {
                    return   <Flipped key={alphabet} flipId={alphabet}>
                        <li  className={clsx(styles.item,shuffled && styles.shuffled)}><span>{alphabet}</span></li>
                    </Flipped>
                })
            }
        </ul>
    </Flipper>
}