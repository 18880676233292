import { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import { Icons } from "./componenets/icons";
import { DesertLayer } from "./componenets/layers/desert";
import { GreenLayer } from "./componenets/layers/green";
import { IceLayer } from "./componenets/layers/ice";
import { Logo } from "./componenets/logo";
import { Lessons } from "../../const/lessons";
import { getStatus } from "./componenets/level-primitive";
import MyReports from "../common/MyReports";
import { playSound } from "../../utils/playsound";
import ProfileModal from "../common/Profile";
import { useNavigate } from "react-router-dom";
import Modal from "../common/Modal";
import Button from "../common/Button";
import { Level } from "./componenets/level";

const testUserIds = ["725f2688-e5ab-46c0-a818-08e3f1b84c13"];
export const Maze = () => {
  const { user } = useContext(UserContext);
  const [currentLesson, setCurrentLesson] = useState("");
  const [checkpointProgress, setCheckpointProgress] = useState([]);
  const [currentCheckpoint, setCurrentCheckpoint] = useState();
  const [progress, setProgress] = useState([]);
  useEffect(() => {
    if (user) {
      let currentLesson;
      let progresses;
      if (user.l21SupportDeactivated) {
        currentLesson =
          progresses = [
            ...user.availableLessons.map(lesson => ({
              lessonId: lesson,
              isUnlocked: true,
            })),
            {
              lessonId: 21,
              isUnlocked: true,
            }
          ]
      } else {
        if (testUserIds.indexOf(user.id) !== -1) {
          progresses = [...user.progresses];
          currentLesson = 21;
        } else {
          progresses = [...user.progresses];
          currentLesson = Array.from(new Array(21))
            .map((_, i) => {
              var prgItem = progresses.find((a) => a.lessonId == i);
              return {
                lessonId: i,
                isLocked:
                  prgItem ?
                    (prgItem?.isUnlocked === false || prgItem?.hasPassed) : true,
              };
            }).filter((a) => !a.isLocked)
          currentLesson = currentLesson
            .map((a) => a.lessonId)
            .sort((a, b) => b - a)?.[0];
          currentLesson = currentLesson >= 1 ? currentLesson : 1;
        }
      }
      let maxLevelSupported =
        Object.keys(Lessons).sort((a, b) => b - a)?.[0] || 1;
      let highestLesson;
      progresses = progresses?.filter(
        (prg) => Object.keys(Lessons).indexOf(prg.lessonId.toString()) !== -1
      );
      for (let i = 0; i < 21; i++) {
        var progress = progresses.find((prg) => prg.lessonId == i + 1);
        if (progress?.isUnlocked && !progress.hasPassed) {
          highestLesson = i + 1;
          if (currentLesson == i + 1) {
            break;
          }
        } else if (!progress) {
          highestLesson = i + 1;
          break;
        }
      }
      if (highestLesson > currentLesson) {
        currentLesson = highestLesson;
      }

      if (currentLesson > maxLevelSupported) {
        currentLesson = maxLevelSupported;
      }

      if (user.userType === "trainee") {
        setCurrentLesson(currentLesson);
        setProgress(progresses);
        return
      }
      /**
       * @type {Array<{id: string, hasPassed: boolean, isUnlocked: boolean, hasPassed: boolean, lessonId: number, isComplete: boolean}>}
       */
      const checkpoints = user.checkPointProgress
      setCheckpointProgress(checkpoints)

      const ch1 = checkpoints.find(cp => cp.lessonId === 0)
      const ch2 = checkpoints.find(cp => cp.lessonId === 1)
      const ch3 = checkpoints.find(cp => cp.lessonId === 2)

      const studentTookLessonBefore5 = progresses.find(prg => prg?.lessonId <= 5 && prg?.isUnlocked && prg?.hasPassed)
      const studentTookLessonBefore12 = progresses.find(prg => prg?.lessonId <= 12 && prg?.isUnlocked && prg?.hasPassed)
      const studentTookLessonBefore16 = progresses.find(prg => prg?.lessonId <= 16 && prg?.isUnlocked && prg?.hasPassed)

      if (studentTookLessonBefore5 && currentLesson > 5 && !ch1?.hasPassed && !ch1?.isComplete) {
        setCurrentCheckpoint(0)
      } else if (studentTookLessonBefore12 && currentLesson > 12 && !ch2?.hasPassed && !ch2?.isComplete) {
        setCurrentCheckpoint(1)
      } else if (studentTookLessonBefore16 && currentLesson > 16 && !ch3?.hasPassed && !ch3?.isComplete) {
        setCurrentCheckpoint(2)
      }

      setCurrentLesson(currentLesson);
      setProgress(progresses);
    }
  }, [user]);
  const [reportOpen, setIsReportOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [isInPretest, setIsInPretest] = useState(false);
  const navigate = useNavigate();


  return (
    <div className="w-full h-[100vh] bg-white overflow-hidden">
      {/* <L21DeactivatedModal close={setL21SupportDModal} progress={progress} /> */}
      {reportOpen && (
        <MyReports
          close={() => {
            playSound("click");
            setIsReportOpen(false);
          }}
        />
      )}
      {profile && (
        <ProfileModal
          playLatest={() => {
            playSound("click");
            navigate(`/lessons/${lessonObject.id}`);
          }}
          removeComponent={() => {
            playSound("click");
            setProfile(false);
          }}
        />
      )}
      <div className="h-[10%] bg-gray-200 w-full flex items-center justify-between px-4">
        <Logo />
        <div
          className="flex items-center gap-2"
          role="button"
          onClick={() => {
            localStorage.removeItem("token");
            window.location.reload();
          }}
        >
          <Icons.logout />
          <p>Logout</p>
        </div>
      </div>
      <div className="h-[75%] flex flex-col justify-between">
        <GreenLayer currentLesson={currentLesson} progress={progress} currentCheckpoint={currentCheckpoint} checkpointProgress={checkpointProgress} />
        <DesertLayer currentLesson={currentLesson} progress={progress} currentCheckpoint={currentCheckpoint} checkpointProgress={checkpointProgress} />
        <IceLayer currentLesson={currentLesson} progress={progress} currentCheckpoint={currentCheckpoint} checkpointProgress={checkpointProgress} />
      </div>
      <div className="h-[15%] bg-gray-200 flex px-2 md:px-0 items-center justify-between">
        <div className="md:w-1/3 hidden md:block"></div>
        <div
          onClick={() => {
            playSound("click");

            if (currentCheckpoint !== undefined) {
              navigate(`/checkpoint/${currentCheckpoint}`)
            } else {
              navigate(`/lessons/${currentLesson}`);
            }
          }}
          className="md:w-1/3 flex justify-center hover:scale-110 scale-100 cursor-pointer transition-all duration-300 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            className="w-12 h-12 md:w-20 md:h-20"
            viewBox="0 0 100 100"
            fill="none"
          >
            <g filter="url(#filter0_i_95_34)">
              <rect width="100" height="100" rx="20" fill="#2F80ED" />
            </g>
            <path
              d="M64.5 44.4545C68.5 46.7639 68.5 52.5374 64.5 54.8468L45 66.1051C41 68.4145 36 65.5278 36 60.909L36 38.3923C36 33.7735 41 30.8868 45 33.1962L64.5 44.4545Z"
              fill="white"
            />
            <defs>
              <filter
                id="filter0_i_95_34"
                x="0"
                y="-3"
                width="100"
                height="103"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="-6" />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_95_34"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="md:w-1/3 justify-end px-2 flex items-center gap-2">
          <div
            className="flex items-center bg-white px-3 py-2 rounded-md shadow-lg gap-2"
            onClick={() => setIsReportOpen(true)}
            role="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 28 28"
              fill="none"
              className="lg:w-6 lg:h-6 w-3 h-4"
            >
              <path
                d="M24.5 16.625V22.75C24.5 23.2141 24.3156 23.6592 23.9874 23.9874C23.6592 24.3156 23.2141 24.5 22.75 24.5H5.25C4.78587 24.5 4.34075 24.3156 4.01256 23.9874C3.68437 23.6592 3.5 23.2141 3.5 22.75V16.625C3.5 16.3929 3.59219 16.1704 3.75628 16.0063C3.92038 15.8422 4.14294 15.75 4.375 15.75C4.60706 15.75 4.82962 15.8422 4.99372 16.0063C5.15781 16.1704 5.25 16.3929 5.25 16.625V22.75H22.75V16.625C22.75 16.3929 22.8422 16.1704 23.0063 16.0063C23.1704 15.8422 23.3929 15.75 23.625 15.75C23.8571 15.75 24.0796 15.8422 24.2437 16.0063C24.4078 16.1704 24.5 16.3929 24.5 16.625ZM13.3809 17.2441C13.4622 17.3254 13.5587 17.39 13.6649 17.434C13.7712 17.478 13.885 17.5007 14 17.5007C14.115 17.5007 14.2288 17.478 14.3351 17.434C14.4413 17.39 14.5378 17.3254 14.6191 17.2441L18.9941 12.8691C19.0754 12.7878 19.1398 12.6913 19.1838 12.585C19.2278 12.4788 19.2505 12.365 19.2505 12.25C19.2505 12.135 19.2278 12.0212 19.1838 11.915C19.1398 11.8087 19.0754 11.7122 18.9941 11.6309C18.9128 11.5496 18.8163 11.4852 18.71 11.4412C18.6038 11.3972 18.49 11.3745 18.375 11.3745C18.26 11.3745 18.1462 11.3972 18.04 11.4412C17.9337 11.4852 17.8372 11.5496 17.7559 11.6309L14.875 14.513V4.375C14.875 4.14294 14.7828 3.92038 14.6187 3.75628C14.4546 3.59219 14.2321 3.5 14 3.5C13.7679 3.5 13.5454 3.59219 13.3813 3.75628C13.2172 3.92038 13.125 4.14294 13.125 4.375V14.513L10.2441 11.6309C10.0799 11.4668 9.85719 11.3745 9.625 11.3745C9.39281 11.3745 9.17012 11.4668 9.00594 11.6309C8.84175 11.7951 8.74951 12.0178 8.74951 12.25C8.74951 12.4822 8.84175 12.7049 9.00594 12.8691L13.3809 17.2441Z"
                fill="black"
              />
            </svg>
            <p className="font-bold">My Report</p>
          </div>
          <div
            role="button"
            className="bg-orange-400 px-4 rounded-md shadow-lg  shadow-black py-2"
            onClick={() => {
              playSound("click");
              setProfile(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M12 13q1.45 0 2.475-1.025T15.5 9.5q0-1.45-1.025-2.475T12 6q-1.45 0-2.475 1.025T8.5 9.5q0 1.45 1.025 2.475T12 13m-9 8V3h18v18zm2-2h14v-1.15q-1.35-1.325-3.137-2.087T12 15q-2.075 0-3.863.763T5 17.85z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

    </div>
  );
};


const L21DeactivatedModal = ({ close, progress }) => {
  console.log({
    progress
  })
  return (
    <Modal close={close}>
      <div className="flex flex-col items-center gap-4 bg-white p-4 rounded-lg">
        <h1 className="text-3xl font-bold">
          You haven't completed the previous levels to access lesson 21
        </h1>
        <p>
          Complete these lessons to unlock lesson 21
        </p>
        <div className="flex items-center gap-6">
          <Level status="inActive" level={12} />
          <Level status="inActive" level={17} />
          <Level status="inActive" level={18} />
        </div>
        <Button title="Continue" />
      </div>
    </Modal>
  )
}