import styles from "./Intros.module.css";
import {useMemo} from "react";
import {ColMap, SeqMap} from "../../const/tables";




export default function WordTable({mapKey}){
    const cols = useMemo(()=>ColMap[mapKey], [mapKey])
    const seqs = useMemo(()=>SeqMap[mapKey]||{}, [mapKey])
    return <div className={styles.wordPresentation}>
        {
            cols.map((column, i) => {
                return (<div className={styles.wordColumns}>
                    {
                        column.map(rw => <div className={styles.wordItem}>{(seqs?.[rw] || rw)}</div>)
                    }
                </div>)
            })
        }
    </div>
}