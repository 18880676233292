import { pauseAll, pauseAllVideos } from "./audios";
import { resumeSound } from "./playsound";

var heldFunctions = []
var holdAll = false;
var pausedSounds = [];
export default function wait(delay) {
    return new Promise((resolve, reject) => {
        if (holdAll) {
            heldFunctions.push(() => {
                setTimeout(() => {
                    if (holdAll) {
                        heldFunctions.push(resolve)
                    } else {
                        resolve();
                    }

                }, delay);
            })

        } else {
            setTimeout(() => {
                if (holdAll) {
                    heldFunctions.push(resolve)
                } else {
                    resolve();
                }

            }, delay);
        }

    })
}


export async function playAllVideos() {
    var elements = document.querySelectorAll("video");
    for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].play()

    }
}
export async function delayAll(forDirection = false) {
    await window.suspendRecordingAudio?.(true)

    holdAll = true;
    await window.soundManager.pauseAllChannels()
    if (forDirection) {
        await window.soundManager.continueChannel("direction")
    }


    await pauseAllVideos();
    return async () => {
        for (let i = 0; i < heldFunctions.length; i++) {
            await heldFunctions[i]();
        }
        heldFunctions = [];
        holdAll = false;
        await window.soundManager.resumeAllChannels()
        await playAllVideos()
    }
}

export function holdFlow(dontPause = false) {
    return new Promise((resolve, reject) => {

        if (holdAll && !dontPause) {
            heldFunctions.push(resolve)
        } else {
            resolve()
        }
    })
}