import styles from "./FloatingObjects.module.css";
import clsx from "clsx";
function hexToRgba(hex) {
    // Remove the # symbol from the hex color
    hex = hex.replace("#", "");
    // Convert the hex color to an array of three hexadecimal numbers
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    // Define the alpha value as a constant
    const a = 0.15;
    // Return the rgba color in a CSS compatible form
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}
var colors = {"A":"#FF5A00", "B":"#FFC800", "C":"#E0FF00", "E":"#00FF6A", "H":"#0080FF", "I":"#0040FF", "J":"#4000FF", "K":"#8000FF", "N":"#FF006A", "O":"#D90040","T":"#4000D9","U": "#0040D9","V": "#0080D9","W": "#00BFD9","Z": "#80D900"}
export default function FloatingObject({color, isHiding, status, white}){
    return <div style={{'--color': color}} className={(styles.area)}>
    <ul className={clsx(styles.circles, isHiding && styles.hiding, status && styles[status], white && styles.white)}>
        {
            Object.keys(colors).map(ikey=>{
                return <li style={{'--chosen-color': hexToRgba(colors[ikey])}}>{ikey}{ikey.toLowerCase()}</li>
            })
        }
    </ul>
  </div>
}