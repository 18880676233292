export const ModuleTitles = {
    0: {
        title: "Setting Up"
    },
    1: {
        title: "Build the Foundation"
    },
    2: {
        title: "Blending Basics"
    },
    3: {
        title: "Most Common Sounds List 1<br/>Consonant Blends"
    },
    4: {
        title: "Most Common Sounds List 2<br/>Vowel Combinations"
    },
    5: {
        title: "Most Common Sounds List 3<br/>Vowel/Consonant Blends"
    },
    6: {
        title: "Syllable Breakdown"
    },
    7: {
        title: "Common Prefixes"
    },
    8: {
        title: "Common Suffixes"
    },
    9: {
        title: "Hidden Sounds"
    },
    10: {
        title: "Read It Out!"
    }
}
export const LessonTitles = {
    0: {
        title: "Setting Up"
    },
    1: {
        title: "Sounds of the Alphabet",
        module: 1,
        content: "Alphabet",
        contentProps: {
            shuffled: false
        }
    },
    2: {
        title: "Single Letter Sounds (Random Order)",
        module: 1,
        content: "Alphabet"
    },
    3: {
        title: "Vowel Sounds (Short, Long, Y)",
        module: 1,
        content: "VowelSounds",
        exampleIncluded: true,
        withExampleAudio: true,
        exampleContent: "PhonesList"
    },
    4: {
        title: "Blending Basic Sounds",
        module: 2,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["b+ag", "a", "man", "*pif", "u", "her", "o", "a", "rug", "e", "suds", "him", "t+op", "i", "j+og", "zip"]
        }
    },
    5: {
        title: "Most Used Words",
        module: 2,
        content: "WordTable",
        contentProps: {
            mapKey: 5
        }
    },
    6: {
        title: "Consonant Blends List 1",
        module: 3,
        content: "WordTable",
        contentProps: {
            mapKey: 6
        },
        exampleIncluded: true,
        exampleContent: "GridTable",
        exampleProps: {
            mapKey: 6,
            gridArea: `'a1 a2' 
            'a1 a3'`
        }
    },
    7: {
        title: "Blending with Consonant Blends",
        module: 3,
        content: "WordList",
        contentProps: {
            mapKey: 7
        }
    },
    8: {
        title: "Most Common Vowel Combinations",
        module: 4,
        content: "WordTable",
        contentProps: {
            mapKey: 8
        }
    },
    9: {
        title: "Blending With Vowel Combinations",
        module: 4,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["p-ai-d", "l-aw", "es", "ey", "el", "ea", "ei", "p-ew", "d-ue", "ur", "ou", "oi", "oy", "oa", "oo", "j-oy", "br-oo-k"]
        }
    },
    10: {
        title: "Most Common Vowel/Consonant Blends",
        module: 5,
        content: "WordTable",
        contentProps: {
            mapKey: 10
        }
    },
    11: {
        title: "Blending With Vowel/Consonant Blends",
        module: 5,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["a", "k", "ail", "m", "ept", "w", "e",
                "sh", "ook", "t",
                "p", "h", "ite",
                "g", "o", "sw",
                "ight", "i", "l", "y"]
        }
    },
    12: {
        title: "Syllable Breakdowns (Decoding Multisyllabic Words)",
        module: 6,
        content: "ImageList",
        contentProps: {
            mapKey: 12
        }
    },
    13: {
        title: "Common Prefixes",
        module: 7,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["pre-", "dis-",
                "un-", "mid-",
                "ir", "em", "semi-", "il-",
                "in-", "re-", "anti-",
                "di-", "fore-"]
        }
    },
    14: {   ///TODO: Check this
        title: "Blending With Prefixes",
        module: 7,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["pre-", "run",
                "bound", "dis-",
                "clue", "il-", "game", "re-",
                "loud", "anti-", "mid-",
                "di-", "fore-"]
        },

        exampleProps: {
            lessonId: 14
        },
    },
    15: {
        title: "Common Suffixes",
        module: 8,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["-ish", "-ing", "-ound", "-less", "-er", "-ment",
                "-ible", "-le", "-ly",
                "-tion", "-ful", "-ion", "-y",
                "-ness", "-able"]
        },
    },
    16: {
        title: "Blending With Suffixes",
        module: 8,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["jump", "-ing", "-less", "clue",
                "-ible", "wonder", "loud", "-ion", "-ly", "-ness",
                "bound", "-ment",
                "-ful", "-y",
                "-able"]
        },
    },
    17: {
        title: "Most Common Irregular Words",
        module: 9,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: ["want",
                "color",
                "any",
                "cold", "women",
                "kind", "through",
                "water", "friend", "move", "have",
                "live", "wolf", "where",
                "answer",
                "work"]
        },
    },
    18: {
        title: "Commonly Mispronounced Sounds",
        module: 9,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: [
                "ai", "ae", "ar",
                "aw", "au", "ail", "ale", "apt", "imp", "es",
                "ey", "el", "ea", "ei", "ew", "ue", "ect", "ict", "ur", "opt", "ou", "oi", "oy", "eel",
                "oa", "ight", "ite", "iar", "ute", "oo"
            ]
        },
    },
    19: {
        title: "Common Phrases",
        module: 10,
        content: "RandomizedShow",
        contentProps: {
            visibleItems: [
                "what is your",
                "friends are",
                "going to play",
                "first of his",
                "by the way",
                "the person",
                "who was",
                "know",
                "do not",
                "a time",
                "once upon"
            ],
            oneRow: true
        },
    },
    20: {
        title: "Sentence Practice",
        module: 10,
        content: "WordList",
        contentProps: {
            sentence: true,
            mapKey: 20
        },
    },
    21: {
        title: "Fluency Reads",
        module: 10,
        content: "WordList",
        contentProps: {
            sentence: true,
            mapKey: 21
        }
    }
}
    ;