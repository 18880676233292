function iOSSafari() {
    var isSafari = /safari/ig.test(window.navigator.userAgent) && /webkit/ig.test(window.navigator.userAgent) && !(/Google Inc/ig.test(navigator.vendor))
    return isSafari
}
export function queryMicAccess(allowedCb, notAllowedCb){
        if(iOSSafari()){
            notAllowedCb()
        }else{

            navigator.permissions.query({ name: "microphone" }).then(function (permissionStatus) {
                if (permissionStatus.state == "granted") {
                    allowedCb()
                }else{
                    notAllowedCb();
                }
            });
        }

    }