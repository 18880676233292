import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Review from "./components/review/Review";
import CoinTest from "./pages/CoinTest";
import Critical from "./pages/Critical";
import GameZone from "./pages/GameZone";
import Home from './pages/Home';
import LessonPlay from "./pages/LessonPlay";
import './styles/main.scss';
import { pauseAll, pauseAllVideos } from "./utils/audios";
import { Maze } from './components/new-maze';
import "./styles/Index.css"
import Checkpoint from './pages/Checkpoint';
import { Assignable } from './pages/Assignable';
import { DyslexiaScreen } from './pages/Dyslexia-Screen';

function RootComponent({ }) {
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        pauseAll()
        pauseAllVideos();
        var openLesson = (new URLSearchParams(location.search)).get("openLesson")
        if (openLesson) {
            navigate(`/lessons/${openLesson}`)
        }
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search
        });
    }, [location]);

    return (
        <>

            <Routes >
                <Route path={"/"} element={<Home />} />
                <Route path={"/critical"} element={<Critical />} />
                <Route path={"/lessons/:lessonId"} element={<LessonPlay />} />
                <Route path={"/checkpoint/:id"} element={<Checkpoint />} />
                <Route path={"/assignable"} element={<Assignable />} />
                <Route path={"/dyslexia"} element={<DyslexiaScreen />} />
                <Route path={"/review/test"} element={<Review trialId={"5c654138-9019-4d80-b8df-a82cbb9876a9"} onNext={() => alert("done")} lessonId={3} percentage={0.8} />} />
                <Route path={"/coins/test"} element={<CoinTest />} />
                <Route path={"/games/:gamezoneId"} element={<GameZone />} />
            </Routes>
        </>

    )
}

export default RootComponent
