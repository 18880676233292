import styles from "./DisplayItems.module.css"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { playSound } from "../../utils/playsound";
import wait from "../../utils/wait";

import clsx from "clsx";
import { genColor, randomIntFromInterval } from "../../utils/common";
import { mockAudio, waitForVoice } from "../../utils/audios";
import BeatRead21 from "../../components/common/BeatRead21";
import FloatingObject from "../../components/common/FloatingObjects";
import { useInstruction } from "../../utils/instruction";
import BaseStage from "../interfaces/BaseStage";
import ItemTable from "../neutral/ItemTable";
import StageIntroduction from "../introduction/StageIntroduction";
import { Flipped, Flipper } from "react-flip-toolkit";
import RecordButton from "../neutral/RecordButton";
import Button, { SlottedButton } from "../common/Button";
import StatusContext from "../../context/StatusContext";
import FlipNumbers from 'react-flip-numbers';
import useCountDown from "../../utils/countdown";
import * as countDownAnimation from "../../lottie/count-down.json"
import * as animationData from "../../lottie/processing.json";
import Lottie from "react-lottie";
import ButtonHolder from "../common/ButtonHolder";
/**
 *
 * @param {string} word
 * @param type
 * @param syllabicPos
 * @param cancelPos
 * @param validVowels
 * @param micStep
 * @param registerMicrophone
 * @param registerTextInput
 * @param microphone
 * @param micPart
 * @returns {JSX.Element}
 * @constructor
 */
function WordEditor({
    word,
    syllabicPos,
    cancelPos,
    validVowels,
    micStep,
    registerMicrophone,
    registerTextInput,
    beforeLine,
    micPart,
    steps,
    microphone
}) {
    const { buttonDisabled } = useContext(StatusContext)
    const [pState, setPState] = useState(steps?.[0])
    const [vowels, setVowels] = useState([])
    const [syllableLine, setSyllableLine] = useState()
    const [cancelLine, setCancelLine] = useState()
    const [dragTemporary, setDragTemporary] = useState(true)

    const { formSubmit, disabled, setText } = registerTextInput()
    useEffect(() => {
        if (micPart) {
            setCancelLine(cancelPos)
            setSyllableLine(syllabicPos)
            setVowels(validVowels)
        }
    }, [micPart])

    useEffect(() => {
        if (pState == "next") {
            var newText = word.split("");
            if (cancelLine) {
                newText.splice(cancelLine + 1, 0, "/")
            }
            if (syllableLine) {
                newText.splice(syllableLine + 1, 0, "|")
            }
            if (vowels) {
                vowels.sort().map((vowelIndex, i) => {
                    var vi = vowelIndex
                    if (syllableLine && (((syllableLine) + 1) <= vi)) {
                        vi += 1;
                    }
                    if (cancelLine && (((cancelLine) + 1) < vi)) {
                        vi += 1;
                    }
                    vi += i * 2
                    newText.splice(vi, 0, "(")
                    newText.splice(vi + 2, 0, ")")
                })

            }
            newText = newText.join("")
            console.log(newText)

            formSubmit()
            setText(newText)
        }

    }, [cancelLine, syllableLine, vowels, word, pState])

    const nextStep = useCallback(() => {
        if (steps.indexOf(pState) < (steps.length - 1)) {
            setPState(steps[steps.indexOf(pState) + 1])
        } else {
            setPState("next")
        }
    }, [pState, steps])
    return <div className={styles.wordActions}>
        <span className={styles.word}>
            {
                word.split("").map((spl, inp) => {
                    return <><span
                        className={clsx(styles.character, (cancelLine == inp) && styles.cancelled, styles.cancelStage, (pState == "vowels") && styles.hoverScalable, ((vowels?.indexOf(inp) !== -1)) && styles.correctChr)}
                        onClick={async () => {
                            if ((pState == "vowels") && !buttonDisabled) {
                                if (validVowels.indexOf(inp) != -1) {
                                    await playSound("correct")
                                    var _addState = vowels || []

                                    _addState.push(inp)
                                    setVowels([..._addState])


                                    if (validVowels.every(vl => (_addState.indexOf(vl) !== -1))) {
                                        if (beforeLine) {
                                            await playSound(beforeLine)
                                        }
                                        nextStep()

                                    }
                                } else {
                                    await playSound("wrong")
                                }
                            }
                        }}
                        onDragOver={(event) => {
                            if ((pState == "cancel")) {
                                event.stopPropagation();
                                event.preventDefault();
                            }
                        }}
                        onDrop={async (e) => {
                            setDragTemporary(false)
                            if (pState == "cancel") {
                                if (inp == (cancelPos)) {
                                    await playSound("correct")
                                    await wait(1000)
                                    nextStep()

                                } else {
                                    await playSound("wrong")
                                    e.preventDefault()
                                    setCancelLine(null)

                                }
                            }
                        }}
                        onDragEnter={() => {
                            if (pState == "cancel") {
                                setDragTemporary(true)
                                setCancelLine(inp)
                            }
                        }}
                        onDragLeave={() => {
                            if ((dragTemporary) && (pState == "cancel")) {
                                setCancelLine(null)
                                setDragTemporary(false)
                            }
                        }}
                    >{spl}</span>
                        {
                            <div
                                onDrop={async (e) => {
                                    setDragTemporary(false)
                                    if ((pState == "syllable")) {
                                        if (inp == (syllabicPos)) {
                                            await playSound("correct")
                                            nextStep()
                                        } else {
                                            await playSound("wrong")
                                            e.preventDefault()
                                            setSyllableLine(null)
                                        }

                                    } else {

                                        e.preventDefault()
                                        setSyllableLine(null)

                                    }
                                }
                                }
                                onDragOver={(event) => {
                                    if ((pState == "syllable")) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }
                                }}
                                onDragEnter={() => {
                                    if ((pState == "syllable")) {

                                        setDragTemporary(true)
                                        setSyllableLine(inp)
                                    }
                                }}

                                onDragLeave={() => {
                                    if ((dragTemporary) && ((pState == "syllable"))) {
                                        setSyllableLine(null)
                                        setDragTemporary(true)
                                    }
                                }}
                                className={clsx(styles.theDropTarget, (syllableLine == inp) && styles.dragging)}></div>
                        }


                    </>
                })
            }
        </span>
        {(pState == "cancel") && <div draggable={true} className={clsx(styles.separatorLine, styles.cancellation)}>
            <div className={styles.sln}></div>
        </div>}
        {((pState == "syllable")) && <div draggable={true} className={styles.separatorLine}>
            <div className={styles.sln}></div>
        </div>}

        {
            ((pState == "next") && !micPart) && <>
                <div style={{ marginTop: 60 }} />
                <Button icon={"nextarrow"} contentSize={"large"} title={"Continue"} onClick={() => {
                    formSubmit()
                    /**
                     * Button will be disabled whilist audio, good method to keep everything together,
                     */
                }} />
            </>
        }

        {
            micPart && microphone && <div className={clsx(styles.micAndWord)} style={{ flex: 0 }}>
                <RecordButton
                    {...registerMicrophone(microphone)}
                /></div>
        }
    </div>
}


function CountDownItem({ amount, onEnded }) {

    useEffect(() => {
        (async () => {
            await soundManager.playSound("background", "enticing")
            onEnded()
        })()



        return () => {
            soundManager.cleanChannel("background")
        }

    }, [])

    return <Lottie
        loop={false}
        options={{
            loop: false,
            autoplay: true,
            animationData: countDownAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid",
            },
        }}
        width={600}
    />
}



class DisplayItems extends BaseStage {
    state = {}

    /**
     *
     * @param {string} word
     * @returns {JSX.Element}
     */
    renderWord(word) {
        var wordSize = "large"
        if (word.length > 7) {
            wordSize = "large"
        } else if (word.length > 4) {
            wordSize = "medium"
        } else {
            wordSize = "small"
        }

        return word.includes("-") ?
            <h1 className={clsx(styles.splittedWord, styles[wordSize])}>{word.split("-").map((itm, i) => <>{(i > 0) && <b>-</b>}<span
                style={{ '--delay': i + 1 }} dangerouslySetInnerHTML={{ __html: itm }}></span></>)}</h1>
            : (word.includes("*") ?
                <h1 className={clsx(styles.splittedWord, styles[wordSize])}>{word.split("*").map((itm, i) => <>{(i > 0) && <b>*</b>}<span
                    style={{ '--delay': i + 1 }} dangerouslySetInnerHTML={{ __html: itm }}></span></>)}</h1> : <h1 className={styles[wordSize]} dangerouslySetInnerHTML={{ __html: word }}></h1>)
    }


    render() {
        const {
            status,
            data,
            current,
            stopPresentable,

            stageDetails,

        } = this.props

        return <div className={clsx(styles.container, status && styles[status])}>
            <div className={clsx(styles.sequences)}>
                <div className={clsx(styles.micAndWord, stopPresentable && styles.noAnimation, styles.largeSize)}>
                    {
                        Array.isArray(data[current].text) ? <>
                            <div className={styles.soundButtons}>
                                {
                                    data[current].text.map(char => {
                                        return <button onClick={async () => {
                                            await window.soundManager.playImmediate(`char/${char}`)
                                        }}>{char}</button>
                                    })
                                }
                            </div>
                        </> : this.renderWord(data[current].text)
                    }
                    <ButtonHolder
                        reference={
                            JSON.stringify(
                                {
                                    ...(this.props.controls.registerMicrophone(data[current].microphone || stageDetails.microphone)),
                                    ...(stageDetails.micDisabled ? { contextuallyDisabled: true } : {})
                                }
                            )
                        }
                        bType={"record"}
                        {...(this.props.controls.registerMicrophone(data[current].microphone || stageDetails.microphone))}
                        {...(stageDetails.micDisabled ? { contextuallyDisabled: true } : {})}
                    />
                </div>
            </div>
        </div>

    }
}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        useInstruction(props.stageDetails?.instruction && [props.stageDetails?.instruction]);
        return <Component {...props} />;
    }
}


export default withMyHook(DisplayItems)

